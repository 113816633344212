import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  FarmingProduces: any;
  Products: any;
  public router: Router;
  Categories: any;
  HomeURL: any;
  Banners: any;

  constructor(public generalService: GeneralService, public http: HttpClient, router: Router, ) {
    this.router = router
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
    navigator.geolocation.getCurrentPosition(position => {
      var lt = position
    });
  }

  ngOnInit(): void {
    this.GetBannerImagesforHome()
    this.GetPopularCategoriesforHome()
    this.GetProductsforHome()
    this.GetFarmingProduces()
  }

  /*
   * Below method is used to get the Banner images from Server with reference of database path
   */
  GetBannerImagesforHome() {    
    let arr = [];
    arr.push({ UserId:0,TokenID:null})
    var UploadFile = new FormData()
    UploadFile.append("userCredentials", JSON.stringify(arr))
    var url = "/api/Products/GetAll_HomepageBannerImages"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Banners = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*
  * Below method is used to get the Popular Categories with images from Server with reference of database path
  */
  GetPopularCategoriesforHome() {    
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetProductCategories"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Categories = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*Below method is for navigating to selected category in home page for*/
  navigateBasedOnCategoryId(Category) {
      this.router.navigate(['/Category/' + Category]); 
  }

  /*
* Below method is used to get the Products with images from Server with reference of database path
*/
  GetProductsforHome() {    
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetAllProductDetails"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Products = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*navigating to customer selected products from our products*/
  navigatingToSelectedProduct(data) {
    var prodName = ""; var prodarr = []
    prodarr.push(data)
    var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.generalService.setProducts(prodarr)
    this.router.navigate(['/Product/' + prodName]);
  }

  /*
* Below method is used to get the Goals list for left side menu 
*/
  GetFarmingProduces() {
    var url = "/api/Products/GetFarmingProducesMaster"
    this.generalService.GetData(url).then(data => {
      this.FarmingProduces = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /**
 * Navigating to selected Goal in header page
 */
  navigateToFarmingProducts(data) {
    debugger
    var carName = ""; var catarr = []
    this.ngOnInit()
    catarr = this.FarmingProduces.filter(A => A.SpecialityName == data)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      carName += "_" + str[i];
    }
    this.generalService.setCategoryid(catarr)
    this.router.navigate(['/FarmerProduces/' + carName]);
  }

}
