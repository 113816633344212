<br />
<div class="container">
  <div class="a_heading">My Enquiries</div>
  <div class="row" *ngIf="EnquiryProductList">
    <div class="col-lg-6" *ngFor="let item1 of EnquiryProductList;let i=index">
      <div class="card">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h5 style="color: #fd6a4e;">
              {{item1.EnquiryNumber}}
            </h5>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h5 style="float: right;">
              {{item1.EnquireDate | date:'dd-MMM-yyyy H:m'}}
            </h5>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-7">
            <h2 class="cardItems">{{item1.ProductName}}-{{item1.Size}}</h2>
          </div>
          <div class="col-lg-5">
            <div>
              <h2 class="cardItems1">
                Quantity:{{item1.TotalProducts}}/-
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!EnquiryProductList">
    <div class="col-md-12">
      <b class="text-center text-danger">No enquiries available</b>
    </div>
    <!--<img src="../../../assets/img/projects/empty-cart.png" />-->
  </div>
</div>


<!--this code use for google analyticals-->
<div class="sticky-container1">
  <ul class="sticky1" style=" margin-top:200px;">
    <li>

      <img height="32" src="assets/media/call-circle.png" width="32">
      <p><a href="Tel:9066-179-179">Call Us<br>Schedule Appointment</a></p>
    </li>


    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32" src="assets/media/youtube-circle.png" width="32" />
      <p _ngcontent-c0="">
        <a _ngcontent-c0="" href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
          Youtube
          <br _ngcontent-c0="">yk mart
        </a>
      </p>
    </li>-->
    <!--<li _ngcontent-c0="">
      <img _ngcontent-c0="" height="32" src="assets/media/facebook-circle.png" width="32">
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br _ngcontent-c0="">Yk mart</a></p>
    </li>-->
    <li>
      <img height="32" src="assets/media/images (1).jpg" width="32" />
      <p><a href="" target="_blank">Email<br>Yk mart</a></p>
    </li>
    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32"src="assets/img/download (1).png"width="32"/>
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://twitter.com/home" target="_blank">Twitter<br _ngcontent-c0="">KISAN</a></p>
    </li>-->

    <li>
      <img height="32" src="assets/media/whatsapp.png" width="32" />
      <p><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br>Yk mart</a></p>
    </li>
  </ul>
</div>








<style>


  .sticky-container1 {
    padding: 0;
    margin: 500px 0 0;
    position: fixed;
    right: -169px;
    top: 230px;
    width: 210px;
    z-index: 1100;
  }

  .sticky1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-top: 20rem;
  }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>
