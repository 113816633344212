import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { GeneralService } from 'src/app/service/general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { debuglog } from 'util';
declare var Razorpay: any;
/*import { NgxSpinnerService } from "ngx-spinner";*/
declare var $: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [MessageService]
})
export class CartComponent implements OnInit {
  totalPrice: any;
  OrderDetails: any;
  Id: any;
  deliveryCharge: any;
  offerPrice: any = 0;
  GstPrice: any;
  offerDetails: any;
  offerCode: any;
  WishListitemmsforloginusers: any = [];
  cartIndex: any;
  n: any;
  val: number;
  arr: any = [];
  PaymentType: any;
  addressId: any;
  currentCartItems: any = [];
  customerCartItems: any = [];
  loginStatus: boolean;
  addressDetails: any;
  addressDetails1: any;
  LoginDetails: any;
  itemSize: any;
  ItemId: any;
  seletedItemforWishlist: any = [];
  wishListItems: any = [];
  subTotal: any = 0;
  HomeURL: any;
  cartItems: any;
  Quantity: any;
  offersdata: any;
  offerId: any;
  alert: any;
  //getAllAdminOrders: any;
  plus: boolean = true;
  TransactionStatus: any;
  admin: any;
  TaxnId:any;

  constructor(public cartService: CartService, public http: HttpClient,
    public wishlistService: WishlistService, public toast: MessageService,
    public generalService: GeneralService, public router: Router) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));

    this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
      this.cartItems = res
      this.changeQuantity()//method will called for getting subtotal amount initially
    })
    this.wishlistService.getProducts().subscribe(res => {/*this is a service call for getting wishlist items*/
      this.wishListItems = res
    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
      this.HomeURL = data.Webservice;
    });
  }

  ngOnInit(): void {
    debugger
    if (this.LoginDetails) {
      this.loginStatus = true;
      this.getCustomerAddress();
      this.GetAllOffers();
    //  this.getAllAdminOrders();
      //this.getAllAdminOrders()
    }
    else {
      this.loginStatus = false;
    }

  }

  /*
  Below procedtoBuy() method will fire when the user click on proced to by button
   */
  procedtoBuy() {
    debugger
    if (this.LoginDetails) {
      if (this.addressDetails) {
        if (!this.addressId) {
          this.generalService.ShowAlert('Warning', 'Please select or add address for Delivery.', 'warning');
        }
        else {
         
          $('#myModal').modal('show');
         
        }
      } else {
        this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
        this.router.navigate(['/Address']);
      }
    }
  }




  /*
  Below changeQuantity() method will fire when user change the quantity of the product
   */
  changeQuantity() {
    debugger
    this.subTotal = 0
    this.totalPrice = 0
    this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
      this.subTotal += (a.Price * 1) * (a.Quantity)
    })
    this.totalPrice = this.subTotal
    //if (this.totalPrice < 5000) {
    this.deliveryCharge = 0;
    //  this.totalPrice = this.totalPrice + this.deliveryCharge
    //}
    //else {
    //  this.deliveryCharge = 0;
    //  this.totalPrice = this.totalPrice + this.deliveryCharge
    //}
  }

  /*Below deleteFromCart() method will fire when user delete the product from the cart*/
  deleteFromCart(index) {
    this.cartService.removeCartItems(index)
    this.changeQuantity()
  }


  /*Below addToWishlist() method will fire when user click on save to wishlist from the cart*/
  addToWishlist(data: any, index) {
    debugger
    this.seletedItemforWishlist = []
    this.seletedItemforWishlist.push(data)
    this.ItemId = 0;
    this.itemSize = "";

    if (this.wishListItems.length != 0 && this.wishListItems != "" && this.wishListItems != undefined && this.wishListItems != null) {
      this.wishListItems.map((a: any) => {
        if (a.Productid == this.seletedItemforWishlist[0].Productid) {//checking if the product is existing in the cartlist with same id
          if (a.Pack == this.seletedItemforWishlist[0].Pack) {//checking if the product is existing in the cartlist with same pack
            this.ItemId = a.Productid
            this.itemSize = a.Pack
          }
        }
      })

      if (this.itemSize == this.seletedItemforWishlist[0].Pack) { //if product with same size then we show toast msg like below
        this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in wishlist' });
      }
      else {
        this.wishlistService.setProducts(this.seletedItemforWishlist[0])
        this.insertWishListItems(this.seletedItemforWishlist[0])
        this.deleteCartfromTable(index, this.seletedItemforWishlist[0].CartId)/*after adding to wishlist remove from the cart*/
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to wishlist successfully' });
      }
    }
    else {
      this.wishlistService.setProducts(this.seletedItemforWishlist[0])
      this.insertWishListItems(this.seletedItemforWishlist[0])
      this.deleteCartfromTable(index, this.seletedItemforWishlist[0].CartId)/*after adding to wishlist remove from the cart*/
      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to wishlist successfully' });
    }
    this.seletedItemforWishlist = []
    //this.wishlistService.setProducts(this.seletedItemforWishlist)
    //this.deleteFromCart(index)/*after adding to wishlist remove from the cart*/
    //this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to wishlist successfully' });
  }


  /*
   *Get Customer Delivery Address 
   */
  getCustomerAddress() {
    debugger
    let Address = [{
      UserId: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(Address));
    var url = "/api/Products/Get_Customer_Address_Based_On_Id"
    
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.addressDetails = data;
        this.plus = false
      }
    });
  }
  sendsmsToAdmin() {
    debugger
      this.addressDetails1 = this.addressDetails

    var AdMobile = '9866041417'
      var MessageText = "A new order has been placed by the customer" +':'+ this.LoginDetails.FarmerName + ':' + this.LoginDetails.MobileNo + ':'+ this.addressDetails1[0].Area + "." + "Ykmart";
      var UploadFile = new FormData();
      var url = "api/Products/Customer_new_orderPlacement"
      debugger
      UploadFile.append("Name", this.LoginDetails.FarmerName);
      UploadFile.append("Mobile", this.LoginDetails.MobileNo);
      UploadFile.append("ID", this.addressDetails1[0].Area);
      UploadFile.append("AdMobile", AdMobile);
      UploadFile.append("Text", MessageText);

      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
      })
    }

  sendsmsToAdmin1() {
    debugger
    this.addressDetails1 = this.addressDetails

    var AdMobile = '8008336307'
    var MessageText = "A new order has been placed by the customer" + ':' + this.LoginDetails.FarmerName + ':' + this.LoginDetails.MobileNo + ':' + this.addressDetails1[0].Area + "." + "Ykmart";
    var UploadFile = new FormData();
    var url = "api/Products/Customer_new_orderPlacement"
    debugger
    UploadFile.append("Name", this.LoginDetails.FarmerName);
    UploadFile.append("Mobile", this.LoginDetails.MobileNo);
    UploadFile.append("ID", this.addressDetails1[0].Area);
    UploadFile.append("AdMobile", AdMobile);
    UploadFile.append("Text", MessageText);

    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
    })
  }

  
  sendsms1() {
    debugger
    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
    this.admin = '8008336307'
    //this.admin = '8008336307/9866041417'
    this.addressDetails1 = this.arr[0].Price

    var url = 'api/Products/Send_sms_custuomer_orderplaced'; 

    let formdata = new FormData();
    formdata.append("details", this.addressDetails1)
    formdata.append("order", this.admin)
    formdata.append("mobile", this.LoginDetails.MobileNo)

    this.generalService.PostData(url, formdata).then((data: any) => {
    })
  }
  sendsms2() {
    debugger
    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
    this.admin = '8008336307'
    //this.admin = '9866041417/8008336307'
    this.addressDetails1 = this.arr[0].Price
  
    var url = 'api/Products/Send_sms_custuomer_orderplaced2';

    let formdata = new FormData();
    formdata.append("details", this.addressDetails1)
    formdata.append("order", this.admin)
    formdata.append("mobile", this.LoginDetails.MobileNo)

    this.generalService.PostData(url, formdata).then((data: any) => {
       this.sendsms2();
    })
  }
  sendMailToAdmin(val) {
    debugger

    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
     let arr = [];
    arr.push({
      OrderID: this.addressDetails1[0].OrderID,
      FarmerName: this.addressDetails1[0].FarmerName,
      MobileNo: this.addressDetails1[0].MobileNo,
      Area: this.addressDetails1[0].Area,
      City: this.addressDetails1[0].City,
      Email: this.addressDetails1[0].Email,
      //ProductName: this.orderDetails[0].ProductName,

    })
    var UploadFile = new FormData();
    var url = "api/Products/Assginoredr_aftersend_mailToAdmin"

    UploadFile.append("Xml", JSON.stringify(arr));
    UploadFile.append("flag", val);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
    })
  }

  deleteaddress(index, Id) {
    this.cartIndex = 0;
    this.cartIndex = index;
    let formData: FormData = new FormData();
    formData.append('Id', Id);
    var url = "api/Products/DeleteAddress"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {

        this.getCustomerAddress();
      }
    });
  }
  /**
   * below place order method will called when the custor click to place order
   */

  /*rediret payment gateway*/
  RedirectToPaymentGateway(CartProducts, n) {
    debugger
    // alert('hello')
    var UploadFile = new FormData();
    this.subTotal = this.totalPrice + this.deliveryCharge;
    // this.subTotal = this.subTotal + this.deliveryCharge
    UploadFile.append('Name', this.LoginDetails.FarmerName);
    UploadFile.append('Mobile', this.LoginDetails.MobileNo);
    UploadFile.append('Email', this.LoginDetails.Email);
    UploadFile.append('Amount', this.subTotal);
    UploadFile.append('Productinfo', "Product");
    UploadFile.append('TransactionId', n);
    UploadFile.append('BookingDetails', JSON.stringify(CartProducts));
    //alert('Start')
    var url = "/api/Products/PaymentGateWayWEB";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger

     
      window.location.href = data.toString();
   



      this.cancel()
      // alert ('hello')
      // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

      this.cartService.removeAllcart()
      this.val = 1
    
      this.router.navigate['/Home'];
      debugger
    
    


    }, err => {
      this.router.navigate['/Cart'];
    });
    var arr = [];
    arr.push({
      TokenID: this.LoginDetails.Token,
      UserId: this.LoginDetails.Id
    })
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(arr));
    var url = "/api/Products/Get_All_Orders"
    this.generalService.PostData(url, formData).then((data1: any) => {
      debugger

      if (data1 != "") {
        debugger
        this.OrderDetails = data1;
        this.plus = false;
        if (this.OrderDetails[0].TransactionStatus == 'Success') {
          this.sendsmsToAdmin();
          this.sendsmsToAdmin1();
          this.sendsms1();
          this.sendsms2();
        }
      }
    });
  }
  /*end*/


  PlaceOrder() {
    debugger

    this.arr = [];
    this.n = ""
    var d = new Date();
    this.n = d.getTime();

    //this.offerPrice = !this.offerPrice ? 0 : !this.offerPrice;
    for (var i = 0; i < this.cartItems.length; i++) {
      this.arr.push({
        CustomerID: this.addressDetails[0].UserId,
        AddressId: this.addressId,
        PaymentType: this.PaymentType,
        ProductId: this.cartItems[i].Productid,
        Quantity: this.cartItems[i].Quantity,
        Size: this.cartItems[i].Pack,
        Price: this.cartItems[i].Price,
        TransactionID: this.n,
        GstPrice: this.cartItems[i].GstPrice, OfferPrice: this.offerPrice,
        DeliveryCharge: this.deliveryCharge,
        TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
      //  TotalPrice: this.totalPrice,
        CreatedBy: this.LoginDetails.Id,
        Device:'Web'
      })
    }
    this.RedirectToPaymentGateway(this.arr, this.n)
    let formData: FormData = new FormData();
    
    formData.append('Param', JSON.stringify(this.arr));
    var url = "/api/Products/Insert_Customer_Orders"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {

        //if (this.PaymentType != "CashOnDelivery") {
        //  this.RedirectToPaymentGateway(this.arr, this.n)

        //}


        if (this.PaymentType = "CashOnDelivery")  {
          this.cancel()
          this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });
        //  this.sendsms1();
        //  this.sendsmsToAdmin();
         
         // this.sendsms2();
          this.cartService.removeAllcart();
          this.val = 1


          this.sendMailToAdmin(this.val)
          this.router.navigate['/Home'];
        }
        //else {
        //  this.RedirectToPaymentGateway(this.arr, this.n)

        //}

      }
     
    });
  }

  cancel() {
    $('#myModal').modal('hide');
  }






  /*
    Below method will fire when the user selected address 
   */
  getSelectedAddress(addressId: any) {
    //this.addressId = addressId;
    //console.log(addressId)
  }

  /*
     Below method is used to call the delivery mode whether it is online or offline
  */
  getPaymentType(pType) {
    this.PaymentType = pType
  }



  getAllAdminOrders() {
    debugger
    var arr = [];
    arr.push({
      TokenID: this.LoginDetails.Token,
      UserId: this.LoginDetails.Id
    })
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(arr));
    var url = "/api/Products/Get_All_Orders"
    this.generalService.PostData(url, formData).then((data1: any) => {
      if (data1 != "") {
        debugger
        this.OrderDetails = data1;
        this.plus = false
      }
    });

  }




 

  /*below method is called when the user delete product when he is login*/
  deleteCartfromTable(index, CartId) {
    debugger
    console.log(CartId);
    this.cartIndex = 0;
    this.cartIndex = index;
    let formData: FormData = new FormData();
    formData.append('Cartid', CartId);
    var url = "/api/Products/DeleteCartItems"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.deleteFromCart(this.cartIndex)
        this.getCustomerAddress();

      }
    });
  }



  /* * Navigating to selected Products page  regarding category
   */
  navigatingToProduct(data) {
    var prodName = "";
    var str = data.split(" ")//spliting the product name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.router.navigate(['/Product/' + prodName]); ///sandhya
  }

  /*
 Below method is for Inserting customer WishList items
 */
  insertWishListItems(wishlist) {
    debugger
    this.WishListitemmsforloginusers = []
    //this.WishListitemmsforloginusers.push(wishlist)
    this.WishListitemmsforloginusers.push({
      UserId: String(this.LoginDetails.Id),
      Productid: wishlist.Productid,
      Quantity: wishlist.Quantity,
      Price: wishlist.Price,
      Pack: wishlist.Pack
    })
    var url = "api/Products/Insert_WishList_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(this.WishListitemmsforloginusers));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {

      }
    })
  }

  ////sandhya for offer calculation start
  /*Below method is called when the user check for the offer is valid or not*/
  checkOfferisValidorNot(code) {
    //let formData: FormData = new FormData();
    //formData.append('Param', code);
    //var url = "/api/Products/Get_Offers_Details_Basedon_OfferCode"
    //// this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    //this.generalService.PostData(url, formData).then((data: any) => {
    //  if (data != "") {
    //    this.offerDetails = data;
    //    this.offerPrice = (this.subTotal * this.offerDetails[0].Discount) / 100
    //    this.totalPrice = (this.subTotal - this.offerPrice)
    //    if (this.totalPrice < 5000) {
    //      this.deliveryCharge = 200;
    //      this.totalPrice = this.totalPrice + this.deliveryCharge
    //    }
    //    else {
    //      this.deliveryCharge = 0;
    //      this.totalPrice = this.totalPrice + this.deliveryCharge
    //    }
    //  }
    //  else {
    //    this.offerDetails = null;
    //    this.offerPrice = 0;
    //    this.totalPrice = this.subTotal
    //    if (this.totalPrice < 5000) {
    //      this.deliveryCharge = 200;
    //      this.totalPrice = this.totalPrice + this.deliveryCharge
    //    }
    //    else {
    //      this.deliveryCharge = 0;
    //      this.totalPrice = this.totalPrice + this.deliveryCharge
    //    }
    //  }
    //});
    this.offerDetails = this.offersdata.filter(a => a.PromoCode == code);
    this.alert = "";
    if (this.offerDetails.length > 0) {
      this.offerDetails = this.offerDetails[0];
      if (this.offerDetails.MaxAmount <= this.subTotal) {
        this.offerPrice = this.offerDetails.ApplicableAmount;
        this.offerDetails.subTotaloff = this.subTotal - this.offerPrice;
        this.offerDetails.totalPriceoff = this.totalPrice - this.offerPrice;
      } else {
        let amount = this.offerDetails.MaxAmount - this.subTotal;
        this.alert = "Shop for " + amount + " more to apply offer";
      }
    } else {
      this.alert = "Not Available";
    }
  }

  /*getting offers*/
  GetAllOffers() {
    var url = "api/Products/GetAllOffers"
    this.generalService.GetData(url).then(data => {
      this.offersdata = data;
      this.offersdata = this.offersdata.filter(a => a.OfferType != "EXPIRED" && a.Status);
      console.log(this.offersdata)
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*end code*/
  changeData() {
    this.offerPrice = 0;
    this.offerDetails = "";
  }
  ////sandhya for offer calculation end

 RazorPayPayment() {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        var options: any = {
            description: 'YKMart ',
            image: 'https://ykmart.in/assets/img/IMG-20220210-WA0049a.jpg',
            currency: 'INR',
            //key: 'rzp_test_PKZRt4NGyfabME',// Test key
           // key: 'rzp_live_LnpQ4EQWubWJOo',// Ggari key
            key: 'rzp_live_VcpmG5M8UJUp01',// Ykmart
            payment_id: n,
            payment_capture: '1',
            amount: this.totalPrice*100,
            //callback_url:"https://ykmart.in//",
            name: "YKMart ",
            prefill: {
                // email: this.LoginDetails.Email,
                contact: +91 + "" + this.LoginDetails.MobileNo,
                name: this.LoginDetails.FarmerName
            },
            theme: {
                color: '#008cb3'
            },
         handler: this.razorPayHandler.bind(this) // Define handler function
        }
        debugger
        var rzp1 = new Razorpay(options);
        //options['handler'] = this.razorPaySuccessHandler.bind(this);
        rzp1.open();
        //rzp1.on();
    }

// Define handler function to capture payment response
 razorPayHandler(response: any){
   console.log(response);
   debugger
  if(response.razorpay_payment_id){
    localStorage.setItem("PaymentId",response.razorpay_payment_id);
    console.log('Payment Succesfull:',response.razorpay_payment_id);
   this.InsertUSer();
   }else{
    console.log('payment failed or cancelled')
  }

 }


InsertUSer(){
 debugger
    this.arr = [];
    this.n = ""
    var d = new Date();
    this.n = d.getTime();
      
       for (var i = 0; i < this.cartItems.length; i++) {
      this.arr.push({
        CustomerID: this.addressDetails[0].UserId,
        AddressId: this.addressId,
        PaymentType: this.PaymentType,
        ProductId: this.cartItems[i].Productid,
        Quantity: this.cartItems[i].Quantity,
        Size: this.cartItems[i].Pack,
        Price: this.cartItems[i].Price,
        TransactionID: this.n,
        GstPrice: this.cartItems[i].GstPrice, OfferPrice: this.offerPrice,
        DeliveryCharge: this.deliveryCharge,
        TotalPrice: this.cartItems[i].Price * this.cartItems[i].Quantity,
      //  TotalPrice: this.totalPrice,
        CreatedBy: this.LoginDetails.Id,
        Device:'Web' , TransactionStatus:'Success'
      })
    }
   
  
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(this.arr));
    var url = "/api/Products/Insert_Customer_Orders"
    this.generalService.PostData(url, formData).then((data: any) => {
     debugger
      if (data != "") {
        if (data == "SUCCESS") {
          this.sendsms1();
          this.sendsms2();
          this.sendsmsToAdmin();
          this.sendsmsToAdmin1();
     this.generalService.ShowAlert('Success','Your Order Placed Succesfully','success')
          this.router.navigate(['/MyOrders']);
        }
        else {
     $('#myModal').modal('hide');
       this.sendsms1();
         this.sendsmsToAdmin();
          this.sendsmsToAdmin1();
       this.generalService.ShowAlert('SUCCESS','Your Order Placed Succesfully','success')
         this.router.navigate(['/MyOrders']);
        }
      }
    });

}


}






