<br /><p-toast position="top-center"></p-toast>
      <div class="container">
        <div class="row">
          <div class="col-lg-10">
            <div style="font-size: 20px;letter-spacing: 0.3px;line-height: 30px;">
            Wishlist items({{wishListItems.length}})</div>
          </div>
        </div> 
        <hr style=" margin-top: 0;" />

        <div class="row">
          <div class="col-lg-3" *ngFor="let item of wishListItems;let i=index">
            <div class="addcartCard">
              <div (click)="navigatingToProduct(item)">
                <img src={{HomeURL+item.Image}} style="height:152px;width:135px;cursor:pointer" />
              </div>
              <div>
                <b>{{item.productname}}-{{item.CategoryName}}-{{item.Pack}}.</b>
              </div>
              <div class="ratefont">
                Price: ₹{{item.Price*item.Quantity}}.00
              </div>
              <div class="ratefont">
                Sold by: YK Laboratories
              </div>
              <button type="button" class="cartbtn" (click)="movetoCart(item,i)">Move to Cart</button>
              <span class="deletefont" (click)="deleteFromwishList(i,item.WishlistId)">Delete</span>
            </div>
          </div>
        </div>
      </div>

