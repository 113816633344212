import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  providers: [MessageService]
})
export class MyOrdersComponent implements OnInit {
  orderDetails: any;
  productDetails: any;
  OrderedProductList: any;
  LoginDetails: any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: any;
  ProductName: any;
    Comments: any;
    prList: any[];
  products: any;
  Comment: any;
  Product: any;
  constructor(public generalService: GeneralService, public router: Router, public toast: MessageService, ) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
  }

  ngOnInit(): void {
    this.getCustomerOrderDetails()
   
  }
  /*
   *Geting customer order details by sending customer id to the server 
   */
  getCustomerOrderDetails() {
    let details = [{
      CustomerID: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(details));
    var url = "/api/Products/Get_All_Customer_Orders_Basedon_CustomerID"
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.OrderedProductList = data;
      }
    });
  }

  /*Below method is used to navigating to order invoice page*/
  navigateToInvoice(orderid) {
    this.router.navigate(['/Invoice/' + orderid]);
  }
  getProductDetails(obj: any) {
    debugger
    this.productDetails = obj;
    var arr = [];
    arr.push({
      TokenID: this.LoginDetails.Token,
      UserId: this.LoginDetails.Id,
      OrderID: this.productDetails.OrderID
    })
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(arr));
    var url = "/api/Products/Get_Admin_Orderdetails_BasedOn_OrderId"
    this.generalService.PostData(url, formData).then((data: any) => {
      debugger
      if (data != "") {
        this.orderDetails = [];
        this.orderDetails = data;
        //console.log(this.orderDetails);
      }
    });
    //console.log(this.productDetails);
  }
  countStar(star) {
    this.selectedValue = star;
    //console.log('Value of star', star);
  }
  rattingSubmit(Product,Comment) {
    debugger
    let details = [{
     
      Id: 0,
      CustomerId: this.LoginDetails.Id,
      ProductId: Product.ProductId,
      Starscount: this.selectedValue ,
      Comments: Comment,
      Status: 1,
      CreatedBy: 1
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(details));
    var url = "api/Products/SaveCustomerRating"
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        debugger
        //this.OrderedProductList = data;
        this.Product = '';
        this.Comment = '';

        this.selectedValue = '';
        
        
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Submit Successfully' });

       //this.generalService.ShowAlert('SUCCESS', 'Submit Successfully.', 'success')
       
        
      }
    });
  }
  // The dropdown and search filter order products are linked by this code.
  filterGroupsSingleforGoal(event) {
    debugger
    let filtered: any[] = [];
    let query = event.query;
    {
      this.prList = [];
      for (let i = 0; i < this.orderDetails.length; i++) {
        let order = this.orderDetails[i];
        if (order.ProductName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(order);
        }
      }
      this.prList = filtered;
    }
  }
}
