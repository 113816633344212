
<div class="container-fluid topBar top-container">
  <div class="container">
    <div class="row ">
      <div class="col-lg-5 navgap">
        <a _ngcontent-c1="" href="callto:(+84)1234-5678" style="color:#fff;">
          <i class="fa fa-phone" style="margin-right: 4px;"aria-hidden="true"></i> <span>(+91) 949 141 8181 / (+91) 970 512 9639</span>
        </a>
      </div>
      <div class="col-lg-4 navgap" style="text-align:right">
        <i class="fa fa-envelope-o" aria-hidden="true"></i>&nbsp;
        <a href="mailto: info@ykmart.in" style="color:white;">info@ykmart.in</a>
      </div>
      <div class="col-lg-3 top1">
        <div id="google_translate_element"></div>
      </div>
    </div>
  </div>
</div>

<!--Mobile Code start-->
<div class="row  mobileCard">
  <div class="hidden-lg col-lg-12  mobileCard1 hidd">
    <ul class="nav-mobile">
      <li>
        <div class="row header_new">

          <div class="col-xs-7 search_p">

            <p-autoComplete placeholder="Search your Product...!" [(ngModel)]="selectedProduct" [suggestions]="searchedProducts"
                            (completeMethod)="filterProducts($event)" class="sea_1" field="ProductName" [dropdown]="false">
              <div style="width:100px;">
                <ng-template let-country pTemplate="item" style="height: 34px; width: 121px;">
                  <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                    <div class="a_g99" style="width:182px"> <!--<img src="{{HomeURL+country.Image}}" style=""class="homp_1" />---> {{country.productname}}</div>
                  </div>
                </ng-template>
              </div>
            </p-autoComplete>




          </div>
          <div class="col-xs-3 mt-2" style="padding:0">
            <span>
              <a routerLink="/Cart" class=" cart1">
                <i class="fa fa-cart-plus cart_0" aria-hidden="true">({{countReturn()}})</i>
              </a>&nbsp;

            </span>
          </div>
        </div>
      </li>

      <li class="menu-container">

        <input id="menu-toggle" type="checkbox">
        <label for="menu-toggle" class="menu-button">
          <img src="../../../assets/img/download (1).png" style="width: 31px;float:left" />

        </label>
        <!--<a href="/Home">
          <img src="../../../assets/img/IMG-20220210-WA0049a.jpg" style="height: 30px;float:right" />
        </a>-->

        <ul class="menu-sidebar">
          <li><a routerLink="/Home"><b style="color:black;width:100%;cursor:pointer;">Home</b></a></li>
          <li>
            <select class="catDropdown nav-item" (change)='navigateToCategoryProducts($event.target.value)'
                    style="width:100%;cursor:pointer;  text-transform: capitalize;margin-left:10px;">
              <option [ngValue]="0">Shop By category</option>
              <option *ngFor="let link of Categories;let i=index;" [ngValue]="link.CategoryId">
                {{link.CategoryName | lowercase | titlecase}}
              </option>
            </select>
          </li>
          <!--<li>
      <select class="catDropdown nav-item" (change)='navigateToGoalProducts($event.target.value)'
              style="width:100%;cursor:pointer; text-transform: capitalize;margin-left:10px;">
        <option [ngValue]="0">Shop By Goal</option>
        <option *ngFor="let link of Goals;let i=index;" [ngValue]="link.Id">
          {{link.GoalName}}
        </option>
      </select>
    </li>-->
          <li>
            <select class="catDropdown nav-item" (change)='navigateToFarmingProducts($event.target.value)'
                    style="width:100%;cursor:pointer;padding-right:10px; text-transform: capitalize;margin-left:10px;">
              <option [ngValue]="0">Farming Produces</option>
              <option *ngFor="let link of FarmingProduces;let i=index;" [ngValue]="link.Id">
                {{link.SpecialityName | lowercase | titlecase }}
              </option>
            </select>
          </li>
          <li *ngIf="!logedin()">
            <div routerLink="/CustomerLogin" style="font-size:18px;">
              <i class="fa fa-user-o imggap" aria-hidden="true">&nbsp;Login</i>
            </div>
            <!--<p style="margin-left:5px;">Login</p>-->
          </li>
          <li *ngIf="logedin()">
            <div class="dropdown">
              <label>Hii {{logedinUser}}<b style="font-size:11px;">(My Account)</b></label>
              <div class="dropdown-content" style="line-height:20.5px;">
                <a routerLink="/MyOrders"><b>My Orders</b></a>
                <a routerLink="/MyEnquiries"><b>My Enquiries</b></a>
                <a routerLink="/Address"><b>Add Address</b></a>
                <a routerLink="/Wishlist" class="nav-link active">
                  <i class="fa fa-heart-o" aria-hidden="true"><b style="font-size:18px;font-family:poppins;">wishlist</b></i>
                </a>
                <a (click)="logout()"><b>Logout</b></a>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<!--Mobile Code End-->


<!--Web Code start-->
<nav class="navbar  navbar-expand-lg bg-dark  header" id="myHeader">
  <div class="container">
    <a href="/" class="navbar-brand">


      <i class="fas fa-blog"></i> &nbsp;
      <img src="../../../assets/img/IMG-20220210-WA0048.jpg" style="height: 63px;margin-top: -11px; " />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto w-100 justify-content-end">
      
        <li class="nav-item homegap" style="padding-top: 14px !important; padding-right: 28px; margin-top: -1px;">
          <a href="/" class="catDropdown nav-item">Home</a>
          
        </li>
        <li class="nav-item" style="padding-top: 11px !important;padding-right: 13px;">
          <select class="catDropdown nav-item" (change)='navigateToCategoryProducts($event.target.value)'>
            <option [ngValue]="0"style="padding:10px">
              Shop By Category
            </option>
            <option *ngFor="let link of Categories;let i=index;" [ngValue]="link.CategoryId">
               {{link.CategoryName | lowercase | titlecase}} 
            </option>
          </select>
        </li>
        <li class="nav-item" style="padding-top: 11px !important;padding-right: 0px;">
          <select class="catDropdown nav-item" (change)='navigateToFarmingProducts($event.target.value)'>
            <option [ngValue]="0">Farming Produces</option>
            <option *ngFor="let link of FarmingProduces;let i=index;" [ngValue]="link.Id">
              {{link.SpecialityName | lowercase | titlecase}}
            </option>
          </select>
        </li>
        <li class="sea_0">

          <p-autoComplete placeholder="Search your Product...!" [(ngModel)]="selectedProduct" [suggestions]="searchedProducts"
                          (completeMethod)="filterProducts($event)" field="ProductName" [dropdown]="false">

            <ng-template let-country pTemplate="item">
              <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                <div class="a_g99"> <img class="homp" src="{{HomeURL+country.Image}}" style="width: 25px;" />- {{country.productname}}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </li>
        <li *ngIf="!logedin()">
          <a class="nav-link active" routerLink="/CustomerLogin" style="font-size:18px;">
            <i class="fa fa-user-o imggap" aria-hidden="true"></i>Login
          </a>
        </li>
        <li *ngIf="logedin()" class="nav-item">
          <div class="dropdown">
            <label class="dropbtn">Hii {{logedinUser}}</label>
            <div class="dropdown-content">
              <a routerLink="/MyOrders">My Orders</a>
              <a routerLink="/MyEnquiries">My Enquiries</a>
              <a routerLink="/Address">Add Address</a>
              <a (click)="logout()">Logout</a>
            </div>
          </div>
        <li class="nav-item">
          <a routerLink="/Cart" class="nav-link active" style=" font-size:18px;color:#92278f">
            <i class="fa fa-cart-plus" aria-hidden="true">({{countReturn()}})</i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!--Web Code End-->

