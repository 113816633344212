<p-toast position="top-center"></p-toast>




<div class="container-fluid" style="background:#fff">
  <br />
  <div class="container">
    <ol class="breadcrumb">
      <li>
        <a routerLink="/"><i class="fa fa-home mr-2"></i>Home</a>
      </li>
      <li>
        <a style="cursor:pointer" (click)="navigatingtoFarmerProduces(productDetails[0].SpecialityName)">{{productDetails[0].SpecialityName}}</a>
      </li>
      <li>
        <a>{{ProductName}}</a>
      </li>
    </ol>

    <div class="row">
      <div class="col-lg-4 ">
        <div class="imgCrd scrollCard">
          <img src={{HomeURL+productDetails[0].Image}}
               class="cart_img" alt="...">
        </div>

      </div>
      <div class="col-lg-5">
        <div class="decCard">


          <h4 class="mainHeader">{{productDetails[0].productname}}</h4>
          <!---{{productDetails[0].Pack1}}-->
          <!--<div>
    <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
    <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
    <i class="fa fa-star rateColor" aria-hidden="true"></i>
    <span class="rate">32 rate</span>
  </div>-->
          <h5 class="mainHeader">
            Share

            <span style="cursor:pointer"><i class="fa fa-facebook-official" aria-hidden="true" style="color:cornflowerblue;"></i> &nbsp;<i (click)="sendWhatsapp(productDetails[0])" class="fa fa-whatsapp" aria-hidden="true" style="color:green;"></i></span>
          </h5>

          <!--<div class="ratefont">
    M.R.P : <span class="mrp">₹720</span>
  </div>-->
          <div class="ratefont">
            Size : {{currntPacksize}}
          </div>

          <!--<div class="ratefont">Price : <span class="acmrp">₹{{currentPrice}}</span></div>-->
          <div class="row">

            <div class="col-lg-0" style="padding-right:10px;width:75px;margin-left:7px;" (click)="selectPrice(productDetails[0].Pack1,productDetails[0].Price1)">
              <div [class]="(currntPacksize === productDetails[0].Pack1) ? 'defaultpricecard' : 'pricecard'" style="color:black;">
                <div>{{productDetails[0].Pack1}}</div>
              </div>
            </div>
            <div *ngIf="productDetails[0].Pack2!=''" class="col-lg-0" style="padding-right:10px;width:75px;" (click)="selectPrice(productDetails[0].Pack2,productDetails[0].Price2)">
              <div [class]="(currntPacksize === productDetails[0].Pack2) ? 'defaultpricecard' : 'pricecard'" style="color:black;">
                <div class="">{{productDetails[0].Pack2}}</div>
              </div>


            </div>
            <div *ngIf="productDetails[0].Pack3!=''" class="col-lg-0" style="padding-right:10px;width:75px;" (click)="selectPrice(productDetails[0].Pack3,productDetails[0].Price3)">
              <div [class]="(currntPacksize === productDetails[0].Pack3) ? 'defaultpricecard' : 'pricecard'" style="color:black;">
                <div class="">{{productDetails[0].Pack3}}</div>
              </div>
            </div>
            <div *ngIf="productDetails[0].Pack4!=''" class="col-lg-0" style="padding-right:2px;width:65px;" (click)="selectPrice(productDetails[0].Pack4,productDetails[0].Price4)">
              <div [class]="(currntPacksize === productDetails[0].Pack4) ? 'defaultpricecard' : 'pricecard'" style="color:black;">
                <div class="">{{productDetails[0].Pack4}}</div>
              </div>
            </div>
          </div>
          <!--<div class="ratefont">
            Brand : YK laboratories
          </div>-->
          <!--<div class="ratefont">
            Volume : {{productDetails[0].Pack1}}
          </div>-->
          <div class="aboutFont" style="font-family:Avenir LT Std;font-size:29px;">
            Product Information
          </div>
          <p class="text-align_j" [innerHtml]="ProductInfo" style="color:black;">
            <!--{{productDetails[0].ProductInfo}}-->
          </p>
        
          <div class="aboutFont" style="font-family:Avenir LT Std;font-size:29px;">
            Benefits
          </div>
          <p class="text-align_j" [innerHtml]="benefits" style="color:black;">
          </p>
          <!--<div class="aboutFont">
    Recommended Crops
  </div>
  <p class="text-align_j" [innerHtml]="recomendedCrops">
  </p>
  <div class="aboutFont">
    Benefits
  </div>
  <p class="text-align_j" [innerHtml]="benefits">
  </p>
  <div class="aboutFont">
    Dosage
  </div>
  <p class="text-align_j" [innerHtml]="dosage">
  </p>-->
          <!--<div class="aboutFont">
       Ingredients
  </div>
   <p class="text-align_j" [innerHtml]="ingrediants">
  </p>-->
          <!--<div class="aboutFont">
    Usages
  </div>
  <p>
    {{productDetails[0].Usages}}
  </p>-->
        </div>
      </div>
      <div class="col-lg-3" >
        <div class="scrollCard">
          <div style="text-align: center; font-family:arial;font-size: 19px;    font-weight: 700;  color: #831f41;
    letter-spacing: 0.5px; padding: 1rem; border-bottom: 1px dashed #831f41; margin-bottom: 1rem;">CART FOR ENQUIRY</div>

          <!--<h5 class="mainHeader"style=" color: #831f41;    padding-left: 10px;">
            Share
            <span style="cursor:pointer"><i class="fa fa-facebook-official" aria-hidden="true"style="color:cornflowerblue;"></i> &nbsp;<i (click)="sendWhatsapp(productDetails[0])" class="fa fa-whatsapp" aria-hidden="true"style="color:green;"></i></span>
          </h5>-->
          <div *ngIf="logedin()" class="addcartCard">
            <!--<div class="ratefont">Price : <span class="acmrp">₹{{Quantity*currentPrice}}</span></div>-->
            <div >
              Quantity
              <span>
                <select [(ngModel)]="Quantity">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </span>
            </div><br />
            <textarea class="enquiryFields" [(ngModel)]="Comments" placeholder="Enter Comments"style="padding-left:1rem;
border: 1px solid rgba(131, 31, 65, 0.37);width:230px;font-weight:bolder;height:87px;"></textarea>
            <div>
              <button type="button" class="cartbtn" (click)="PlaceEnquiry()">Add to Enquiry</button>
            </div>
          </div>
          <div *ngIf="!logedin()" class="addcartCard">
            <!--<div class="ratefont">Price : <span class="acmrp">₹{{Quantity*currentPrice}}</span></div>-->
            <div>
              <div>
                Quantity
                <span>
                  <select [(ngModel)]="Quantity">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                </span>
              </div>
              <br />


          <input type="text" [(ngModel)]="Name" placeholder="Enter Name" style="padding-left: 1rem;font-weight:bolder;border: 1px solid rgba(131, 31, 65, 0.37);width: 240px;margin-left: -7px;" />
        <br /><br />
     <input class="enquiryFields" type="text" [(ngModel)]="Mobile" placeholder="Enter Mobile No" style="padding-left: 1rem;font-weight:bolder;margin-left: -7px;border: 1px solid rgba(131, 31, 65, 0.37);width: 240px;" />
              <br /><br />

              <!--<br />
  <input class="enquiryFields" type="text" [(ngModel)]="HNo" placeholder="HNo" />
  <br />
  <input class="enquiryFields" type="text"[(ngModel)]="Area" placeholder="Enter Area" />
  <br />
  <input class="enquiryFields" type="text" [(ngModel)]="Landmark" placeholder="Enter Landmark" />-->
              <input class="enquiryFields" type="text" [(ngModel)]="City" placeholder="Enter City" style="padding-left: 1rem;font-weight:bolder;margin-left: -7px;border: 1px solid rgba(131, 31, 65, 0.37);width: 240px;" />
              <br /><br />
              <input class="enquiryFields" type="text" [(ngModel)]="State" placeholder="Enter State" style="padding-left: 1rem;font-weight:bolder;margin-left: -7px;border: 1px solid rgba(131, 31, 65, 0.37);width:240px;" />  <br /><br />
         <textarea class="enquiryFields" [(ngModel)]="Comments" placeholder="Enter Comments" style="width: 240px;height: 100px;font-weight:bolder;margin-left: -7px;padding-left:1rem;border: 1px solid rgba(131, 31, 65, 0.37);"></textarea><br />

            </div>
            <div>
     <button type="button" (click)="PlaceEnquiryNormalUser()" class="cartbtn" style=  "border-radius: 5px; font-family: arial;
    font-weight: bold; letter-spacing: 0.5px;  cursor: pointer; width: 10rem !important;background-color:#dd31608f;  padding: 1rem;margin-left:3rem;">SUBMIT</button>
            </div>
          </div>
          <br />
          <div *ngIf="logedin()">
            <div *ngFor="let item of addressDetails; let i=index" class="addcartCard">
              <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.Id}}" (change)="getSelectedAddress(item.Id)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
              <div class="ratefont">
                <!--{{item.Address}}-->
                {{item.Area}}, {{item.HNo}}, {{item.Landmark}}, {{item.City}}, {{item.State}}, {{item.Pincode}}
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="aboutFont1">
      YKmart youtube Video

    </div>
    <!--------------------------       New productes          ------------------------------>
    <div class="new-producetes">
      <iframe class="fancybox" rel="ligthbox" width="400" height="315" [src]="videoUrl | uvideosafe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!--Carousel Wrapper-->
            <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">
              <!--Controls-->
              <!--<div class="controls-top">
            <a class="btn-floating prev" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
            <a class="btn-floating next" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
          </div>-->
              <!--Slides-->
              <div class="carousel-inner" role="listbox">
                <!--First slide-->
                <!--<div class="carousel-item active">
              <div class="row">
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
              </div>
            </div>-->
                <!--Second slide-->
                <!--<div class="carousel-item">
              <div class="row">
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
              </div>
            </div>-->
                <!--Second slide-->
                <!--<div class="carousel-item">
              <div class="row">
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
                <div class="col-lg-2 cartpad">
                  <div class="custcards">
                    <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
                    <div class="custHead">Cyto Plus Organic</div>
                    <div>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                      <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                    </div>
                    <div class="custPrice">₹400</div>
                  </div>
                </div>
              </div>
            </div>-->
              </div>
              <!--Indicators-->
              <!--<ol class="carousel-indicators">
            <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
            <li data-target="#multi-item-example" data-slide-to="1"></li>
            <li data-target="#multi-item-example" data-slide-to="2"></li>
          </ol>-->
            </div>
            <!--/.Carousel Wrapper-->
          </div>
        </div>
      </div>
    </div>

    <hr />
    <!--<div class="aboutFont1">
      Related Products
    </div>
    <div class="row">
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71q7IFgLFoL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
      <div class="col-lg-2 cartpad">
        <div class="custcards">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/61wXE4jd3TL._AC_UL160_SR160,160_.jpg" />
          <div class="custHead">Cyto Plus Organic</div>
          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
          </div>
          <div class="custPrice">₹400</div>
        </div>
      </div>
    </div>-->

  </div>
</div>

<style>
  .custcards {
    border: 1px solid #c1c1c1;
    padding: 11px;
    border-radius: 4px;
  }

  .cartpad {
    padding-right: 0
  }
</style>


