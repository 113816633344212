import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { PagesComponent } from './pages/pages.component';
import { BrowserModule } from '@angular/platform-browser';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
/*Page-Layout components import place*/
import { PageLayoutComponent } from './page-layout/page-layout.component'
import { HomeComponent } from './page-layout/home/home.component'
import { PageHeaderComponent } from './page-layout/page-header/page-header.component'
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component'
import { CategoriesComponent } from './page-layout/categories/categories.component'
import { CustomerLoginComponent } from './page-layout/customer-login/customer-login.component'
import { AboutUsComponent } from './page-layout/about-us/about-us.component';
import { ContactUsComponent } from './page-layout/contact-us/contact-us.component';
//import { ProductDetailsComponent } from './page-layout/product-details/product-details.component'
import { BlogComponent } from './page-layout/blog/blog.component'
import { CartComponent } from './page-layout/cart/cart.component'
import { WishlistComponent } from './page-layout/wishlist/wishlist.component'
import { AddAddressComponent } from './page-layout/add-address/add-address.component'
import { GoalsComponent } from './page-layout/goals/goals.component'
import { FarmerProducesComponent } from './page-layout/farmer-produces/farmer-produces.component'
import { ProductEnquiryComponent } from './page-layout/product-enquiry/product-enquiry.component'
import { MyOrdersComponent } from './page-layout/my-orders/my-orders.component'
import { OrderInvoiceComponent } from './page-layout/order-invoice/order-invoice.component'
import { MyEnquiriesComponent } from './page-layout/my-enquiries/my-enquiries.component'
import { PrivacyPolicyComponent } from './page-layout/privacy-policy/privacy-policy.component'
import { ProductInformationComponent } from './page-layout/product-information/product-information.component';
import { TermsandConditionsComponent } from './page-layout/termsand-conditions/termsand-conditions.component';
import { CustomerRegistrationComponent } from './page-layout/customer-registration/customer-registration.component';
export const routes: Routes = [ 
  { path: '', redirectTo: 'Home', pathMatch: 'full' }, 
  //{ path:'', redirectTo: 'Products', pathMatch: 'full' },
  {

    path: '',
    component: PageLayoutComponent,
    children: [
      { path: 'Home', component: HomeComponent},
      { path: 'PageHeader', component: PageHeaderComponent },
      { path: 'PageFooter', component: PageFooterComponent },
      { path: 'Category/:ProductName', component: CategoriesComponent, data: { breadcrumb: 'Category' }  },
      { path: 'CustomerLogin', component: CustomerLoginComponent },
      { path: 'AboutUs', component: AboutUsComponent },
      { path: 'ContactUs', component: ContactUsComponent },
     // { path: 'Product/:ProductDeatails', component: ProductDetailsComponent, data: { breadcrumb: 'Category/ProductDeatails' } },
      { path: 'Blog', component: BlogComponent },
      { path: 'Cart', component: CartComponent },
      { path: 'Login', component: CustomerLoginComponent },
      { path: 'Wishlist', component: WishlistComponent },
      { path: 'Address', component: AddAddressComponent },
      { path: 'Goals/:Name', component: GoalsComponent,data: { breadcrumb: 'Goals' }},
      { path: 'FarmerProduces/:Name', component: FarmerProducesComponent, data: { breadcrumb: 'Farmer Produces' } },
      { path: 'EnquiryProducts/:jame', component: ProductEnquiryComponent },
      { path: 'MyOrders', component: MyOrdersComponent },
      { path: 'Invoice/:No', component: OrderInvoiceComponent },
      { path: 'MyEnquiries', component: MyEnquiriesComponent },
      { path: 'CustomerRegistration', component: CustomerRegistrationComponent },
      { path: 'PrivacyPolicy', component: PrivacyPolicyComponent },     
      { path: 'Product/:ProductDeatails', component: ProductInformationComponent, data: { breadcrumb: 'Category/ProductDeatails' } },
      { path: 'TermsandConditions', component: TermsandConditionsComponent },
    ]
  },

  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'Dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' } },
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },

    ]
  },  
  //{
  //  path: '', 
  //  component: PagesComponent,
  //  children:[
  //    { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },          
  //    { path: 'membership', loadChildren: () => import('./pages/membership/membership.module').then(m => m.MembershipModule), data: { breadcrumb: 'Membership' } },
  //    { path: 'ui', loadChildren: () => import('./pages/ui/ui.module').then(m => m.UiModule), data: { breadcrumb: 'UI' } },
  //    { path: 'form-elements', loadChildren: () => import('./pages/form-elements/form-elements.module').then(m => m.FormElementsModule), data: { breadcrumb: 'Form Elements' } },
  //    { path: 'tables', loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
  //    { path: 'tools', loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsModule), data: { breadcrumb: 'Tools' } },
  //    { path: 'calendar', loadChildren: () => import('./pages/calendar/app-calendar.module').then(m => m.AppCalendarModule), data: { breadcrumb: 'Calendar' } },
  //    { path: 'mailbox', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
  //    { path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule), data: { breadcrumb: 'Maps' } },
  //    { path: 'charts', loadChildren: () => import('./pages/charts/charts.module').then(m => m.ChartsModule), data: { breadcrumb: 'Charts' } },
  //    { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },  
  //    { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' }  },         
  //    { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
  //    { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } }
  //  ]
  //},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }

]; 

@NgModule({
  //imports: [
  //  RouterModule.forRoot(routes, { useHash: true })
  //],
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      //relativeLinkResolution: 'legacy',
      useHash: false
    })
  ],
  exports: [
    RouterModule, BrowserModule
  ]
})
export class AppRoutingModule { }
