import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-popular-purchase',
  templateUrl: './popular-purchase.component.html',
  styleUrls: ['./popular-purchase.component.scss']
})
export class PopularPurchaseComponent implements OnInit {
  HomeURL: any;
  ProductName: any;
  PopularProducts: any;
  LoginDetails: any;
  @Output() someEvent = new EventEmitter<string>();

  constructor(public generalService: GeneralService, public router: Router,
              public http: HttpClient) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.GetPopularPurchaseProducts()
  }

  /*
 Below method is for Getting Popular purchases products for customer 
 */
  GetPopularPurchaseProducts() {
    var url = "api/Products/GetPopularPurchaseProducts";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        this.PopularProducts = data;
        // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
      }
    })
  }

  /* popular product for navigation*/
  navigatingToPopularProd(item:any) {
    this.someEvent.next(item);
  }
  
}
