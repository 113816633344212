<div id="myCarousel" class="carousel slide mob_0" data-ride="carousel">
  <!-- Indicators -->
  <ol class="carousel-indicators" style="margin-bottom: -29px;">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
    <li data-target="#myCarousel" data-slide-to="3"></li>
    <li data-target="#myCarousel" data-slide-to="4"></li>
    <li data-target="#myCarousel" data-slide-to="5"></li>
    <li data-target="#myCarousel" data-slide-to="6"></li>
    <li data-target="#myCarousel" data-slide-to="7"></li>

  </ol>

  <!-- Wrapper for slides-->
  <div class="carousel-inner">
    <div *ngFor="let data of Banners; let i=index" [class]="(i === 0) ? 'item active' : 'item'">
      <img src={{HomeURL+data.Image}} alt="Y Mart" style="width:100%;object-fit:cover">
    </div>
  </div>

  <!-- Left and right controls -->
  <!--<a class="left carousel-control" href="#myCarousel" data-slide="prev">
    <span class="glyphicon glyphicon-chevron-left"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="right carousel-control" href="#myCarousel" data-slide="next">
    <span class="glyphicon glyphicon-chevron-right"></span>
    <span class="sr-only">Next</span>
  </a>-->
</div>

<div class="container">
  <div class="row">

    <div class="hidden-lg   col-md-4 col-xs-4 col-sm-4" style="width:34% !important;padding: 0;">
      <div class="category" href="/Category/_ORGANIC_MANURE">
        Shop by categorytt
      </div>
    </div>
    <!--<div class="hidden-lg col-md-4 col-xs-4 col-sm-4" style="width:33% !important;padding:2px">
      <div class="category" routerLink="/Goals/_Fertilizers">
        Shop by goal
      </div>
    </div>-->
    <div class="hidden-lg    col-md-4 col-xs-4 col-sm-4" style="width:33% !important;padding:2px">
      <div class="category" href="/FarmerProduces/_Millets">
        Farming Produces
      </div>
    </div>
  </div>
</div>

<!--jagan-->
<div class="container mobileHidden">
  <div class="row">
    <div class="col-lg-12" style="text-align:center;margin:5% 0%">
      <h6 style="color:orangered; font-family:Roboto;">SHOP BY CATEGORIES</h6>
      <h3 style="color:orangered; font-family: Roboto; font-weight:bold;font-size:18px;">POPULAR CATEGORIES</h3>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 ">
        <div class=" card_1">
          <img style="width: 100%;height:560px;cursor:pointer" src="../../../assets/img/YK.png" (click)="navigateBasedOnCategoryId('_YK_LABORATORIES_SPECIAL')" alt=""/>
          <!--<img style="width: 100%;" src="https://news.mit.edu/sites/default/files/styles/news_article__image_gallery/public/images/201706/MIT-Predicting-Reactions-1_0.jpg?itok=q5Dlcw3w" (click)="navigateBasedOnCategoryId('_YK_LABORATORIES_SPECIAL')"/>-->

          <div class="category_heading" (click)="navigateBasedOnCategoryId('_YK_LABORATORIES_SPECIAL')">YK LABORATORIES SPECIAL</div>
        </div>
      </div>
      <div class="col-lg-6">
        <!--<div class="card_3">-->
        <!--<marquee>-->
        <div class="row">

          <div class="col-lg-3" *ngFor="let items of FarmingProduces;let i=index">

            <img *ngIf="i<4" style="width:120%;height: 213px;cursor: pointer;" src={{HomeURL+items.Image}} (click)="navigateToFarmingProducts(items.SpecialityName)" alt="" />

          </div>

          <!--</div>-->
          <!--<div class="row">
            <div class="col-lg-4">
              <img style="width:100%;height: 213px;" src="https://i.pinimg.com/736x/ce/f5/13/cef513e44f9670c35ff2ff39a15a2967.jpg" />
            </div>
            <div class="col-lg-4">
              <img style="width:100%;height: 213px;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuGf8F8bXCP1MFiSiPUsxXJjK0YuyLs0ECaA&usqp=CAU" />
            </div>
            <div class="col-lg-4">
              <img style="width:100%;height: 213px;" src="https://m.media-amazon.com/images/I/61qnSAYCJCL._SX466_.jpg" />
            </div>
          </div>-->

        </div>
        <!--</marquee>-->
        <div class="category_heading">FARMING PRODUCE</div>

        <!--<div class="card_1">
          <img style="width: 100%;" src="../../../assets/img/projects/YV.png" (click)="navigateBasedOnCategoryId('_ORGANIC_FERTILIZER')" />
          <div class="category_heading" (click)="navigateBasedOnCategoryId('_ORGANIC_FERTILIZER')">ORANIC FERTILIZER</div>
        </div>-->
        <div class="row" style="    margin-top: 5%;">
          <!--<div class="col-lg-6 ">
            <div class="card_1">
              <img style="width: 100%; cursor:pointer;height: 280px;" class="" src="../../../assets/img/projects/enviro.png" (click)="navigateBasedOnCategoryId('_BOTANICAL_VIRUCIDE')" />
              <div class="category_heading" (click)="navigateBasedOnCategoryId('_BOTANICAL_VIRUCIDE')">BOTANICAL VIRUCIDE</div>
            </div>
          </div>-->
          <div class="col-lg-6 ">
            <div class="card_1">
              <img style="width: 100%; cursor: pointer; height: 280px; background-color: white;" class="" src="../../../assets/img/projects/Immuno.png" alt="" (click)="navigateBasedOnCategoryId('_ORGANIC_MANURE')" />
              <div class="category_heading" (click)="navigateBasedOnCategoryId('_ORGANIC_MANURE')">ORGANIC MANURE</div>
            </div>
          </div>
          <div class="col-lg-6 ">
            <div class="card_1">

              <img style="width: 100%;cursor:pointer;height: 280px;" class="" src="../../../assets/img/Organic Vaayu New.jpg" alt="" (click)="navigateBasedOnCategoryId('_ORGANIC_MANURE')" />
              <div class="category_heading" (click)="navigateBasedOnCategoryId('_ORGANIC_MANURE')">ORGANIC SUCKING PEST</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<div class="container">

  <h3 style="  margin: 2% 0%;font-family: Roboto;">OUR PRODUCTS</h3>
  <div class="row mb-5">

    <div class="col-md-3 col-xs-6" *ngFor="let item of Products">
      <div class="card" style="margin-bottom: 11%;" (click)="navigatingToSelectedProduct(item)">
        <div class=" new">New</div>
        <div class="wshare">
          <span style="cursor:pointer">
            <a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true" style="color:green;"></i></a>
          </span>
        </div>
        <div style="text-align:center;">
          <img src={{HomeURL+item.Image}} alt="..." class="card_ourproduct">
        </div>

        <div class="card-title over " style="text-align:center;">
          {{item.productname}}
        </div>
        <hr />

        <div class="row">
          <div class="col-lg-12 col-xs-12">
            <div class="price" style="font-family:Roboto;text-align:center;">
              {{item.Price1 | currency:'INR'}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--<div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
  <!--<div class="row mb-5">

    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
             class="card-img-top" alt="...">
        <div class="card-title">
          Max - Size 5lts
        </div>
        <hr />
        <div class="row">
          <div class="col-lg-9">
            <div class="price">
              ₹ 500.00
            </div>
          </div>
          <div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->

</div>



<div class="container" style="margin-top:-50px;">

  <h1 style="text-align:center; font-family:'poppins';" class="india"><b>PROUDLY MADE IN INDIA</b></h1>
  <br />
  <marquee>
    <div class="row">
      <div class="col-lg-3 ">
        <img src="../../../assets/img/ifoam certificates (1).png" class=" certificates" alt=""/>

      </div>
      <div class="col-lg-3">
        <img src="../../../assets/img/logo.png certificate (1).png" class=" certificates_1" alt=""/>

      </div>
      <div class="col-lg-3 ">
        <img src="../../../assets/img/nopop.jpg1.jpg" class=" certificates_2" alt=""/>


      </div>
      <div class="col-lg-3">
        <img src="../../../assets/img/biocert_logo.jpg certificates.jpg" class=" certificates_3" alt=""/>

      </div>

    </div>
  </marquee>
</div>

<br />
<!--this code use for google analyticals-->
<div  class="sticky-container1">
  <ul  class="sticky1" style=" margin-top:200px;">
    <li >

      <img  height="32" src="assets/media/call-circle.png" width="32" alt="">
     <a  href="Tel:9066-179-179">Call Us<br >Schedule Appointment</a>
    </li>


    <!--<li >

    <img  height="32" src="assets/media/youtube-circle.png" width="32" />
    <p >
      <a  href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
        Youtube
        <br >yk mart
      </a>
    </p>
  </li>-->
    <!--<li >
    <img  height="32" src="assets/media/facebook-circle.png" width="32">
    <p ><a  href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br >Yk mart</a></p>
  </li>-->
    <li >
      <img  height="32" src="assets/media/images (1).jpg" width="32" alt=""/>
    <!--<li><i class="fa fa-envelope-open-o loc"> </i><span style="cursor:pointer;"><a href="mailto:info@agrobin.in">info@agrobin.in</a>  </span> </li>-->
    <a  href="mailto:info@ykmart.in">info@ykmart.in<br >Yk mart</a>
    </li>
    <!--<li >

    <img  height="32"src="assets/img/download (1).png"width="32"/>
    <p ><a  href="https://twitter.com/home" target="_blank">Twitter<br >KISAN</a></p>
  </li>-->

    <li >
      <img  height="32" src="assets/media/whatsapp.png" width="32" alt=""/>
      <p ><a  href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br >Yk mart</a></p>
    </li>
  </ul>
</div>








<style>
  @media only screen and (max-width: 600px) {
  body{
    overflow-x:hidden;
    max-width:100%;
  }
    .sticky-container1 {
      display: none;
    }
  }

  .sticky-container1 {
    padding: 0;
    margin: 500px 0 0;
    position: fixed;
    right: -169px;
    top: 230px;
    width: 210px;
    z-index: 1100;
  }

  .sticky1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-top: 20rem;
  }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>

