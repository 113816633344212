<div class="carousel-inner" role="listbox">
  <div class="row">
    <div class="col-lg-2 col-sm-6 col-xs-666 cartpad divv" *ngFor="let item of relatedProducts;let i=index">
      <div class="custcards" (click)="navigatingToRelatedProd(item.productname,item.Productid)">
        <img src={{HomeURL+item.Image}}>
        <div class="custHead flow-name">{{item.productname}}</div>
        <!--<div>
          <i class="fa fa-star rateColor" aria-hidden="true"></i>
          <i class="fa fa-star rateColor" aria-hidden="true"></i>
          <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
        </div>-->
        <div class="custPrice flow-name">₹{{item.UPrice}}</div>
      </div>
    </div>
  </div>
</div>
