


<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-6 vol-sm-12 col-xs-12">
      <!--<img src="../../../assets/images/add_a.png" style="width:100%;" />-->
      <p-gmap #gmap [style]="{'width':'100%','height':'320px', 'margin-top': '5em'}"
              [options]="options" [overlays]="overlays"
              (onMapClick)="handleMapClick($event)"
              (onOverlayClick)="handleOverlayClick($event)"
              (onOverlayDragEnd)="handleDragEnd($event)">
      </p-gmap>

    </div>
    <div class="col-lg-6 col-md-6 vol-sm-12 col-xs-12" style="margin-top: 4.5rem;">
      <div class="a_heading">ADD ADDRESS</div>
      <div class="sub_text">Please Enter The Below Fields</div>
      <div>
        <br />
      </div>
      <div class="row" *ngIf="LoginDetails.RoleId==2">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input type="text" placeholder="Name" class="add_input" [(ngModel)]="Name" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" maxlength="10" [(ngModel)]="mobile" placeholder="Mobile No" />
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==3">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input type="text" placeholder="Name" class="add_input"  [(ngModel)]="Name1"/>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" maxlength="10"  placeholder="Mobile No"[(ngModel)]="mobile1" />
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==2">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="H.No / Flat No" [(ngModel)]="HNo" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="Area / Colony" [(ngModel)]="Area" />
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==3">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="H.No / Flat No" [(ngModel)]="HNo1" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="Area / Colony"  [(ngModel)]="Area1"/>
        </div>
      </div>
      <div class="row" *ngIf="LoginDetails.RoleId==2">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="Landmark" [(ngModel)]="Landmark" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="City" [(ngModel)]="City" />
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==3">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="Landmark"  [(ngModel)]="Landmark1"/>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="City" [(ngModel)]="City1" />
        </div>
      </div>


      <div class="row" *ngIf="LoginDetails.RoleId==2">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="State" [(ngModel)]="State" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" placeholder="Pincode" type="tel" pattern="[0-9]*" maxlength="6" [(ngModel)]="Pincode" />
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==3">
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" type="text" placeholder="State"[(ngModel)]="State1"  />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 ol-xs-12">
          <input class="add_input" placeholder="Pincode" type="tel" pattern="[0-9]*" maxlength="6"   [(ngModel)]="Pincode1" />
        </div>
      </div>

      <!--<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <input type="file" multiple (change)="detectFiles(input)" #input class="m-img-upload-btn" />
      <button class="btn btn-success btn-block" type="button">
        <i class="fa fa-upload"></i> Upload Image

      </button>

    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="images-container">
        <div class="m-image-wrapper">
          <img [attr.src]="imagefile" *ngIf="image" style="cursor:pointer;">
        </div>
      </div>
    </div>
  </div>-->
      <div class="row" *ngIf="LoginDetails.RoleId==2">
        <div class="col-lg-12">
          <button class="btn btn-success" [disabled]="insertingData" (click)="InsertAdderss(Name,mobile,HNo,Area,Landmark,City,State,Pincode,Section)"> Confirm And Proceed</button>
        </div>
      </div>

      <div class="row" *ngIf="LoginDetails.RoleId==3">
        <div class="col-lg-12">
          <button class="btn btn-success" [disabled]="insertingData" (click)="InsertAdderss1(Name1,mobile1,HNo1,Area1,Landmark1,City1,State1,Pincode1,Section1)"> Confirm</button>
        </div>
      </div>

    </div>
  </div>
</div>


