import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { CartService } from 'src/app/service/cart.service'
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { WishlistService } from 'src/app/service/wishlist.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
declare var google: any;
@Component({
  selector: 'app-product-enquiry',
  templateUrl: './product-enquiry.component.html',
  styleUrls: ['./product-enquiry.component.scss'],
  providers: [MessageService],
})
export class ProductEnquiryComponent implements OnInit {
  Pincode: any;
  Name: any;
  lat: any;
  lng: any;
  addressDetails: any;
  addressId: any;
  Cartimesforloginusers: any = [];
  ProductInfo: any;
  recomendedCrops: any;
  benefits: any;
  ingrediants: any;
  dosage: any;
  logedinUser: any;
  LoginDetails: any;
  seletedItemforWishlist: any = [];
  wishListItems: any = [];
  whatsAppProductURL: string;
  Quantity: any = 1;
  itemSize: any;
  ItemId: any;
  currntPacksize: any;
  currentPrice: any;
  ProductName: any;
  HomeURL: any;
  productDetails: any;
  cartItemList: any = []
  selectedItemforCart: any = [];
  Mobile: any;
  HNo: any;
  Area: any;
  Landmark: any;
  City: any;
  State: any;
  Comments: any;
  videoUrl: any;
  constructor(public generalService: GeneralService, public http: HttpClient,
    public activeroute: ActivatedRoute, public cartService: CartService, public router: Router,
    public toast: MessageService, public wishlistService: WishlistService, private sanitizer: DomSanitizer) {    
    this.ProductName = this.activeroute.snapshot.paramMap.get('jame').replace(/\_/g, " ").trim();
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));    
    this.generalService.getProducts().subscribe(res => {      
      if (res.length == 0) {//if category id is null
        this.GetProductDetailsbasedonProductName()
      }
      else {
        this.ProductName = res[0].productname
        this.currentPrice = res[0].Price1
        this.currntPacksize = res[0].Pack1
        this.productDetails = res;
        this.videoUrl = res[0].VideoLink;
        this.ProductInfo = decodeURIComponent(res[0].ProductInfo)
        this.recomendedCrops = decodeURIComponent(this.productDetails[0].RecommendedCrops)
        this.benefits = decodeURIComponent(this.productDetails[0].Benefits)
        this.dosage = decodeURIComponent(this.productDetails[0].Dosage)
      }
    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
    navigator.geolocation.getCurrentPosition(position => {      
      this.lat = (position.coords.latitude)*1
      this.lng = (position.coords.longitude) * 1
      this.getLocation(this.lat, this.lng)
    });
  }

  ngOnInit(): void {
    this.cartService.getProducts().subscribe(res => {
      this.cartItemList = res /*Getting current cart item lists*/
    })
    this.wishlistService.getProducts().subscribe(res => {
      this.wishListItems = res /*this is a service call for getting wishlist items*/
    })
    if (this.LoginDetails) {
      this.getCustomerAddress()
    }
    this.clearFields()
    console.log(this.videoUrl);

  }
  /*
   Getting product details based on their product name which was getting from active routs in url
   */
  GetProductDetailsbasedonProductName() {
    var UploadFile = new FormData()
    UploadFile.append("ProductName", this.ProductName)
    var url = "/api/Products/GetAllCategoryWiseProductsBasedOnProductName"
    this.generalService.PostData(url, UploadFile).then(data => {      
      this.productDetails = data
      this.ProductInfo = null;
      this.recomendedCrops = null;
      this.benefits = null;
      this.ingrediants = null;
      this.dosage = null
      this.videoUrl = this.productDetails[0].VideoLink
      this.currentPrice = this.productDetails[0].Price1
      this.currntPacksize = this.productDetails[0].Pack1
      this.ProductInfo = decodeURIComponent(this.productDetails[0].ProductInfo)
      this.recomendedCrops = decodeURIComponent(this.productDetails[0].RecommendedCrops)
      this.benefits = decodeURIComponent(this.productDetails[0].Benefits)
      this.dosage = decodeURIComponent(this.productDetails[0].Dosage)
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*
  below method fires when the user clicks on size pack along with price
   */
  selectPrice(size, price) {
    this.currntPacksize = size
    this.currentPrice = price
  }
  
  /*
   *Below method is for sharing url on whatsapp 
   */
  sendWhatsapp(item) {
    var prodName = ""
    var str = item.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.whatsAppProductURL = "https://ykmart.breakingindiaapp.com/%23/Product/" + prodName
    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
  }

  
  /** 
   * below method is for getting loged in user name
   */
  logedin() {
    this.logedinUser = localStorage.getItem('token');
    return this.logedinUser;
  }
  /*
   *Get Customer Delivery Address 
   */
  getCustomerAddress() {
    let Address = [{
      UserId: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(Address));
    var url = "/api/Products/Get_Customer_Address_Based_On_Id"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.addressDetails = data;
      }
    });
  }

  /*
  get selected addresss of customers
   */
  getSelectedAddress(item) {
   // this.addressId=item
  }

  /**
   * Below method fired when the user raises an enquiry
   */
  PlaceEnquiry() {
    var arr = [];
    if (!this.addressId) {      
      this.toast.add({ severity: 'warn', summary: 'Warning', life: 2000, detail: 'Please select address for enquiry' });
       }
    else {
      for (var i = 0; i < this.productDetails.length; i++) {
        arr.push({
          Customerid: this.LoginDetails.Id, AddressId: this.addressId,
          ProductId: this.productDetails[i].Productid, TotalProducts: this.Quantity,
          Size: this.currntPacksize, TotalAmount: this.productDetails[i].Price,
          AdminAmount: this.productDetails[i].Price, CustomerName: this.LoginDetails.FarmerName,
          Mobile: this.LoginDetails.MobileNo, Latitude: this.lat, Longitude: this.lng,
          Comments: this.Comments
        })
      }
      let formData: FormData = new FormData();
      formData.append('Param', JSON.stringify(arr));
      var url = "/api/Products/Insert_Customer_Enquires"
      // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
      this.generalService.PostData(url, formData).then((data: any) => {
        if (data != "") {
          this.addressId=""
          this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry placed successfully Ykmart team get you soon' });
          this.ngOnInit()
        }
      });
    }
  }

  /* ProductName for navigation*/
  navigatingtoFarmerProduces(data) {    
    var catName = "";
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      catName += "_" + str[i];
    }
    this.router.navigate(['/FarmerProduces/' + catName]);
  }

  /**
  * Below method fired when the user raises an enquiry With out login
  */
  PlaceEnquiryNormalUser() {
    if (!this.LoginDetails) {
      if (!this.Name || !this.Mobile) {
        this.generalService.ShowAlert('WARNING', 'Please fill Name and Mobile No.', 'warning');
       // this.toast.add({ severity: 'warning', summary: 'Warning', life: 2000, detail: 'Please fill Name and Mobile No.' });
        return false;
      }
    }
      var arr = [];
      for (var i = 0; i < this.productDetails.length; i++) {
        arr.push({
          AddressId: this.addressId,
          ProductId: this.productDetails[i].Productid, TotalProducts: this.Quantity,
          Size: this.currntPacksize, TotalAmount: this.productDetails[i].Price,
          AdminAmount: this.productDetails[i].Price,
          City: this.City, HNo: this.HNo, Area: this.Area, LandMark: this.Landmark,
          State: this.State, Mobile: this.Mobile, Latitude: this.lat, Longitude: this.lng,
          Pincode: this.Pincode, CustomerName: this.Name, Comments: this.Comments
        })
      }
      let formData: FormData = new FormData();
      formData.append('Param', JSON.stringify(arr));
      var url = "/api/Products/Insert_Customer_Enquires"
      // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
      this.generalService.PostData(url, formData).then((data: any) => {
        if (data != "") {
          this.addressId = ""
          this.toast.add({ severity: 'success', summary: 'Success', life: 2000, detail: 'Your enquiry placed successfully Ykmart team get you soon' });
          this.ngOnInit()
        }
      });
  }
  /**Get Formated Addreess using this method*
   *@param lat
   * @param long
   */
  getLocation(lat, long) {
    var geocoder = new google.maps.Geocoder();
    let latlng = new google.maps.LatLng(lat, long);
    let request = { latLng: latlng };
    geocoder.geocode(request, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let result = results[0];
       // this.ADDRESS1 = result.formatted_address;
        this.Pincode = result.address_components.find(x => x.types == 'postal_code').long_name;
        this.State = result.address_components.find(x => x.types[0] == 'administrative_area_level_1').long_name;
        this.City = result.address_components.find(x => x.types[0] == 'locality').long_name;
      }
    })
  }

  /*Clear all enquiry fields*/
  clearFields() {
    this.HNo = ""
    this.Area = ""
    this.Landmark = ""
    this.Pincode = ""
    this.Name = ""
    this.Mobile = ""
    this.Comments=""
  }
}
