import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { CartService } from 'src/app/service/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent implements OnInit {
  relatedProducts: any;
  HomeURL: any;
  LoginDetails: any;
  /*Below method is for getting product id as input this component from product details page*/
  @Input() productId: any;
  /*Below method is for sending product name as output to product details page*/
  @Output() someEvent = new EventEmitter<string>();

  constructor(public generalService: GeneralService, public router: Router,
    public http: HttpClient, public cart: CartService) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
    this.cart.getrelatedProdNumber().subscribe((data: any) => {
      if (data) {
        this.GetrelatedProducts(data)
      }
    })
  }

  ngOnInit(): void {
    this.GetrelatedProducts(this.productId)
  }


  /*
Below method is for Get Related products
*/
  GetrelatedProducts(ProductId) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("xml", ProductId);
    var url = "api/Products/Get_reletedproduct_basedon_productid";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.relatedProducts = data;
      }
    })
  }

  /* related product for navigation*/
  navigatingToRelatedProd(item: any,id) {
    this.someEvent.next(item);
    /*below method is calling for changing related items regarding that product*/
    this.GetrelatedProducts(id)
  }
}
