import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-farmer-produces',
  templateUrl: './farmer-produces.component.html',
  styleUrls: ['./farmer-produces.component.scss']
})
export class FarmerProducesComponent implements OnInit {
  SpecialityName: any;
  HomeURL: any;
  FarmerProducts: any;
  selectedFarmerProducts = [];
  Specialities: any;
  SpecialitieData: any;
  SpecialityId: any;
  public router: Router;
  constructor(public generalService: GeneralService, public http: HttpClient, router: Router, public activeroute: ActivatedRoute) {
    /*below categry name is invoked from the above url from the browser in that '-' is replaced with " " and trim for whaite spaces*/
    this.SpecialityName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
    this.router = router
    /*By using below service we can get the category id from page header*/
    this.generalService.getCategoryid().subscribe(res => {
      if (res.length == 0) {//if category id is null
        this.GetFarmerProducesMasterfilterspecialityidWhilecopyURL()
      }
      else {//it will execute when we select header Specialities
        this.SpecialityId = res[0].Id
        this.SpecialityName = res[0].SpecialityName
        this.getSpecialProductsBasedonSpecialityId()
      }

    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }
  ngOnInit(): void {
    this.GetSpecialforsideBar()
  }

  /*
* Below method is used to get the Category product details from url
    GoalName while category id is null this method i invoked
*/
  GetFarmerProducesMasterfilterspecialityidWhilecopyURL() {
    var url = "/api/Products/GetFarmingProducesMaster"
    this.generalService.GetData(url).then(data => {
      this.SpecialitieData = data
      var catData = this.SpecialitieData.filter(A => A.SpecialityName === this.SpecialityName)
      this.SpecialityId = catData[0].Id
      this.getSpecialProductsBasedonSpecialityId()
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*
* Below method is used to get the Category list for left side menu 
*/
  GetSpecialforsideBar() {
    var url = "/api/Products/GetFarmingProducesMaster"
    this.generalService.GetData(url).then(data => {
      this.Specialities = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*
 * Below method is used to get the Specialities based on category Id
 */
  getSpecialProductsBasedonSpecialityId() {    
    let arr = [];
    arr.push({ UserId: 0, TokenID: null, Id: this.SpecialityId })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetAll_FarmingProductsBasedOnSpecialityId"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.FarmerProducts = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /**
   * Below method is call when customer clicks on side bar Specialities
   */
  onclickmethodforLeftsideSpecialities(SpecialityId, name) {
    debugger
    this.SpecialityId = SpecialityId
    this.SpecialityName = name
    this.getSpecialProductsBasedonSpecialityId()
    // this.GetCategoryforsideBar()
  }

  /**
  * Navigating to selected Products page  regarding category
  */
  navigatingToProduct(data) {
    debugger
    var prodName = ""; var prodarr = []
    prodarr = this.FarmerProducts.filter(A => A.productname == data.productname)//Filtering Specialities data based on selected chemical name for getting category id    
    var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.generalService.setProducts(prodarr);
    this.router.navigate(['/EnquiryProducts/' + prodName]);
  }

  /**
  * navigate To best Seller
  */
  navigateTobestSeller() {
    this.router.navigate(['/Product/_VAAYU'])
  }
}
