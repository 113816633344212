
<!--<div class="container">
  <br />
  <nav class="breadcrumb_padd" style="background-color:#fff">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active"> Blog</li>
    </ol>
  </nav>

  <div class="  card" style="margin-bottom:1%" *ngFor="let item of blogDetails">
    <div class="row">
      <div class="col-lg-6">  <h2 style=" color: #9d0db7">{{item.BlogName}}</h2></div>
      <div class="col-lg-6" style="text-align:end"> <i><b>{{item.CreatedDate | date:'dd-MMM-yyyy'}}</b></i></div>

      <div class="col-lg-2">
        <img src="{{HomeUrl+item.Image}}" class="card_image" style="height: 137px;">
      </div>

      <div class="HideDescription" style="color:#000;text-align:justify;font-size: 15px; padding-left: 7.5rem;letter-spacing: 0.5px;line-height: 6.7rem;" [innerHtml]="item.Description"></div>

    </div>
  </div>

</div>-->
<!------------------------------->
<div class="container">
  <br />
  <nav class="breadcrumb_padd" style="background-color:#fff">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active"> Blog</li>
    </ol>
  </nav>


  <div class="  card" style="margin-bottom:1%" *ngFor="let item of blogDetails">
    <div class="row">
      <!-- new title -->
      <h2 style="color:#3498db; margin-left: 29px;font-weight: bold; font-size: 18px;">{{item.BlogName}}</h2>
      <div class="col-lg-12" style="text-align:start;margin-left: 16px;
    font-style: initial!important;
    color: #b93956;"><i><b style=" font-style: initial!important">{{item.CreatedDate | date:'dd-MMM-yyyy'}}</b></i></div>
    </div>
    <!--this code use for  onnce insert to image  on admin it s will show in fronted-->
    <div class="row">
      <div class="col-lg-6">
        <img src="{{HomeUrl+item.Image}}" class="card_image" style=" height: 215px;margin-top: 8px;margin-left: 23px;border-radius: 8%;width: 50%;">
      </div>


      <div class="col-lg-6">

        <div class="HideDescription description" [innerHtml]="item.Description"></div>
      </div>

      <br />
    </div>
    <br />
  </div>
</div>
