import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    CategoryName: any;
    HomeURL: any;
    CategoryProducts: any;
  selectedCategory=[];
  Categories: any;
  CategoryData: any;
  categoryId: any;
  displayCards: boolean;
 CategoryId: any;
  public router: Router;
  constructor(public generalService: GeneralService, public http: HttpClient,
    router: Router, public activeroute: ActivatedRoute) {
      /*below categry name is invoked from the above url from the browser in that '-' is replaced with " " and trim for whaite spaces*/
      this.CategoryName = this.activeroute.snapshot.paramMap.get('ProductName').replace(/\_/g, " ").trim();     
      this.router = router
      /*By using below service we can get the category id from page header*/
      this.generalService.getCategoryid().subscribe(res => {        
          if (res.length == 0) {//if category id is null
              this.GetCategoryforfiltercatidWhilecopyURL()              
          }
          else {//it will execute when we select header categories
              this.categoryId = res[0].CategoryId
              this.CategoryName=res[0].CategoryName
              this.getCategoryBasedonCategoryID()
          }
        
    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
        this.HomeURL = data.Webservice;
    });
  }
  ngOnInit(): void
  {
      localStorage.setItem('Goal', '');///sandhya
      this.GetCategoryforsideBar()
  }


  test() {
    debugger
    this.displayCards = true;
  }
 
  /*
* Below method is used to get the Category product details from url
    categoryname while category id is null this method i invoked
*/
  GetCategoryforfiltercatidWhilecopyURL() {
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetProductCategories"
    this.generalService.PostData(url, UploadFile).then(data => {        
        this.CategoryData = data
        var catData = this.CategoryData.filter(A => A.CategoryName === this.CategoryName)
        this.categoryId = catData[0].CategoryId
        this.getCategoryBasedonCategoryID()
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

    /*
* Below method is used to get the Category list for left side menu 
*/
  GetCategoryforsideBar() {
      let arr = [];
      arr.push({ UserId: 0, TokenID: null })
      var UploadFile = new FormData()
      UploadFile.append("Param", JSON.stringify(arr))
      var url = "/api/Products/GetProductCategories"
      this.generalService.PostData(url, UploadFile).then(data => {          
          this.Categories = data          
      }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
  }
 /*
* Below method is used to get the Categories based on category Id
*/
  getCategoryBasedonCategoryID() {
      debugger
      let arr = [];
      arr.push({ UserId: 0, TokenID: null, CategoryId: this.categoryId })
      var UploadFile = new FormData()
      UploadFile.append("Param", JSON.stringify(arr))
      var url = "/api/Products/GetAll_CategorywiseProductsBasedOnCategoryID"
      this.generalService.PostData(url, UploadFile).then(data => {          
        this.CategoryProducts = data
 
      }, err => {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
  }
   /**
    * Below method is call when customer clicks on side bar categories
    */
  onclickmethodforLeftsideCategories(categoryId,name) {
      this.categoryId = categoryId
      this.CategoryName=name
      this.getCategoryBasedonCategoryID()
     // this.GetCategoryforsideBar()
  }

   /**
   * Navigating to selected Products page  regarding category
   */
  navigatingToProduct(data) {
    debugger
    var prodName = "";var prodarr=[]
    prodarr = this.CategoryProducts.filter(A => A.productname == data.productname)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.generalService.setProducts(prodarr);
    this.router.navigate(['/Product/' + prodName]);
  }

  /*navigate To best Seller*/
  navigateTobestSeller() {

    this.router.navigate(['/Product/_VAAYU'])
      .then(() => {
        window.location.reload();
      });

    //this.router.navigate(['/Product/_VAAYU'])
    //location.reload();
  }

}




  





