import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { CartService } from 'src/app/service/cart.service'
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { WishlistService } from 'src/app/service/wishlist.service';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare var Razorpay: any;
declare var $: any;

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss'],
  providers: [MessageService],
})
export class ProductInformationComponent implements OnInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  gstAmount: any;
  ProductId: any;
  PopularProducts: any;
  arr: any = [];
  addNew: boolean = false;
  catName: string;
  WishListitemmsforloginusers: any = [];
  productFrameImage: any;
  Products: any;
  ProductImages: any;
  addressId: any;
  Cartimesforloginusers: any = [];
  ProductInfo: any;
  recomendedCrops: any;
  benefits: any;
  loginStatus: boolean;
  ingrediants: any;
  dosage: any;
  logedinUser: any;
  LoginDetails: any;
  subTotal1: any;
  seletedItemforWishlist: any = [];
  wishListItems: any = [];
  whatsAppProductURL: string;
  Quantity: any = 1;
  itemSize: any;
  ItemId: any;
  currntPacksize: any;
  currentPrice: any;
  ProductName: any;
  HomeURL: any;
  productDetails: any = [];
  cartItemList: any = []
  selectedItemforCart: any = []
  Starscount: any;
  Comments: any;
  CreatedDate: any;
  ReviewsData: any;
  imageUrl: any;
  video: any = false;
  prodName: any;
  addressDetails: any;
  plus: boolean = true;
  n: any;
  PaymentType: any;
  cartItems: any;
  addressDetails1: any;
  offerPrice: any;
  deliveryCharge: any;
  TotalPrice: any;
  val: number;
  subTotal: any = 0;
  admin: any;
  TaxnId:any;
  //ItemId: any;
  constructor(public generalService: GeneralService, public http: HttpClient,
    public activeroute: ActivatedRoute, public cartService: CartService, public router: Router,
    public toast: MessageService, public wishlistService: WishlistService, private sanitizer: DomSanitizer) {
    this.ProductName = this.activeroute.snapshot.paramMap.get('ProductDeatails').replace(/\_/g, " ").trim();
    //this.ProductId = this.activeroute.snapshot.paramMap.get('ProductDeatails').replace(/\_/g, " ").trim();


    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.cartService.getProducts().subscribe(res => {
      this.cartItemList = res /*Getting current cart item lists*/
    })
    this.wishlistService.getProducts().subscribe(res => {
      this.wishListItems = res /*this is a service call for getting wishlist items*/
    })
    this.generalService.getProducts().subscribe(res => {
      if (res.length == 0) {//if category id is null
        this.GetProductDetailsbasedonProductName()
      }
      else {
        //this.cartService.setRelatedproductId(res[0].Productid)
        this.ProductName = res[0].productname;
        this.GetProductDetailsbasedonProductName()
        //this.currentPrice = res[0].Price1
        //this.currntPacksize=res[0].Pack1
        //this.productDetails = res;
        //this.recomendedCrops = decodeURIComponent(this.productDetails[0].RecommendedCrops)
        //this.benefits = decodeURIComponent(this.productDetails[0].Benefits)
        //this.dosage = decodeURIComponent(this.productDetails[0].Dosage)
        // this.ingrediants = decodeURIComponent(this.productDetails[0].Ingredients)
        //this.GetProductimages(res[0].Productid)
      }
    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }


  ngOnInit(): void {
    debugger
    if (this.LoginDetails) {
      this.loginStatus = true;
      this.getCustomerAddress();
      this.reviewandrating();
      //this.GetAllOffers();
    }
    else {
      this.loginStatus = false;
    }

  }

  //ngOnInit(): void {


  //  this.GetProductsforCustomerwhoalsoviwed()
  //  if (this.LoginDetails) {

  //    this.loginStatus = true;
  //    this.getCustomerAddress();
  //  }
  //  else {
  //    this.loginStatus = false;

  //  }
  //}

  /*
   Getting product details based on their product name which was getting from active routs in url
   */
  GetProductDetailsbasedonProductName() {
    debugger
    var UploadFile = new FormData()
    UploadFile.append("ProductName", this.ProductName)
    var url = "/api/Products/GetAllCategoryWiseProductsBasedOnProductName"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.productDetails = data;
      this.productDetails[0].GoalName = localStorage.getItem('Goal') ? localStorage.getItem('Goal') : "";
      this.ProductInfo = null;
      this.recomendedCrops = null;
      this.benefits = null;
      this.ingrediants = null;
      this.dosage = null
      this.ProductId = this.productDetails[0].Productid
      this.cartService.setRelatedproductId(this.ProductId)
      this.currentPrice = this.productDetails[0].Price1
      this.currntPacksize = this.productDetails[0].Pack1
      this.gstAmount = this.productDetails[0].GstAmount1
      this.gstAmount = this.productDetails[0].GstAmount1
      //var ecomendedCrops = encodeURIComponent("<div style=\"background:#eeeeee; border:1px solid #cccccc; padding:5px 10px\">* Fost-RootexAct as a Soil Spreader and penetrate the irrigated water towards root zone.</div>\n\n<div style=\"background:#eeeeee; border:1px solid #cccccc; padding:5px 10px\">* Fost-Rootexincrease the water infiltration and water-holding capacity of the soil.</div>\n\n<div style=\"background:#eeeeee; border:1px solid #cccccc; padding:5px 10px\">* Fost&ndash;Rootexgreatly increase the plant root growth and metabolism, * Fost&ndash;Rootex enhance seed germination, and help plants deal with environmental stresses.</div>\n")
      //var emendedCrops = encodeURIComponent("ghghf")

      this.recomendedCrops = decodeURIComponent(this.productDetails[0].RecommendedCrops)
      this.benefits = decodeURIComponent(this.productDetails[0].Benefits)
      this.dosage = decodeURIComponent(this.productDetails[0].Dosage)
      this.ingrediants = decodeURIComponent(this.productDetails[0].Ingredients)
      this.GetProductimages(this.productDetails[0].Productid)
      this.imageUrl = this.productDetails[0].VideoLink;
      this.ProductInfo = decodeURIComponent(this.productDetails[0].ProductInfo)
      console.log(this.productDetails);
      this.reviewandrating()
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*
  below method fires when the user clicks on size pack along with price
   */
  selectPrice(size, price, gstAmount) {
    debugger
    this.currntPacksize = size
    this.currentPrice = price,
      this.gstAmount = gstAmount
  }

  getCustomerAddress() {
    debugger
    let Address = [{
      UserId: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(Address));
    var url = "/api/Products/Get_Customer_Address_Based_On_Id"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        debugger
        this.addressDetails = data;
        this.plus = false
      }
    });
  }

  PlaceOrder() {
    debugger

    this.arr = [];
    this.n = ""
    var d = new Date();
    this.n = d.getTime();

    //this.offerPrice = !this.offerPrice ? 0 : !this.offerPrice;
    {
      if (this.LoginDetails) {
        if (this.addressDetails) {
          if (!this.addressDetails[0].Id) {
            this.generalService.ShowAlert('Warning', 'Please select or add address for Delivery.', 'warning');
          }
          else {
            $('#myModal').modal('show');
          }
        } else {
          this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
          this.router.navigate(['/Address']);
        }
      }

      this.arr.push({
        CustomerID: this.addressDetails[0].UserId, AddressId: this.addressDetails[0].Id,
        PaymentType: "Online", ProductId: this.productDetails[0].Productid,
        Quantity: this.Quantity, Size: this.currntPacksize,
        Price: this.currentPrice, TransactionID: this.n, GstPrice: this.gstAmount
        , DeliveryCharge: 0, TotalPrice: this.currentPrice * this.Quantity, OfferPrice: 0, CreatedBy: this.LoginDetails.Id, Device: 'Web'
      })
    }
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(this.arr));
    var url = "/api/Products/Insert_Customer_Orders"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {

        if (this.PaymentType == "CashOnDelivery") {
          this.cancel()
         // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });
          // this.cartService.removeAllcart();
          this.val = 1
         // this.sendsmsToAdmin();
        //  this.sendsms1();

          //this.sendMailToAdmin(this.val)
          this.router.navigate['/Home'];
        }
        else {

          this.RedirectToPaymentGateway(this.arr, this.n)
          //this.procedtoBuy();
        }
      }
    });
  }

  cancel() {
    $('#myModal').modal('hide');
  }

  RedirectToPaymentGateway(arr, n) {
    debugger
    // alert('hello')
    var UploadFile = new FormData();
    this.subTotal1 = this.currentPrice * this.Quantity;
    // this.subTotal = this.subTotal + this.deliveryCharge
    UploadFile.append('Name', this.LoginDetails.FarmerName);
    UploadFile.append('Mobile', this.LoginDetails.MobileNo);
    UploadFile.append('Email', this.LoginDetails.Email);
    UploadFile.append('Amount', this.subTotal1);
    UploadFile.append('Productinfo', "Product");
    UploadFile.append('TransactionId', n);
    UploadFile.append('BookingDetails', JSON.stringify(arr));
    //alert('Start')
    var url = "/api/Products/PaymentGateWayWEB";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Your order placed successfully' });

      window.location.href = data.toString();
      this.cancel()
      // alert ('hello')
       this.cartService.removeAllcart()
      this.val = 1
     // this.sendsmsToAdmin();
      //this.sendsms1();
     // this.sendsms2();
      // this.sendMailToAdmin(this.val)
      //this.products = []
      // this.cartProducts = []
      //localStorage.removeItem('CartItems');
      //localStorage.setItem("totalcartitems", JSON.stringify(this.cartProducts.length));
      //this.MailForCustomerPlaceOrder(CartProducts)
      this.router.navigate['/Home'];
    
    }, err => {
      this.router.navigate['/Cart'];
    });
  }


  getSelectedAddress(addressId: any) {
    //this.addressId = addressId;
    //console.log(addressId)
  }

  procedtoBuy() {
    debugger
     if (this.LoginDetails) {
       if (this.addressDetails) {
         if (this.addressDetails[0].Id) {
           $('#myModal').modal('show');

         }

         else {
           this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
           this.router.navigate(['/Address']);
         }
       }
      }
   
  }




  rating(el: HTMLElement) {
    this.addNew = true;
    el.scrollIntoView();
  }

  //getting review and rating code
  reviewandrating() {
    debugger
    this.ProductId = this.productDetails[0].Productid;
    var UploadFile = new FormData();
    UploadFile.append("Param", this.ProductId);
    var url = "/api/Products/Get_ReviewsandRatings";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.ReviewsData = data;
        this.Starscount = this.ReviewsData[0].Starscount;
        //this.Comments = this.ReviewsData[0].Comments;
        //this.CreatedDate = this.ReviewsData[0].CreatedDate;

      }
    })
  }





  /*Below method fires when the user clicks on add to cart page
  */
  Addtocart(item: any) {
    debugger
    this.ItemId = 0;
    this.itemSize = "";
    this.selectedItemforCart.push({
      Productid: item.Productid, productname: item.productname, CategoryName: item.CategoryName ? item.CategoryName : "",
      Image: item.Image, Price: this.currentPrice, Pack: this.currntPacksize, Quantity: this.Quantity,
      UserId: "", GstPrice: this.gstAmount
    })

    if (this.cartItemList.length != 0 && this.cartItemList != "" && this.cartItemList != undefined && this.cartItemList != null) {
      for (var i = 0; i < this.cartItemList.length; i++) {
        if (this.cartItemList[i].Productid == this.selectedItemforCart[0].Productid) {//checking if the product is existing in the cartlist with same id
          if (this.cartItemList[i].Pack == this.selectedItemforCart[0].Pack) {//checking if the product is existing in the cartlist with same pack
            this.ItemId = this.cartItemList[i].Productid;
            this.itemSize = this.cartItemList[i].Pack;
            break;///sandhya
          }
        }
      }
      if (this.itemSize == this.selectedItemforCart[0].Pack && this.ItemId == this.selectedItemforCart[0].Productid) { //if product with same size then we show toast msg like below
        this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in cart' });
      }
      else {
        if (this.logedin()) {
          this.insertCartItems(this.selectedItemforCart)
        }
        else {
          this.cartService.addtoCart(this.selectedItemforCart[0]);
        }
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
      }
    }
    else {
      if (this.logedin()) {
        this.insertCartItems(this.selectedItemforCart)
      }
      else {
        this.cartService.addtoCart(this.selectedItemforCart[0]);
      }
      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
    }
    this.selectedItemforCart = []
  }

  /*
   *Below method is for sharing url on whatsapp 
   */
  sendWhatsapp(item) {
    var prodName = ""
    var str = item.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.whatsAppProductURL = "https://ykmart.in/%23/Product/" + prodName
    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
  }

  getPaymentType(pType) {
    this.PaymentType = pType
  }


  /*
    Below method is called when user click on add to wishlist
  */
  addToWishlist(item: any) {
    if (!this.logedin()) {
      this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Need to login to site for adding this product into wishlist' });
      this.router.navigate(['/CustomerLogin']);
    }
    else {
      this.seletedItemforWishlist = [];//sandhya
      this.seletedItemforWishlist.push({
        Productid: item.Productid, productname: item.productname, CategoryName: item.CategoryName,
        Image: item.Image, Price: this.currentPrice, Pack: this.currntPacksize, Quantity: this.Quantity,
        UserId: "", GstPrice: this.gstAmount
      })
      this.ItemId = 0;
      this.itemSize = "";

      if (this.wishListItems.length != 0 && this.wishListItems != "" && this.wishListItems != undefined && this.wishListItems != null) {
        this.wishListItems.map((a: any) => {
          if (a.Productid == this.seletedItemforWishlist[0].Productid) {//checking if the product is existing in the cartlist with same id
            if (a.Pack == this.seletedItemforWishlist[0].Pack) {//checking if the product is existing in the cartlist with same pack
              this.ItemId = a.Productid
              this.itemSize = a.Pack
            }
          }
        })

        if (this.itemSize == this.seletedItemforWishlist[0].Pack) { //if product with same size then we show toast msg like below
          this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in wishlist' });
        }
        else {
          //this.wishlistService.setProducts(this.seletedItemforWishlist[0])
          this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to wishlist successfully' });
          if (this.logedin()) {
            this.insertWishListItems(this.seletedItemforWishlist)
          }
        }
      }
      else {
        //this.wishlistService.setProducts(this.seletedItemforWishlist[0])
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to wishlist successfully' });
        if (this.logedin()) {
          this.insertWishListItems(this.seletedItemforWishlist)
        }
      }
      this.seletedItemforWishlist = []
    }
  }

  /**
   * below method is for getting loged in user name
   */
  logedin() {
    this.logedinUser = localStorage.getItem('token');
    return this.logedinUser;
  }

  /*
 Below method is for Inserting customer cart items
 */
  insertCartItems(cartItems) {
    debugger
    this.Cartimesforloginusers = []
    this.Cartimesforloginusers.push(cartItems)
    cartItems[0].UserId = String(this.LoginDetails.Id)
    var url = "api/Products/Insert_Cart_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(cartItems));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        ///sandhya
        var object = data.filter(x => x.Productid == cartItems[0].Productid)[0];
        this.cartService.addtoCart(object);
        ///sandhya
        //this.cartService.addtoCart(this.selectedItemforCart[0]);
        // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
      }
    })
  }

  /*
 Below method is for Inserting customer WishList items
 */
  insertWishListItems(wishlist) {
    debugger
    this.WishListitemmsforloginusers = []
    this.WishListitemmsforloginusers.push(wishlist)
    wishlist[0].UserId = String(this.LoginDetails.Id)
    var url = "api/Products/Insert_WishList_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(wishlist));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        var object = data.filter(x => x.Productid == wishlist[0].Productid)[0];
        if (object.Pid) {
          object.WishlistId = object.Pid;
          this.wishlistService.setProducts(object);
        } else {
          this.getWishListItemsListbasedonUserId(wishlist);
        }
      }
    })
  }
  getWishListItemsListbasedonUserId(wishlist) {
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    var url = "api/Products/GetAllWishListItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", this.LoginDetails.Id);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.wishlistService.removeAllWishlistItems();
      if (data != null) {
        var object = data.filter(x => x.Productid == wishlist[0].Productid)[0];
        this.wishlistService.setProducts(object);
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });
  }

  /*
 Below method is for Get productImages
 */
  GetProductimages(ProductId) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("Param", ProductId);
    var url = "api/Products/GetAll_ProductImagesBasedonProductId";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.ProductImages = data;
        this.productFrameImage = data[0].UImage
      }
    })
  }

  /*
* Below method is used to get the Products with images from Server with reference of database path
*/
  GetProductsforCustomerwhoalsoviwed() {
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetAllProductDetails"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Products = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*changeImages*/
  changeImage(imgPath) {
    if (imgPath) {
      this.productFrameImage = imgPath;
      this.video = false;
    } else {
      this.video = true;
    }
  }


  /* categoryName for navigation*/
  navigatingtoCategory(data) {
    this.catName = "";
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      this.catName += "_" + str[i];
    }
    this.router.navigate(['/Category/' + data]);
  }


  /* popular product for navigation*/
  navigatingToPopularProd(item) {
    var catName = "";
    var str = item.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      catName += "_" + str[i];
    }
    this.ProductName = item
    this.GetProductDetailsbasedonProductName();
    this.router.navigate(['/Product/' + catName]);
  }

    
  RazorPayPayment() {
        debugger
        var d = new Date(),
            n = d.getTime();
        this.TaxnId = n;
        var options: any = {
            description: 'YKMart ',
            image: 'https://ykmart.in/assets/img/IMG-20220210-WA0049a.jpg',
            currency: 'INR',
            //key: 'rzp_test_PKZRt4NGyfabME',// Test key
           // key: 'rzp_live_LnpQ4EQWubWJOo',// Ggari key
            key: 'rzp_live_VcpmG5M8UJUp01',// Ykmart
            payment_id: n,
            payment_capture: '1',
            amount: (this.currentPrice * this.Quantity)*100,
            //callback_url:"https://ykmart.in//",
            name: "YKMart ",
            prefill: {
                // email: this.LoginDetails.Email,
                contact: +91 + "" + this.LoginDetails.MobileNo,
                name: this.LoginDetails.FarmerName
            },
            theme: {
                color: '#008cb3'
            },
         handler: this.razorPayHandler.bind(this) // Define handler function
        }
        debugger
        var rzp1 = new Razorpay(options);
        //options['handler'] = this.razorPaySuccessHandler.bind(this);
        rzp1.open();
        //rzp1.on();
    }

// Define handler function to capture payment response
 razorPayHandler(response: any){
   console.log(response);
   debugger
  if(response.razorpay_payment_id){
    localStorage.setItem("PaymentId",response.razorpay_payment_id);
    console.log('Payment Succesfull:',response.razorpay_payment_id);
   this.InsertUSer();
   }else{
    console.log('payment failed or cancelled')
  }

 }

InsertUSer(){
 debugger
    this.arr = [];
    this.n = ""
    var d = new Date();
    this.n = d.getTime();
      
      this.arr.push({
        CustomerID: this.addressDetails[0].UserId,
        AddressId: this.addressDetails[0].Id,
        PaymentType: "Online", ProductId: this.productDetails[0].Productid,
        Quantity: this.Quantity, Size: this.currntPacksize,
        Price: this.currentPrice, TransactionID:
         this.n, GstPrice: this.gstAmount,
        DeliveryCharge: 0,
        TotalPrice: this.currentPrice * this.Quantity, OfferPrice: 0,
          CreatedBy: this.LoginDetails.Id, Device: 'Web',
        TransactionStatus:'Success'
      })
  
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(this.arr));
    var url = "/api/Products/Insert_Customer_Orders"
    this.generalService.PostData(url, formData).then((data: any) => {
     debugger
      if (data != "") {
        if (data == "SUCCESS") {
     
        this.generalService.ShowAlert('Success','Your Order Placed Succesfully','success')
         this.router.navigate(['/MyOrders']);
        }
        else {
     $('#myModal').modal('hide');
          this.sendsms1();
          this.sendsmsToAdmin1();

       this.generalService.ShowAlert('SUCCESS','Your Order Placed Succesfully','success')
         this.router.navigate(['/MyOrders']);
        }
      }
    });

}

 sendsms1() {
    debugger
    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
    this.admin = '8008336307/9866041417'
    this.addressDetails1 = this.arr[0].Price

    var url = 'api/Products/Send_sms_custuomer_orderplaced';

    let formdata = new FormData();
    formdata.append("details", this.addressDetails1)
    formdata.append("order", this.admin)
    formdata.append("mobile", this.LoginDetails.MobileNo)

    this.generalService.PostData(url, formdata).then((data: any) => {
       this.sendsms2();
    })
  }


  sendsms2() {
    debugger
    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
    this.admin = '9866079507/8008336307'
    this.addressDetails1 = this.arr[0].Price

    var url = 'api/Products/Send_sms_custuomer_orderplaced2';

    let formdata = new FormData();
    formdata.append("details", this.addressDetails1)
    formdata.append("order", this.admin)
    formdata.append("mobile", this.LoginDetails.MobileNo)

    this.generalService.PostData(url, formdata).then((data: any) => {
    })
  }


 sendMailToAdmin(val) {
    debugger
    this.addressDetails1 = []
    this.addressDetails1 = this.addressDetails
     let arr = [];
    arr.push({
      OrderID: this.addressDetails1[0].OrderID,
      FarmerName: this.addressDetails1[0].FarmerName,
      MobileNo: this.addressDetails1[0].MobileNo,
      Area: this.addressDetails1[0].Area,
      City: this.addressDetails1[0].City,
      Email: this.addressDetails1[0].Email,
      //ProductName: this.orderDetails[0].ProductName,

    })
    var UploadFile = new FormData();
    var url = "api/Products/Assginoredr_aftersend_mailToAdmin"

    UploadFile.append("Xml", JSON.stringify(arr));
    UploadFile.append("flag", val);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
     this.sendsmsToAdmin();
    })
  }

 sendsmsToAdmin() {
    debugger
      this.addressDetails1 = this.addressDetails

    var AdMobile = '9866041417'
      var MessageText = "A new order has been placed by the customer" +':'+ this.LoginDetails.FarmerName + ':' + this.LoginDetails.MobileNo + ':'+ this.addressDetails1[0].Area + "." + "Ykmart";
      var UploadFile = new FormData();
      var url = "api/Products/Customer_new_orderPlacement"
      debugger
      UploadFile.append("Name", this.LoginDetails.FarmerName);
      UploadFile.append("Mobile", this.LoginDetails.MobileNo);
      UploadFile.append("ID", this.addressDetails1[0].Area);
      UploadFile.append("AdMobile", AdMobile);
      UploadFile.append("Text", MessageText);

      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
      })
    }

  sendsmsToAdmin1() {
    debugger
    this.addressDetails1 = this.addressDetails

    var AdMobile = '8008336307'
    var MessageText = "A new order has been placed by the customer" + ':' + this.LoginDetails.FarmerName + ':' + this.LoginDetails.MobileNo + ':' + this.addressDetails1[0].Area + "." + "Ykmart";
    var UploadFile = new FormData();
    var url = "api/Products/Customer_new_orderPlacement"
    debugger
    UploadFile.append("Name", this.LoginDetails.FarmerName);
    UploadFile.append("Mobile", this.LoginDetails.MobileNo);
    UploadFile.append("ID", this.addressDetails1[0].Area);
    UploadFile.append("AdMobile", AdMobile);
    UploadFile.append("Text", MessageText);

    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
    })
  }


}

