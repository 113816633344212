import { Component, OnInit } from '@angular/core';
import { EncryptionService } from '../service/encryption.service';
import { GeneralService } from '../service/general.service';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {
  signinvalue: any;

  constructor(public userIdle: UserIdleService, public router: Router,public encryptionService: EncryptionService, public generalService: GeneralService, ) {
    this.signinvalue = localStorage.getItem("keepsignin");
  }

  ngOnInit(): void {
    if (this.signinvalue != "true") {
      //Start watching for user inactivity.
      this.userIdle.startWatching();
      // Start watching when user idle is starting and reset if user action is there.
      this.userIdle.onTimerStart().subscribe(count => {
        var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
        for (let event of eventList) {
          document.body.addEventListener(event, () => this.userIdle.resetTimer());
        }
      });
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        this.userIdle.stopWatching();
        localStorage.removeItem("LoginDetails");
        localStorage.removeItem("keepsignin");
        this.router.navigate(['/CustomerLogin']);
        this.generalService.ShowAlert('INFO', 'Your Session has been expired.', 'info');
      });
    }
  }

}
