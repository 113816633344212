<br /><p-toast position="top-center"></p-toast>
<div class="container cart_p">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-10">
          <div style="font-size: 20px;letter-spacing: 0.3px;line-height: 30px;">Shopping Cart</div>

        </div>
        <div class="col-lg-2" style="text-align:right">
          <div class="price_0">Price</div>
        </div>
      </div>

      <hr style="margin-top: 0;" />
      <div *ngFor="let items of cartItems;let i=index">
        <div class="row">
          <div class="col-lg-10" style="margin-top:1%">
            <div class="row">
              <div class="col-lg-4" style="text-align:center;cursor:pointer">
                <img src={{HomeURL+items.Image}} style="height:152px;width:135px" (click)="navigatingToProduct(items.productname)" />
              </div>
              <div class="col-lg-8">
                <div>
                  {{items.productname}}-{{items.CategoryName}}-{{items.Pack}}
                </div>
                <div>
                  <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                  <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
                  <i class="fa fa-star rateColor" aria-hidden="true"></i>
                </div>
                <!--<div class="ratefont">Sold By : YK laboratories</div>-->
                <div class="ratefont">Size : {{items.Pack}}</div>
                <div class="ratefont">
                  Qty
                  <select [(ngModel)]="items.Quantity" (change)="changeQuantity()">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                  &nbsp; <button *ngIf="loginStatus" mat-button color="accent" (click)="deleteCartfromTable(i,items.CartId)" style="height: 22px; width: 56px;font-size: 12px;background-color: #92278f;" class="btn btn-primary btn-rounded"><p style="font-size: 13px; margin-left: -5px;margin-top: -5px;">Delete</p></button>
                  <button *ngIf="!loginStatus" mat-button color="accent" (click)="deleteCartfromTable(i,items.CartId)" style="height: 22px; width: 56px;font-size: 12px;background-color: #92278f;" class="btn btn-primary btn-rounded"><p style="font-size: 13px; margin-left: -5px;margin-top: -5px;">Delete</p></button>
                  <!--| <span *ngIf="loginStatus" class="deletefont" (click)="deleteCartfromTable(i,items.CartId)"><b>Delete</b></span>
              | <span *ngIf="!loginStatus" class="deletefont" (click)="deleteFromCart(i)">Delete</span>-->
                  <!-- <span *ngIf="loginStatus" class="deletefont" (click)="addToWishlist(items,i)">Move to wishlist</span>-->
                  <span *ngIf="!loginStatus" class="deletefont" routerLink="/CustomerLogin">Move to wishlist</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 money_0">
            <h6><b>₹{{items.Price*items.Quantity}}</b></h6>
          </div>
        </div>
        <hr style="margin-top:0;" />

      </div>
      <div class="col-lg-5">
        <div *ngIf="this.cartItems==''||this.cartItems==null">
          <i class="fa fa-cart-plus" routerLink="/Home" style="font-size:250px;"></i>
        </div>
        <button type="button" class="cartbtn" routerLink="/Home">Continue to shopping</button>
      </div>

    

    </div>
    <div class="col-lg-3">
      <h5>Payment Details </h5>
      <hr />
      <br />
      <div class="addcartCard">
        <div *ngIf="!offerPrice">
          <div class="ratefont">
            Subtotal <div style="color: black;margin-left: 147px;margin-top: -30px;"><b>:&nbsp;₹{{subTotal}}.00</b></div>
          </div>
          <div class="ratefont">
            Delivery Charge<div style="color: black;margin-left: 147px;margin-top: -30px;">
              <b>:&nbsp;₹{{deliveryCharge}}.00</b>
            </div>
          </div>

          <div class="ratefont">
            Total price<div style="color: black;margin-left: 147px;margin-top: -30px;">
              <b>:&nbsp;₹{{totalPrice}}.00</b>
            </div>
          </div>

        </div>
        <div *ngIf="offerPrice">
          <div class="ratefont">
            Subtotal<b>₹{{offerDetails.subTotaloff}}</b>
          </div>
          <div class="ratefont">
            Offer price:<b>₹{{offerPrice}}</b>
          </div>
          <div class="ratefont">
            Delivery Charge:₹{{deliveryCharge}}
          </div>
          <div class="ratefont">
            Total price:<b>₹{{offerDetails.totalPriceoff}}</b>
          </div>
        </div>
        <div class="ratefont">
          Offer Code: <span><input style="width: 12rem;" type="text" placeholder="Enter offer code" (blur)="changeData()" [(ngModel)]="offerCode" /></span>
          &nbsp;       <span class="checkbtn" (click)="checkOfferisValidorNot(offerCode)">Check</span>
          <div *ngIf="alert"><span style="color:red;">{{alert}}</span></div>
          <!--<div *ngFor="let item of offersdata">
        <input type="radio" name="offersRadio" [(ngModel)]="offerId" value="{{item.OfferID}}" (change)="getOfferes(item)" style="width: 18px;height: 18px;" />
        {{item.PromoCode}} (Maximum Amount : {{item.MaxAmount}} , Applicable Amount : {{item.ApplicableAmount}})
      </div>-->
        </div>

      </div>
      <br />
      <div *ngIf="loginStatus && LoginDetails.RoleId==2">
        <div *ngFor="let item of addressDetails; let i=index" class="addcartCard">
          <!--[checked]="(i=== 0)"-->
          <div>
            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.Id}}" (change)="getSelectedAddress(item.Id)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
          </div>
          <div style="padding-left: 33px;
    padding-top: -20px;
    margin-top: -23px;">
            <i class="fa fa-trash" (click)="deleteaddress(i,item.Id)" style="color:red;margin-left: 103px;font-size: 21px;"></i> <span class="icon-name"></span>
            <button mat-button color="accent" style="height: 23px;    margin-left: 142px; margin-top: -45px;width: 61px;background-color: #046804;" routerLink="/Address" class="btn btn-primary btn-rounded"><p style="font-size: 9px;margin-left:-5px">Add Address</p></button>
          </div>
          <div class="ratefont">







            <!--{{item.Address}}-->
            {{item.Area}}, {{item.HNo}}, {{item.Landmark}}, {{item.City}}, {{item.State}}, {{item.Pincode}}
          </div>
        </div>
        <br />
      </div>

      <div *ngIf="LoginDetails.RoleId==3">
        <div *ngFor="let item of addressDetails.slice(0,1); let i=index">
          <!--[checked]="(i=== 0)"-->
          <div>
            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.Id}}" (change)="getSelectedAddress(item.Id)" style="width: 18px;height: 18px;" /> Address {{i+1}}
          </div>

          <div style="padding-left: 33px;
    padding-top: -20px;
    margin-top: -23px;">
            <button mat-button color="accent" style="height: 23px;    margin-left: -30px; margin-top: -77px;width: 78px;background-color: #046804;" routerLink="/Address" class="btn btn-primary btn-rounded"><p style="font-size: 9px;margin-left:-5px">Add Details</p></button>
            <i class="  fa fa-trash" (click)="deleteaddress(i,item.Id)" style="color:red;margin-left: 103px;font-size: 21px;"></i> <span class="icon-name"></span>

          </div>
          <!--{{item.Address}}-->
          {{item.Area}}, {{item.HNo}}, {{item.Landmark}}, {{item.City}}, {{item.State}}, {{item.Pincode}}
        </div>
        <br />
      </div>


      <button *ngIf="loginStatus" type="button" class="cartbtn" (click)="procedtoBuy()">Proceed to buy</button>
      <button *ngIf="!loginStatus" type="button" class="cartbtn" routerLink="/CustomerLogin">Proceed to buy</button>

    </div>
  </div>
</div>

<!--<app-wishlist></app-wishlist>-->

<!--<div class="container">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-10">
          <div style="font-size: 20px;letter-spacing: 0.3px;line-height: 30px;">Shoping Cart</div>
        </div>
        <div class="col-lg-2" style="text-align:right">
          <div style="font-size: 13px;margin-top: 9%;
           letter-spacing: 0.3px;">Price</div>
        </div>
      </div>

      <hr style=" margin-top: 0;" />
      <div>
        <div class="row">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-4" style="text-align:center">
                <img src="../../../assets/img/projects/Vaayu-removebg-preview.png" />
              </div>
              <div class="col-lg-8">
                <div>
                  Cyto Plus Organic Bactericide and Fungicide for Plant Sucking Pest , 250ml.
                </div>
                <div>
                  <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </div>
                <div class="ratefont">Slod By : YK Mart</div>
                <div class="ratefont">Size : 250 ML</div>
                <div class="ratefont">Qty <select><option>1</option></select> | <span>Delete</span> | <span>Save</span></div>
              </div>
            </div>
          </div>
          <div class="col-lg-2" style="text-align:right">
            <h6>500</h6>
          </div>
        </div>
        <hr />


        <div class="col-lg-12" style="text-align:right;    padding-right: 0;">
          <div>Subtotal(1item): 500.00</div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <br />
      <br />
      <div class="addcartCard">
        <div class="ratefont">
          Subtotal(1item): 500.00
        </div>
        <button type="button" class="cartbtn">Proced to buy</button>
      </div>
    </div>
  </div>
</div>-->


<div class="modal" id="myModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Select Delivery mode</h4>


        
      </div>
      <div class="modal-body">
  <!--<input type="radio" name="mode" (change)="getPaymentType('CashOnDelivery')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />-->
  <input type="radio" name="mode" (change)="getPaymentType('Online')" /><span >&nbsp;&nbsp;&nbsp;Online payment</span>
        <br />
        <br /> 
        <div class="modal-footer">

          <button id="closemodal" type="button" class="btn btn-success" (click)="RazorPayPayment()" style="margin-right:46%;">Ok</button>
          <button id="closemodal" type="button" class="btn btn-danger" (click)="cancel()" style="margin-left:-43%;">Cancel</button>
        </div>

      </div>
    </div>
  </div>
</div>
