import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service'
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogDetails: any;
  Description: any;
  Image: any;
  BlogName: any;
  public router: Router;
  showindividualImage: boolean = true;
  Blogdata: any = [];
  Products: any;
  HomeUrl: any;


  constructor(public generalService: GeneralService, private sanitizer: DomSanitizer, public http: HttpClient, router: Router, ) {
    this.router = router
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.GetBlogDetails()
  }

  /*
   * Below method is used to get the Blog details from Server with reference of database path
   */
  GetBlogDetails() {
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    var url = "/api/Products/GetBlogDetails"
    this.generalService.GetData(url).then(data => {
      this.blogDetails = data
      console.log(this.blogDetails)
      for (var i = 0; i < this.blogDetails.length; i++) {
        this.blogDetails[i].Description =  decodeURIComponent(this.blogDetails[i].Description);
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }


  navigatingToSelectedProduct(data) {
    var prodName = ""; var prodarr = []
    prodarr.push(data)
    var str = data.BlogName.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    //this.generalService.setProducts(prodarr)
    //this.router.navigate(['/Blog/' + data.BlogId]);
  }

}
