<div id="messagesDropDown" class="dropdown d-inline-block">

  <!-- HTML Structure for displaying the count and triggering the details -->
  <div class="dropdown">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="messages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="showNotificationDetails()">
      <i class="fa fa-bell-o" aria-hidden="true"></i>
      <span *ngIf="notificationCount > 0" class="badge badge-danger">{{ notificationCount }}</span>
    </a>
    <div class="dropdown-menu" aria-labelledby="messages" *ngIf="showDetails"style="margin-left: -159px;
">
      <div *ngFor="let order of ordersList"> 
        Order ID:<h6 (click)="setbookingDetails()"> {{ order.OrderID }}</h6>  <!-- Replace 'orderId' with the correct property -->
        <!-- Display other order details as needed -->
      </div>
      <div *ngIf="ordersList.length === 0">
        No new orders.
      </div>
    </div>
  </div>

  <div class="dropdown-menu dropdown-menu-right rounded-0 p-0 mt-3 box-shadow" aria-labelledby="messages">

    <div class="tab-content" perfectScrollbar>
      <div class="tab-pane active" id="comments">

        <div class="list">
          <a *ngFor="let message of ordersList" href="javascript:void(0);" class="transition">


            <div class="list-content" click)="setbookingDetails(message)">
              <h3>{{message.Name}}</h3>
              <p>{{message.Area}}</p>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
