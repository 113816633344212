<!--<nav class="breadcrumb_padd" style="background-color:#fff;margin-top:2px">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active"> Categories</li>
  </ol>
</nav>-->
<div class="container-fluid goal-0" >
  <div class="container">
    <app-breadcrumb></app-breadcrumb>



    <div class="row">



      <div class="col-lg-3">
        <div class="hidden-xs">
          <div class="catHead">Goals </div>
          <!--Below div for side bar categories-->
          <div *ngFor="let item of Goals;let i=index">
            <div [class]="(GoalName === item.GoalName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideGoals(item.Id,item.GoalName)">
              {{item.GoalName}}
            </div>
          </div>
        </div>
        <br /> <br />
      </div>

      <div class="col-lg-9">
       
          <!--below div is for showing products regarding selected categories-->
          <div class="col-lg-4 col-sm-6 col-xs-6" *ngFor="let item of GoalProducts;let i=index">
            <div class="card" (click)="navigatingToProduct(item)">
              <img src={{HomeURL+item.Image}}
                   class="card-img-top imgMobile" alt="...">
              <div class="card-title over">
                {{item.productname}}
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-9">
                  <div class="price">
                    ₹ {{item.Price1}}
                  </div>
                </div>
              
              </div>
            </div>
          </div>
      
    
      </div>
    </div>
      </div>
      <!--<div class="col-lg-9">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-xs-6" *ngFor="let item of GoalProducts;let i=index">
            <div class="card" (click)="navigatingToProduct(item)">
              <img src={{HomeURL+item.Image}}
                   class="card-img-top imgMobile" alt="...">
              <div class="card-title over">
                {{item.productname}}
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-9">
                  <div class="price">
                    ₹ {{item.Price1}}
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
   
      </div>-->
    </div>
 
