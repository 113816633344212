import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service'
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit {
  deliveryCharge: any;
  offerAmount: any;
  grandTotal: any;
  billNo: any;
  orderDate: any;
  orderDetails: any = [];
  orderNo: string;
  LoginDetails: any;

  constructor(public generalService: GeneralService, public activeroute: ActivatedRoute) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.orderNo = this.activeroute.snapshot.paramMap.get('No')
  }

  ngOnInit(): void {
    this.getOrdersbasedOnOrderDetails()
  }

  getOrdersbasedOnOrderDetails() {
    debugger
    
    var arr = [];
    arr.push({
      TokenID: this.LoginDetails.Token,
      UserId: this.LoginDetails.Id,
      OrderID: this.orderNo
    })
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(arr));
    var url = "/api/Products/Get_Admin_Orderdetails_BasedOn_OrderId"
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.orderDetails = []
        this.orderDetails = data
        this.orderDate = data[0].CreatedDate
        this.billNo = data[0].TransactionID
      
        let grandTotal = data.reduce((total, order) => total + order.TotalPrice, 0);
        this.grandTotal = grandTotal
        this.offerAmount = data[0].OfferPrice
        this.deliveryCharge = 0
      }
    });
  }

  print():
    void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    //popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          
* {
  font-size: 14px;
  font-family: Arial !important;
width:100%;
}



td,
th,
tr,
table {
  border-top: 1px solid black;
  font-size: 12px;
width: 100% !important;

}
.ticket {
  width: 100% !important;
  /*max-width: 155px;*/
  max-width: 100% !important;
font-family: Arial !important;
  font-size: 14px;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  body {
font-family: Arial !important;
        height:100px; 
        width: 100%;


        /* to centre page on screen*/
       
    }
  .hidden-print,
  .hidden-print * {
    display: none !important;
width:100%;
  }
}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

}
