<div class="container">
  <br />
  <nav class="breadcrumb_padd" style="background-color:#fff">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active"> Terms & Conditions</li>
    </ol>
  </nav>
  <div class="card">
    <div style="padding:2%">
      <b> Terms & Conditions of YK Mart</b>
      <p>YK Mart operates the https://ykmart.in/ website, which provides the SERVICE.</p>

      This page is used to inform website visitors regarding our terms with the collection,
      use, and disclosure of Personal Information if anyone decided to use our Service, the
      YK Mart website.
      <br/>
      <b>Personal Information</b>
      <p>
        As part of the registration process on the Site, YKMART may collect the following personally
        identifiable information about you: Name including Full Name, email address,
        mobile phone number and contact details, Postal code, address
        and information about the pages on the site you visit/access, the links you click on the site,
        the number of times you access the page and any such browsing information.
      </p>

      <b>Account & Registration Obligations</b>
      <p>
        All shoppers have to register and login for placing orders on the Site.
        You have to keep your account and registration details current and correct for communications
        related to your purchases from the site. By agreeing to the terms and conditions, the shopper
        agrees to receive promotional communication and newsletters upon registration
      </p>

      <b>Pricing</b>
      <p>
        All the products listed on the Site will be sold at MRP unless otherwise specified.
        The prices mentioned at the time of ordering will be the prices charged on the date of the
        delivery.
        Although prices of most of the products do not fluctuate on a daily basis but some of the
        commodities do change on a daily basis.
      </p>

      <b>Return & Refunds</b>
      <p>
        We have a "no questions asked return and refund policy" which entitles all our members to
        return the product at the time of delivery if due to some reason they are not satisfied
        with the quality or freshness of the product. We will take the returned product back with
        us and issue a credit note for the value of the return products which will be credited to
        your account on the Site. This can be used to pay your subsequent shopping bills.
      </p>

      <b>You may not use the Site for any of the following purposes:</b>
      <p>
        Disseminating any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.
        Transmitting material that encourages conduct that constitutes a criminal offence or results in civil liability or otherwise breaches any relevant laws, regulations or code of practice.
        Gaining unauthorized access to other computer systems.
        Interfering with any other person's use or enjoyment of the Site.
        Breaching any applicable laws;
        Interfering or disrupting networks or web sites connected to the Site.

        Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner.
      </p>

      <b>Colours</b>
      <p>
        We have made every effort to display the colours of our products that appear on the Website
        as accurately as possible. However, as the actual colours you see will depend on your monitor,
        we cannot guarantee that your monitor's display of any colour will be accurate.
      </p>

      <b>Modification of Terms & Conditions of Service</b>
      <p>
        YKMART may at any time modify the Terms & Conditions of Use of the Website without any
        prior notification to you. You can access the latest version of these Terms & Conditions
        at any given time on the Site. You should regularly review the Terms & Conditions on the Site.
        In the event the modified Terms & Conditions is not acceptable to you, you should discontinue
        using the Service. However, if you continue to use the Service you shall be deemed to have
        agreed to accept and abide by the modified Terms & Conditions of Use of this Site.
      </p>
    </div>
</div>
