
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 

import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';

import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';

import { ImageCropperModule } from 'ngx-image-cropper';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component'; 
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

/*Page-Layout components import place*/
import { PageLayoutComponent } from './page-layout/page-layout.component'
import { HomeComponent } from './page-layout/home/home.component'
import { PageHeaderComponent } from './page-layout/page-header/page-header.component'
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component'
import { CategoriesComponent } from './page-layout/categories/categories.component'
import { CustomerLoginComponent } from './page-layout/customer-login/customer-login.component'
import { ReactiveFormsModule } from '@angular/forms';
import { AboutUsComponent } from './page-layout/about-us/about-us.component'
import { ContactUsComponent } from './page-layout/contact-us/contact-us.component';
//import { productDetails } from './page-layout/product-details/product-details.component';
//import { productDetails1Component } from './page-layout/product-details1/product-details1.component'
import { BlogComponent } from './page-layout/blog/blog.component'
import { CartComponent } from './page-layout/cart/cart.component'
import { CKEditorModule } from 'ng2-ckeditor';
import { WishlistComponent } from './page-layout/wishlist/wishlist.component';
import { AddAddressComponent } from './page-layout/add-address/add-address.component'
import { GoalsComponent } from './page-layout/goals/goals.component'
import { FarmerProducesComponent } from './page-layout/farmer-produces/farmer-produces.component'
import { GMapModule } from 'primeng/gmap'
import { SpinneroverlayComponent } from 'src/app/service/spinneroverlay/spinneroverlay.component';
import { ProductEnquiryComponent } from './page-layout/product-enquiry/product-enquiry.component';
import { MyOrdersComponent } from './page-layout/my-orders/my-orders.component'
import { OrderInvoiceComponent } from './page-layout/order-invoice/order-invoice.component';
import { MyEnquiriesComponent } from './page-layout/my-enquiries/my-enquiries.component';
import { CustomerRegistrationComponent } from './page-layout/customer-registration/customer-registration.component';
import { PopularPurchaseComponent } from './page-layout/popular-purchase/popular-purchase.component';
import { RelatedProductsComponent } from './page-layout/related-products/related-products.component';
import { UVideoSafe } from './Uvideosafe';
import { PrivacyPolicyComponent } from './page-layout/privacy-policy/privacy-policy.component';
import { ProductInformationComponent } from './page-layout/product-information/product-information.component';
import { UserIdleModule } from 'angular-user-idle';
import { TermsandConditionsComponent } from './page-layout/termsand-conditions/termsand-conditions.component';


@NgModule({   
  imports: [  
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PerfectScrollbarModule,
    NgbModule,
    GMapModule,
    AutoCompleteModule,
    MultiselectDropdownModule,
    ReactiveFormsModule,
    CKEditorModule,
   ImageCropperModule,
    UserIdleModule.forRoot({ idle: 500, timeout: 500, ping: 500 }),
    ToastModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), 
    PipesModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    /*page-layout declarations*/
    PageLayoutComponent,
    HomeComponent,
    PageHeaderComponent,
    PageFooterComponent,
    CustomerLoginComponent,
    CategoriesComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    AboutUsComponent,
      ContactUsComponent,
  
    BlogComponent,
    CartComponent,
    CustomerLoginComponent,
    WishlistComponent,
    AddAddressComponent,
    GoalsComponent,
    FarmerProducesComponent,
    ProductEnquiryComponent,
    MyOrdersComponent,
    OrderInvoiceComponent,
    MyEnquiriesComponent,
    CustomerRegistrationComponent,
    PopularPurchaseComponent,
    RelatedProductsComponent,
    UVideoSafe,
    PrivacyPolicyComponent,
    ProductInformationComponent,
    TermsandConditionsComponent
  ],
  providers: [ 
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG } 
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SpinneroverlayComponent
  ]
})
export class AppModule { } 
