<!--login page form-->

<div class="container cus_0">
  <div class="row" style="width:100%;">
 
    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <br /><br />
      <div class="card"style="border-color:#a9bde0;text-align:center; padding: 18px;margin-top: 16px;   padding-top: 64px;">
        <div class="lead">
          <!-- loginpage side image-->


          <img src="../../../assets/img/IMG-20220210-WA0047.jpg" style="height: 116px; margin-top: -36px;" /><br />
          <br />
          <h2><b> Purchase Pure Organic YK Products</b></h2>
          <br />
          <p style="text-align: justify;font-size: 14px; font-family: 'Poppins'">

            &nbsp;   &nbsp;    &nbsp;    &nbsp;  We aim to give high-performance organic manure to farmers for achieving a significant and sustainable
            increase in agriculture production through environmental free and pollinators free products.
          </p>
          <p style="text-align: justify;font-size: 14px; font-family: 'Poppins'">
            &nbsp;    &nbsp;    &nbsp;   We YK Mart celling of certified organic products especially Botanical Virucide, Fungicide water base and
            Botanical Insecticide & Sucking pest oil base, with the aid of Medicinal plants and related alkaloids which
            controls and suppress the multiplication of Bacterial and Fungal growth.
          </p>
          <p style="text-align: justify;font-size: 14px; font-family: 'Poppins'">&nbsp;    &nbsp;    &nbsp; Morever we are also farming produces organic turmeric,organic chilli,organic papaya and organic millets.</p><br /><br />


          <div class="row" style="    font-size: 12px; font-weight: 900;">
            <div class="col-lg-5 col-md-5">Powered by: <a href="https://gagri.net/" target="_blank"> Gagri Global IT Services</a></div>
            <div class="col-lg-3 col-md-3"> Version: Beta 1.0.0</div>
            <div class="col-lg-4 col-md-4">copyright @ 2022 YK Mart.in</div>
          </div>
          <!--<h6>Powered by: Gagri Global IT Services</h6>
  <small> Version: Beta 1.0.0</small><br />
  <small>copyright @ 2022 YK Mart.in</small>-->
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 login_bg">

      <div style="text-align:center;">
        <!---Loginpage logo-->
        <img src="../../../assets/img/IMG-20220210-WA0047.jpg"  style="height: 116px; margin-top: -36px;" />
      </div>
      <div class="admin_headiang"><h4><b>Hello Customer/Employee...!</b></h4></div>
      <div>
        <!--*ngIf=""-->
        <!---- customerDon't have an register to click  Sign up now open the register form-->
        <a routerLink="/CustomerRegistration" class="transition"
           style="font-family: poppins;letter-spacing: 1px;">Don't have an account?<u> <b style="color:#b22995;font-size: 14px;">Sign up now!</b></u></a>
        <div>
          <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" class="text-left mt-4">
            <div class="form-group">
              <input [formControl]="email" class="form-control validation-field" placeholder="Email or Mobile" type="text">
              <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').hasError('required')">Email Id is required</small>
              <small class="text-danger" *ngIf="form.get('email').touched && form.get('email').hasError('EmailId')">Invalid EmailId address</small>
            </div>
            <div class="form-group">
              <input [formControl]="password" class="form-control validation-field" placeholder="Password" type="Password" id="myInput">

              <i class="fa fa-eye eye_0" *ngIf="passwordshow" (click)="ShowPassword(1)"></i><span class="icon-name"></span>
              <i class="fa fa-eye-slash slash_0" *ngIf="!passwordshow"(click)="ShowPassword(1)"></i><span class="icon-name"></span>

              <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').hasError('required')">Password is required</small>
              <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</small>
            </div>
            <div class="form-group d-flex justify-content-between">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" [checked]="Remember" (change)="RememberMe($event)" class="custom-control-input" id="login-checkbox">
                <label class="custom-control-label fontt" for="login-checkbox">Keep me signed in.</label>
              </div>
            
                <a (click)="ForgotPassword()" class="transition pull-right" style="cursor:pointer;">Forgot password?</a>
          
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-lg-7 col-md-7"></div>
                <div class="col-lg-5 col-md-5">
                  <button class="login__button" type="submit">Sign in</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

  
<div class="modal enter_0" id="myModal2" style="margin-top:9rem;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Mobile<span style="color:red">*</span></label>
          <input [(ngModel)]="MobileNo" class="form-control Caps" placeholder="Enter Mobile" type="text">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="CheckMobile(MobileNo)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->  
<!-- #region check otp popup for forgot password-->
<div class="modal" id="myModal3">
  <div class="modal-dialog" role="document">
    <div class="modal-contentotp">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>OTP<span style="color:red">*</span></label>
          <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text">
        </div>
        <div class="row" style="float:right;cursor:pointer;margin-right:10px">
          <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="VerifyOtp(OTP)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
<!-- #region update customer and vendor password-->
<div class="modal" id="myModal4">
  <div class="modal-dialog" role="document">
    <div class="modal-content1">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Password<span style="color:red">*</span></label>
          <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="text">
          <br>
          <label>Confirm Password<span style="color:red">*</span></label>
          <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirmpassword" type="text">
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<style>
  .card border-0 box-shadow rounded-0 {
    width: 600px;
    padding: 100px;
  }

  .modal-dialog {
    max-width: 31% !important;
    margin: 1.75rem auto;
  }
  @media only screen and (max-width :600px) {
    .modal-dialog {
      max-width: 74% !important;
      margin-left: 53px;
    }
  }
  </style>
  <!-- ------------------------------->
