
  <footer class="mainfooter  foot" role="contentinfo">
    <div class="footer-middle">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-6">
            <!--Column1-->
            <div class="footer-pad">
              <h4 style="margin-bottom: 1.5rem; font-family: Roboto Condensed; font-weight: bold; font-size: 2rem;">Hot products</h4>
              <ul class="list-unstyled">
                <li><a href="https://ykmart.in/#/Product/_IMMUNO" target="_blank">IMMUNO-ORGANIC MANURE,500 ml</a></li>
                <li><a href="https://ykmart.in/#/Product/_FOST-ROOTEX" target="_blank">FOST-ROOTEX-ORGANIC MANURE</a></li>

                <li><a href="https://ykmart.in/#/Product/_VAAYU" target="_blank">VAAYU-ORGANIC SUCKING PEST,100ml</a></li>
                <li><a href="https://ykmart.in/#/Product/_CUREAL" target="_blank"> CUREAL-BOTANICAL BACTERICIDE & FUNGICIDE</a></li>
                <li><a href="https://ykmart.in/#/Product/_MAX_SIZE" target="_blank">MAX SIZE-ORGANIC MANURE</a></li>
                <li><a href="https://ykmart.in/#/Product/_NEEMOIL" target="_blank">NEEMOIL-ORGANIC MANURE</a></li>
                <li><a href="https://yklaboratories.com/#/Products/PAPPAYA%20SPECIAL/7" target="_blank">PAPPAYA & CHILLI SPECIAL-YK LABORATORIES SPECIAL</a></li>
                <li><a href="https://yklaboratories.com/#/home" target="_blank">TOMATO SPECIAL-YK LABORATORIES SPECIAL</a></li>
                <li><a href="https://ykmart.in/Product/_WILT_SPECIAL" target="_blank">WILT SPECIAL </a></li>
                <li><a href="https://ykmart.in/Product/_Oxyveg_-_cotton_special">Oxyveg Cotton Special</a></li>


              </ul>
              <div class="footer-pad">
                <div class="col-lg-4">
                  <h4 style="padding-top: 24px; margin-left: -12px; font-family: Roboto;">We Accept</h4>
                </div>
                <div class="col-lg-1">
                  <img src="../../../assets/img/payu.PNG" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="col-md-2 col-sm-6">
            <div class="footer-pad">
              <h4>Hot products</h4>
              <ul class="list-unstyled">

                <li><a routerLink="/Home">Home</a></li>
                <li><a routerLink="/AboutUs">About Us</a></li>
                <li><a routerLink="ContactUs">Contact Us</a></li>
                <li><a routerLink="CustomerLogin">Customer Login</a></li>
                <li><a routerLink="/login">Admin Login</a></li>

                <li><a href="https://yklaboratories.com/" target="_blank">YK Laboratories</a></li>

              </ul>
              <div class="footer-pad">
                <div class="col-lg-4">
                  <h4 style="padding-top: 24px;">We Accept</h4>
                </div>
                <div class="col-lg-1">
                  <img src="../../../assets/img/payu.PNG" />
                </div>
              </div>
            </div>
          </div>-->
          <div class="col-md-3 col-sm-6">
            <!--Column1-->
            <div class="footer-pad">
              <!---links-->
              <h4 style="margin-bottom:1.5rem;font-family:roboto;font-weight:bold;font-size:2rem;">Quick links</h4>
              <ul class="list-unstyled">

                <li><a href="/Home">Home</a></li>

                <li><a href="/AboutUs">About Us</a></li>
                <li><a href="ContactUs">Contact Us</a></li>
                <li><a href="/Blog">Blog</a></li>
                <li><a href="CustomerLogin">Customer Login</a></li>
                <li><a href="/login">Admin Login</a></li>

                <li><a href="https://yklaboratories.com/" target="_blank">YK Laboratories</a></li>
                <li><a href="/PrivacyPolicy">Privacy policy</a></li>
                <li><a href="/TermsandConditions">Terms & Conditions</a></li>

              </ul>
            </div>
          </div>
          <!--<div class="col-md-2 col-sm-6">
          <!--Column1-->
          <!--<div class="footer-pad">
              <h4>APP Links </h4>
              <ul class="list-unstyled">
                <div (click)="navigate()">
                  <a><img src="../../../assets/img/app/PlayStoreMobApp.png" style="width:110px; cursor:pointer; " /></a>
                </div>

              </ul>
            </div>
          </div>-->
          <div class="col-md-3">
            <h4 style="margin-bottom:1.5rem;font-family:roboto;font-weight:bold;font-size:2rem;">Address</h4>
            <address>
              <div style="font-family: 'roboto';font-size: 13px;letter-spacing: 0.2px; margin-right: 00px;">

                <a href="/ContactUs">SY.NO. 255, PLOT NO.3, EAST PART, KUNTLOOR (V), HAYATHNAGAR, R.R. DIST. TELANGANA - 501505. INDIA.</a>
                <br /><br /><p style="font-family: 'Poppins';font-size: 12px;letter-spacing: 0.2px;">Customer Login please call</p><span style="font-size:16px;">
                  <a href="Tel:9066-179-179">
                    (+91) 970 512 9639
                  </a>
                </span><div>
                  <span style="font-size:16px;">
                    <a href="Tel:9066-179-179">
                      (+91) &nbsp;&nbsp;949 141 8181
                    </a>
                  </span>
                </div>

              </div>
            </address>
          </div>
        </div>
        <div class="container-fluid" style="  border-top: 1px solid #c1c1c1;">
          <div class="container" style="padding:0">
            <div class="row" style="margin: 0.5% 0%;">
              <div class="col-lg-6" style="padding:0">
                <p style="margin-left:-19px; font-family: Roboto;">&copy; Copyright 2022 - All rights reserved.</p>
              </div>
              <!--<div class="col-lg-6 footer_0" style="text-align:right; font-family: Roboto;padding:0;">
    <p>  Powered by :</p>  <a style="color:white; font-family: Roboto;" href="http://gagri.net/" target="_blank" class="global"> <p>Gagri Global IT Services Pvt. Ltd.</p></a>
  </div>-->

              <!--<div class="col-lg-1"></div>-->
      
            
              <div class="col-lg-6 global"style="text-align:right">Powered By:&nbsp;&nbsp; <a style="color:white; font-family: Roboto;" href="http://gagri.net/" target="_blank" >Gagri Global IT Services Pvt.Ltd</a></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

