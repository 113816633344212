
<!--<div class="d-flex justify-content-center align-items-center w-100 h-100 login-container" style="background: #f6f6f7;">-->
  <div class="container ">
    <div class="row" style="background: #fff;border-radius: 16px;margin-top: 43px;">
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="padding-left:0px;">
        <img src="../../../assets/img/YK Mart .png"  style="width: 100%;border-radius: 16px 0 0 16px;" />
      </div>

      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <div class="card border-0 box-shadow rounded-0">

          <div class="card-body text-center pb-1">
            <h2 class="login_heading">Register Customer</h2>
            <a routerLink="/CustomerLogin" class="transition" style="font-family: poppins;letter-spacing: 1px;">Already have an account?<u> <b style="color:#b22995;font-size: 16px;"> Sign in!</b></u></a>

            <form [formGroup]="form" (ngSubmit)="SignUp(form.value)" class="text-left mt-4">
              <div class="form-group">
                <input [formControl]="FarmerName" class="form-control validation-field" placeholder="Full Name" type="text">
                <small class="text-danger" *ngIf="form.get('FarmerName').touched">
                  <span *ngIf="form.get('FarmerName').hasError('required')">Full Name is required</span>
                  <span *ngIf="form.get('FarmerName').hasError('minlength')">Minimum of 3 characters</span>
                </small>
              </div>
              <div class="form-group">
                <input [formControl]="MobileNo" class="form-control validation-field" placeholder="MobileNo" type="text" (input)="CheckMobileNo()" maxlength="10">
                <small class="text-danger" *ngIf="form.get('MobileNo').touched">
                  <span *ngIf="form.get('MobileNo').hasError('required')">Mobile is required</span>
                  <span *ngIf="checkNo==10">Mobile Exist</span>
                </small>
              </div>
              <div class="form-group">
                <input [formControl]="Email" class="form-control validation-field" placeholder="Email" type="text" >
                <small class="text-danger" *ngIf="form.get('Email').touched">
                  <!--<span *ngIf="checkemail==10">Email Exist</span>-->
                </small>
              </div>


              <div class="form-group">
                <input [formControl]="Password" class="form-control validation-field" placeholder="Password" type="Password" id="myInput">

                <i class="fa fa-eye" *ngIf="passwordshow" style="color:green; font-size: 18px;margin-left: 347px;position: absolute;margin-top: -25px;" (click)="ShowPassword(1)"></i><span class="icon-name"></span>
                <i class="fa fa-eye-slash" *ngIf="!passwordshow" style="color:green; font-size: 18px;margin-left: 347px;position: absolute;margin-top: -25px;" (click)="ShowPassword(1)"></i><span class="icon-name"></span>
                <small class="text-danger" *ngIf="form.get('Password').touched && form.get('Password').hasError('required')">Password is required</small>
                <small class="text-danger" *ngIf="form.get('Password').touched && form.get('Password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</small>
              </div>             
              <div class="form-group">
                <input [formControl]="confirmPassword" class="form-control validation-field" placeholder="Confirm Password" type="password"id="myInput1">
                <i class="fa fa-eye" *ngIf="confirmpasswordshow" style="color: green; font-size: 18px;margin-left: 347px; position: absolute; margin-top: -36px;" (click)="ShowPassword1(2)"></i><span class="icon-name"></span>
                <i class="fa fa-eye-slash" *ngIf="!confirmpasswordshow" style="color: green; font-size: 18px;margin-left:347px; position: absolute; margin-top: -29px;" (click)="ShowPassword1(2)"></i><span class="icon-name"></span>
                <small class="text-danger" *ngIf="form.get('confirmPassword').touched">
                  <span *ngIf="form.get('confirmPassword').hasError('required')">Confirm Password is required</span>
                  <span *ngIf="form.get('confirmPassword').hasError('mismatchedPasswords')">Password do not match</span>
                </small>
              </div>
              <div class="form-group">
                <button *ngIf="checkNo!=10" [disabled]="!form.valid"  class="btn btn-block" type="submit" style="background: #bb1278;color: white;" (click)="Sendsms()">Sign up</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--</div>-->

<div class="modal" id="myModal1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Otp has been send to your mobile</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter Otp" type="text">
        </div>
      </div>
      <div class="modal-footer">
        
        <button type="submit" class="btn btn-primary" (click)="CheckOtp(otp)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->
