
<div *ngIf="spinner" class="lds-roller">
  <div></div><div></div><div></div><div>
  </div><div></div><div></div><div></div><div>
  </div>
</div>
<!-----this code contactus page  -->
<div class="container">
  <br />
  <nav class="breadcrumb_padd" style="background-color:#fff;margin-top:3%">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active"> Contact Us</li>
    </ol>
  </nav>

  <!--- contact us page  Send Enquiry the enquiry received ADMIN  -->
  <div class="card">
    <div class="row">
      <div class="col-md-6">
        <h3>Contact Form</h3>
        <form [formGroup]="ContactUsForm">
          <div class="form-group">
            <label for="pwd">Name :</label>
            <input class="form-control" formControlName="Name" name="name" type="text" placeholder="Full Name">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Name.touched && ContactUsForm.controls.Name.errors?.required">Field is required</small>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email :</label>
            <input class="form-control" formControlName="Email" name="name" type="text" placeholder="Email Address">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Email.touched && ContactUsForm.controls.Email.errors?.required">Field is required</small>
            </div>
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Email.errors?.email">Incorrect email format.</small>
            </div>
          </div>
          <div class="form-group">
            <label for="pwd"> Mobile Number :</label>
            <input class="form-control" formControlName="Mobile" name="name" type="text" placeholder="Mobile Number">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Mobile.touched && ContactUsForm.controls.Mobile.errors?.required">Field is required</small>
            </div>
          </div>
          <div class="form-group">
            <label for="pwd"> Message :</label>
            <textarea rows="4" formControlName="Message" placeholder="Message..." class="form-control" cols="50"></textarea>
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Message.touched && ContactUsForm.controls.Message.errors?.required">Field is required</small>
            </div>
          </div>
          <button style="float:right" class="btn btn-primary" [disabled]="ContactUsForm.invalid" (click)="onSubmit(ContactUsForm.value)">Send Mail</button>
        </form>
      </div>
      <!----YK MART ADDRESS-->
      <div class="col-md-6">
        <h3>Reach Us</h3>
        <ul class="tcy">
          <i class="fa fa-map-marker icon_p"></i>

          SY.NO. 255, PLOT NO.3, EAST PART, KUNTLOOR (V), HAYATHNAGAR, R.R. DIST. TELANGANA - 501505. INDIA.

        </ul>
        <div class="tcy">
          <i class="fa fa-envelope-o icon_p"></i><a href="mailto: info@ykmart.in">info@ykmart.in</a>
        </div>
        <div class="tcy">
          <i class="fa fa-phone icon_p"></i>
          <a href="tel:123-456-7890">970 512 9639</a> (Or)
          <a href="tel:123-456-7890"> 949 141 81819</a>

        </div>
        <br />
        <img src="../../../assets/img/YK Mart .png" href="/Home" style="width:71%;" />
      </div>

      <br>

    </div>
    <br />
    <!--YK MART googleMap-->
    <div class="row">
      <div class="col-md-12">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30466.20535342644!2d78.60781882879768!3d17.35046315696774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba1d2650e2773%3A0x74f36db397451857!2sYK+Laboratories%3A+Immuno+Enviro+Cyto+plus+in+India.!5e0!3m2!1sen!2sin!4v1557926319405!5m2!1sen!2sin" width="100%" height="323px" frameborder="0" style="border:0" allowfullscreen></iframe>
        <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30277.157586717058!2d83.63396869251442!3d18.45443440553825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3c76c475ae7cdb%3A0xdaaa023e694d5298!2sRazam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1579775112291!5m2!1sen!2sin" width="1080" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>-->

      </div>
    </div>
  </div>
</div>
<br />
<!--this code use for google analyticals-->
<div class="sticky2-container1">
  <ul class="sticky3" style=" margin-top:200px;">
    <li>

      <img height="32" src="assets/media/call-circle.png" width="32">
     <a  href="Tel:9066-179-179">Call Us<br>Schedule Appointment</a>
    </li>


    <!--<li _ngcontent-c0="">

    <img _ngcontent-c0="" height="32" src="assets/media/youtube-circle.png" width="32" />
    <p _ngcontent-c0="">
      <a _ngcontent-c0="" href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
        Youtube
        <br _ngcontent-c0="">yk mart
      </a>
    </p>
  </li>-->
    <!--<li _ngcontent-c0="">
    <img _ngcontent-c0="" height="32" src="assets/media/facebook-circle.png" width="32">
    <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br _ngcontent-c0="">Yk mart</a></p>
  </li>-->
   
    <li>
      <div class="imae1">
        <img height="32" src="assets/media/images (1).jpg" width="32" />
        <p><a href="mailto:info@ykmart.in">info@ykmart.in<br>Yk mart</a></p>
      </div> </li>
    <!--<li _ngcontent-c0="">

    <img _ngcontent-c0="" height="32"src="assets/img/download (1).png"width="32"/>
    <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://twitter.com/home" target="_blank">Twitter<br _ngcontent-c0="">KISAN</a></p>
  </li>-->

    <li>
      <img height="32" src="assets/media/whatsapp.png" width="32" />
      <p ><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br>Yk mart</a></p>
    </li>
  </ul>
</div>

















<style>
  .tcy {
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  /*ul, li {
    list-style: none;
    display: inline-flex;
    margin: 0
  }*/

  .card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    padding: 13px;
  }

  .icon_p {
    font-size: 20px;
    color: red;
    margin-right: 1%;
  }

  /*styles for loader or spinner*/
  .lds-roller {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    z-index: 999;
    margin: 16% 0% 0% 35%;
  }

    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 32px 32px;
    }

      .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: red;
        margin: -3px 0 0 -3px;
      }

      .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
      }

        .lds-roller div:nth-child(1):after {
          top: 50px;
          left: 50px;
        }

      .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
      }

        .lds-roller div:nth-child(2):after {
          top: 54px;
          left: 45px;
        }

      .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
      }

        .lds-roller div:nth-child(3):after {
          top: 57px;
          left: 39px;
        }

      .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
      }

        .lds-roller div:nth-child(4):after {
          top: 58px;
          left: 32px;
        }

      .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
      }

        .lds-roller div:nth-child(5):after {
          top: 57px;
          left: 25px;
        }

      .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
      }

        .lds-roller div:nth-child(6):after {
          top: 54px;
          left: 19px;
        }

      .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
      }

        .lds-roller div:nth-child(7):after {
          top: 50px;
          left: 14px;
        }

      .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
      }

        .lds-roller div:nth-child(8):after {
          top: 45px;
          left: 10px;
        }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ui-widget {
    font-size: 12px !important;
    text-decoration: none;
    width: 100%;
  }
  /*end code*/
  
</style>
