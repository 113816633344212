<!--<nav class="breadcrumb_padd" style="background-color:#fff;margin-top:2px">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active"> Categories</li>
  </ol>
</nav>-->

<div class="container-fluid cate_0" >
  <div class="container">
    <app-breadcrumb></app-breadcrumb>
    <div class="row">
      <div class="col-lg-3">
        <div class="hidden-xs">
          <div class="catHead">Categories </div>
          <!--Below div for side bar categories-->
          <div *ngFor="let item of Categories;let i=index">
            <div style="text-transform:lowercase;"class="active_cat" [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName)">
              <span style="text-transform:capitalize;">{{item.CategoryName | lowercase | titlecase}}</span>
            </div>
          </div>
        </div>
        <div class="hidden-lg">
          <div class="catHead">Categories </div>
          <div style="overflow:overlay;">
            <!--Below div for side bar categories-->
            <ul>
              <li class="catHeader" *ngFor="let item of Categories;let i=index">
                <span [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName);test()">
                  {{item.CategoryName | lowercase | titlecase}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <!--<div class="hidden-lg">
          <div class="catHead">Categories </div>
    
          <div style="overflow: overlay;">
            <ul>
              <li class="catHeader" *ngFor="let item of Categories">
                <span [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName);test()">
                  {{item.CategoryName}}
                </span>
              </li>
            </ul>
          </div>-->

          <!--<div *ngFor="let item of Categories;let i=index">
      <span [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName);test()">
        {{item.CategoryName}}
      </span>
    </div>
    <div class="row">
      <div class="col-xs-6" *ngFor="let item of Categories;let i=index">
        <span [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName);test()">
          {{item.CategoryName}}
        </span>
      </div>
    </div>-->
          <!--<div *ngFor="let item of Categories;let i=index">
      <div [class]="(CategoryName === item.CategoryName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CategoryId,item.CategoryName);test()">
        {{item.CategoryName}}
      </div>
    </div>-->
        <!--</div>-->
        <br /> <br />
               <div class="hidden-xs">
                 <div class="catHead">Best Seller</div>

                 <div class="card">
                   <img src="../../../assets/img/Organic Vaayu New.jpg" class="card-img-top" alt="..." (click)="navigateTobestSeller()">
                   <!--<img src="../../../assets/img/projects/Vaayu-removebg-preview.png"-->
                   <!--class="card-img-top" alt="..." (click)="navigateTobestSeller()">-->
                   <div class="card-title over">
                     Vaayu
                   </div>
                   <hr />
                   <div class="row">
                     <div class="col-lg-9">
                       <div class="price">
                         ₹ 500.00
                       </div>
                     </div>
                     <!--<div class="col-lg-3" style="text-align:center">
        <div class="itemAdd">
          +
        </div>
      </div>-->
                   </div>
                 </div>

            



               </div>



      </div>

      <div class="col-lg-9 hidden-lg ">
        <div class="row ind_0">
          <!--below div is for showing products regarding selected categories-->
          <div class="col-lg-4 col-xs-6 mbpadd1 " *ngFor="let item of CategoryProducts;let i=index" style="margin-top:-21%">
            <div class="card" (click)="navigatingToProduct(item)">
              <div class=" new">New</div>
              <div class="wshare">
                <span style="cursor:pointer">
                  <a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true" style="color:green;"></i></a>
                </span>
              </div>
              <img src={{HomeURL+item.Image}}
                   class="card-img-top" alt="...">
              <div class="card-title over">
                {{item.productname}}
                <p style="color: darkgrey;"> Yklaboratories</p>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-9">
                  <div class="price">
                    ₹ {{item.Price1}}
                  </div>
                </div>
                <!--<div class="col-lg-3" style="text-align:center">
      <div class="itemAdd">
        +
      </div>
    </div>-->
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row" style="margin-top:3%">
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>-->
      </div>
      <div class="hidden-lg col-xs-6 mbpadd">
        <div class="catHead">Best Seller</div>
        <div class="card">
          <img src="../../../assets/img/Organic Vaayu New.jpg" class="card-img-top" alt="..." (click)="navigateTobestSeller()">
          <!--<img src="../../../assets/img/projects/Vaayu-removebg-preview.png"-->
          <!--class="card-img-top" alt="..." (click)="navigateTobestSeller()">-->
          <div class="card-title over">
            Vaayu
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>
            <!--<div class="col-lg-3" style="text-align:center">
      <div class="itemAdd">
        +
      </div>
    </div>-->
          </div>
        </div>

      </div>
      <div class="col-lg-9 hidden-xs">
        <div class="row">
          <!--below div is for showing products regarding selected categories-->
          <div class="col-lg-4 col-xs-6 " *ngFor="let item of CategoryProducts;let i=index">
            <div class="card" (click)="navigatingToProduct(item)">
                <div class=" new">New</div>
                <div class="wshare">
                  <span style="cursor:pointer">
                    <a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true" style="color:green;"></i></a>
                  </span>
                </div>
              <img src={{HomeURL+item.Image}}
                   class="card-img-top" alt="...">
              <div class="card-title over">
                {{item.productname}}<br />
                <p style="color: darkgrey;"> Yklaboratories</p>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-9">
                  <div class="price">
                    ₹ {{item.Price1}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!--<div class="row" style="margin-top:3%">
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>
            <div class="col-lg-3" style="text-align:center">
              <div class="itemAdd">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>
            <div class="col-lg-3" style="text-align:center">
              <div class="itemAdd">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
               class="card-img-top" alt="...">
          <div class="card-title">
            Max - Size 5lts
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price">
                ₹ 500.00
              </div>
            </div>
            <div class="col-lg-3" style="text-align:center">
              <div class="itemAdd">
                +
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
      </div>

    </div>
  </div>
</div>
<!--this code use for google analyticals-->
<!--
<div _ngcontent-c0="" class="sticky-container1">


      <ul _ngcontent-c0="" class="sticky1" style=" margin-top:200px;">
    <li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32" src="assets/media/call-circle.png" width="32">
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="Tel:9066-179-179">Call Us<br _ngcontent-c0="">Schedule Appointment</a></p>
    </li>-->
<!--<li _ngcontent-c0="">

  <img _ngcontent-c0="" height="32" src="assets/media/youtube-circle.png" width="32" />
  <p _ngcontent-c0="">
    <a _ngcontent-c0="" href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
      Youtube
      <br _ngcontent-c0="">yk mart
    </a>
  </p>
</li>-->
<!--<li _ngcontent-c0="">
  <img _ngcontent-c0="" height="32" src="assets/media/facebook-circle.png" width="32">
  <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br _ngcontent-c0="">Yk mart</a></p>
</li>-->
<!--<li _ngcontent-c0="">
  <img _ngcontent-c0="" height="32" src="assets/media/images (1).jpg" width="32" />
  <p _ngcontent-c0=""><a _ngcontent-c0="" href="" target="_blank">Email<br _ngcontent-c0="">Yk mart</a></p>
</li>-->
<!--<li _ngcontent-c0="">

  <img _ngcontent-c0="" height="32"src="assets/img/download (1).png"width="32"/>
  <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://twitter.com/home" target="_blank">Twitter<br _ngcontent-c0="">KISAN</a></p>
</li>-->
<!--<li _ngcontent-c0="">
    <img _ngcontent-c0="" height="32" src="assets/media/whatsapp.png" width="32" />
    <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br _ngcontent-c0="">Yk mart</a></p>
  </li>
</ul>-->
<!--</div>-->








<style>

 
  .sticky-container1 {
    padding: 0;
    margin: 500px 0 0;
    position: fixed;
    right: -169px;
    top: 230px;
    width: 210px;
    z-index: 1100;
  }

  .sticky1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-top: 20rem;
  }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>
<script>
  function toggleGallery() {
    var gallery = document.getElementById("imageGallery");
    if (gallery.style.display === "none") {
      gallery.style.display = "block";
    } else {
      gallery.style.display = "none";
    }
  }
</script>
