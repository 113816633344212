import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessagesService } from './messages.service';
import { GeneralService } from '../../../service/general.service';
import { Router } from '@angular/router';

import { NotificationService } from '../../../service/notification.service';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MessagesService ]
})
export class MessagesComponent implements OnInit {
  Count: any;
  pendingBookingDetails: any;
  LoginDetails: any;
  public messages:Array<Object>;
  public files:Array<Object>;
  public meetings: Array<Object>;
  orderStatus: any = 1;
  ordersList: any = [];
  notificationCount: number;
  showDetails: boolean = false;
  oder: any;
  ordersList12: any[] = [];
  constructor(private messagesService: MessagesService, private notificationService: NotificationService,public generalService: GeneralService, public router: Router) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    //================Condition for not allowed users without login=================//
    if (localStorage.getItem("LoginDetails") == null) {
      this.router.navigate(['/login']);
    }

    //this.messages = messagesService.getMessages();
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();
    
  }

  ngOnInit() {
    jQuery('#messagesTabs').on('click', '.nav-item a', function(){        
        setTimeout(() => jQuery(this).closest('.dropdown').addClass('show')); 
    })
    this.getAllAdminOrders();
  }
  updateNotificationCount(count: number) {
   
    this.notificationCount = count;
  }



  // Assuming this.ordersList contains the list of orders

  showNotificationDetails() {
    if (this.notificationCount > 0) {
      // Toggle the visibility of the dropdown menu on each click
      this.showDetails = !this.showDetails;

      // If the dropdown is opened, you might want to reset the notification count
      if (this.showDetails) {
        this.notificationCount = 0;
      }
    }
  }

  getAllAdminOrders() {
    var arr = [];
    arr.push({
      TokenID: this.LoginDetails.Token,
      UserId: this.LoginDetails.Id
    });

    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(arr));
    var url = "/api/Products/Get_All_Orders";

    this.generalService.PostData(url, formData).then((data: any) => {
      if (data !== "") {
        const todayDate = new Date(); // Get today's date

        // Filter orders that belong to today's date
        this.ordersList = data.filter((order: any) => {
          const orderDate = new Date(order.OrderDate); // Assuming OrderDate is a valid date string
          const orderDateOnly = orderDate.toISOString().split('T')[0]; // Get date part only

          const todayDateOnly = todayDate.toISOString().split('T')[0]; // Get today's date part

          return orderDateOnly === todayDateOnly;
        });

        // Update notification count after filtering orders
        this.updateNotificationCount(this.ordersList.length);

        // Call showNotification if you want to display a notification here
      }
    });
  }


  // Inside your component

  // Assuming 'ordersList' is an array of orders with 'OrderID' and 'showDetails' properties
   // Assuming 'ordersList' is initialized

  toggleBookingDetails(clickedOrder: any) {
    // Toggle the 'showDetails' property of the clicked order
    clickedOrder.showDetails = !clickedOrder.showDetails;

    // Clear other orders' 'showDetails' property
    this.ordersList12.forEach(order => {
      if (order !== clickedOrder) {
        order.showDetails = false;
      }
    });
  }


  //=========navigation while clicking on Booking details in toggle=======/
  setbookingDetails() {
    debugger
    this.router.navigate(['/Admin/Orders']);
  }

}
