<!----yk mart aboutus from show  the ykmart description and logo-->
<br />
<br />
<div class="container ">

  <nav class="breadcrumb_padd" style="background-color:#fff">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active">About Us</li>
    </ol>
  </nav>
  <br />

  <!----yk mart aboutus from show  the ykmart description and logo-->
    <div class="row">
      <div class="col-lg-8">
        <h4 style="color:#000000"><b>ABOUT</b>&nbsp; <span style="color:#92278f;font-size:28px; "><b>YK</b></span><span style="color:#ed008c;font-size:32px; "><b>mart</b></span></h4>
     
        <p class="abt_txt">
        <p>We YK Laboratories manufacturing of certified organic inputs especially Botanical Virucide, Fungicide water base and
        Botanical Insecticide & Sucking pest oil base, with the aid of Medicinal plants and related alkaloids which controls and
        suppress the multiplication of Bacterial and Fungal growth.</p>
        <p>
          We have developed the capability to match international quality standards stringent user requirements through
          continuous Field trials on my YK Farm Land which we cultivate organically all vegetables and flowers and cereals,
          horticulture crops, located in Hyderabad, INDIA. It improves the product quality and batch to batch consistency and
          reproducibility according to the climatic conditions.
        </p>


      </div>
      <div class="col-lg-4">

        <img src="../../../assets/img/YK Mart .png"  style="width: 118%;margin-left: 29px;margin-top: 176px;" />
      </div>
    </div>
  </div>
<br /><br />
<style>
  .abt_txt {
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 25px;
    text-align: justify;
  }

  .card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    padding: 13px;
  }
</style>

