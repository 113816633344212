import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  EnquiryAry: any = [];
  formGroup: any;
  admin: any;
  TemplateId: any;
  MessageText: any;
  //ApiURL: any;
  ContactUsForm: FormGroup;
  spinner: boolean = false;
  constructor(public generalService: GeneralService, public router: Router, public fb: FormBuilder, public http: HttpClient) {
   
    debugger
 

  }

  ngOnInit() {
    debugger

    this.ContactUsForm = this.fb.group({
      Name: ['', Validators.required],
    
      Mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', Validators.compose([Validators.required, Validators.email])],
      Message: ['', Validators.required]
    });
  }
 
 // When a consumer submits an inquiry, it is forwarded to the administrator( EnquiryMailToAdmin)
  onSubmit(form: any) {
    debugger;
    //this.sendsms();
    this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.Name, Mobile: form.Mobile, Email: form.Email, Message: form.Message })
    var UploadFile = new FormData();
    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    var url = "api/Products/EnquiryMailToAdmin"
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      debugger;
      this.ContactUsForm.reset();
      Swal.fire(
        'SUCCESS',
        'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
        'success'
        
      )
      this.sendsms();
    });

  }

  //onSubmit() {
  //  debugger;
   
  //  var FromMail = 'info@gagri.net';
  //  var FromName='jagan'
  //  var tomail = 'kolasrinivas114@gmail.com';
  //  var toName='bhanu'
  //  var subject = 'testing';
  //  var ContentType = 'this text';
  //  //var mailjson = '';
  //  var HTML_Content = "<html><body><p>This is a test email sent with the <a href=\"https://sendlayer.com\">SendLayer</a> API!</p></body></html>";
  //  var PlainContent = 'This is the plain text email.';
  //  var auth = '64927AD7-773BB6E3-663CC339-AF67D435';
     
  // let mailjson= {
     

  //      "From": {
  //        "name": FromName,
  //        "email": FromMail
  //    },
  //    "To": [
  //      {
  //        "name": toName,
  //        "email": tomail
  //      }
  //    ],
  //   "Subject": subject,
  //   "ContentType": ContentType,
  //   "HTMLContent": HTML_Content,
  //   "PlainContent": PlainContent,
  //   "auth": auth,
  //              "Tags": [
  //                "newsletter",
  //                "daily"
  //              ],
  //                "Headers": {
  //      "X-Mailer": "Test mailer",
  //                  "X-Test": "test header"

  //    }
  //  }





  //  this.http.post('https://console.sendlayer.com/api/v1/email', mailjson
  //  ).subscribe(data => {
  //  //var url = 'https://console.sendlayer.com/api/v1/email' ;
  //  // var url = '\"https://sendlayer.com\"' + mailjson;
  //  //this.http.post(url, mailjson).then(obj => {
  //   // this.generalService.PostData(url,mailjson).then((data: any) => {
  //    //UploadFile.append("userdata", JSON.stringify(arr));
  //    //this.http.post(url, UploadFile).subscribe((data: any) => {
   
  //    debugger;
  //    this.ContactUsForm.reset();
  //    Swal.fire(
  //      'SUCCESS',
  //      'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
  //      'success'

  //    )
  //    this.sendsms();
  //  });

  //}
  sendsms() {
    debugger
    this.admin = 9866041417;
    this.TemplateId = 1207164812642544439;
    this.MessageText = "Thank you for your enquiry, we will contact you soon Company mobile number " + this.admin + " Ykmart ";
    var url = 'api/Products/raisenquiry_sendsms_customer';

    let formdata = new FormData();
    formdata.append("admin", this.admin)
    formdata.append("Mobile", this.EnquiryAry[0].Mobile)  
    formdata.append("SMS", this.MessageText)
    formdata.append("TemplateId", this.TemplateId)
    this.generalService.PostData(url, formdata).then((data: any) => {
    })
  }

 
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
