import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
declare var $;
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  customerWishlistItems: any=[];
  FarmingProduces: any;
  Goals: any;
  currentCartItems: any=[];
  customerCartItems: any=[];
  wishlistItemsCount: any;
  logedinUser: string;
  LoginDetails: any;
  cartItemsCount: any;
  HomeURL: any;
  carDropdown: any;
  Categories: any;
  selectedProduct: any;
  public router: Router;

  searchedProducts: any;
  value1: any //="Shop By Products";
  Producttoggle: boolean = false;
  constructor(public generalService: GeneralService, public http: HttpClient, router: Router,
              public cartService: CartService, public wishlistService: WishlistService) {
    this.router = router;

    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    if (this.LoginDetails) { 
      this.getCartItemsListbasedonUserId(this.LoginDetails.Id)
      this.getWishListItemsListbasedonUserId(this.LoginDetails.Id)
    }
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.GetGoals()
    this.GetCategoryforHeader()
    this.GetFarmingProduces()
    this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
      this.cartItemsCount = res.length
    })
    this.wishlistService.getProducts().subscribe(res => {/*getting wish list count from wishlist service*/      
      this.wishlistItemsCount = res.length
    })
    window.onscroll = function () { myFunction() };

    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }

  /**
   * cart count return function
   */
  countReturn() {
    this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
      
      this.cartItemsCount = res.length
      return this.cartItemsCount
    })
    return this.cartItemsCount
  }
  /*
* Below method is used to get the Category with images from Server with reference of database path
*/
  GetCategoryforHeader() {
    let arr = [];
    arr.push({ UserId: 0, TokenID: null })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetProductCategories"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Categories = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }


  /**
   * Navigating to selected category in header page
   */
  navigateToCategoryProducts(data) {
      debugger
    var carName = ""; var catarr=[]
    this.ngOnInit()
    catarr = this.Categories.filter(A => A.CategoryName == data)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      carName += "_"+str[i];
    }
    this.generalService.setCategoryid(catarr)
    this.router.navigate(['/Category/' + carName]);
  }

  /**
  filtering products method
   **/
  filterProducts(event) {
    debugger
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let query = event.query;
    if (event.query.length > 2) {
      var UploadFile = new FormData()
      UploadFile.append("productName", query)
      var url = "/api/Products/GetAll_SearchingProducts"
      this.generalService.PostData(url, UploadFile).then(data => {
        this.searchedProducts = data
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    } 
  }

  /*navigating to customer search products*/
  navigatingToSearchedProduct(data) {
    debugger
    var prodName = ""; var prodarr = []
    prodarr.push(data)
    var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.generalService.setProducts(prodarr)
    if (data.SpecialityId == 0) {
      this.router.navigate(['/Product/' + prodName]);
    }
    else {
      this.router.navigate(['/EnquiryProducts/' + prodName])
    }
  }

  /**
   * below method is for getting loged in user name
   */
  logedin() {
    this.logedinUser = localStorage.getItem('token');
    return this.logedinUser;
  }

  /*Below method will call at the time of user clicks on logout*/
  logout() {
    this.cartService.removeAllcart();
    this.wishlistService.removeAllWishlistItems();
    this.router.navigate(['/CustomerLogin']);
  }

  /*method is for getting customer cart items based on the user id*/
  getCartItemsListbasedonUserId(userid) {
    this.customerCartItems = []
    this.currentCartItems = []
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    var url = "api/Products/GetAllCartItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {      
      this.cartService.removeAllcart();
      if (data != null) {        
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerCartItems.push(data[k])
          this.cartService.addtoCart(this.customerCartItems[k])
        }
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });
  }

  /*method is for getting customer cart items based on the user id*/
  getWishListItemsListbasedonUserId(userid) {
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    var url = "api/Products/GetAllWishListItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {      
      this.wishlistService.removeAllWishlistItems();
      if (data != null) {        
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerWishlistItems.push(data[k])
          this.wishlistService.setProducts(this.customerWishlistItems[k])
        }
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });
  }

  /*
* Below method is used to get the Goals list for left side menu 
*/
  GetGoals() {
    var url = "/api/Products/GetGoals"
    this.generalService.GetData(url).then(data => {
      this.Goals = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  
  /**
   * Navigating to selected Goal in header page
   */
  navigateToGoalProducts(data) {
    debugger
    var carName = ""; var catarr = []
    this.ngOnInit()
    catarr = this.Goals.filter(A => A.GoalName == data)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      carName += "_" + str[i];
    }
    this.generalService.setCategoryid(catarr)
    this.router.navigate(['/Goals/' + carName]);
  }

  /**
 * Navigating to selected Goal in header page
 */
  navigateToFarmingProducts(data) {
    debugger
    var carName = ""; var catarr = []
    this.ngOnInit()
    catarr = this.FarmingProduces.filter(A => A.SpecialityName == data)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      carName += "_" + str[i];
    }
    this.generalService.setCategoryid(catarr)
    this.router.navigate(['/FarmerProduces/' + carName]);
  }

  /*
* Below method is used to get the Goals list for left side menu 
*/
  GetFarmingProduces() {
    var url = "/api/Products/GetFarmingProducesMaster"
    this.generalService.GetData(url).then(data => {
      this.FarmingProduces = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
}
