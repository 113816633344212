<!--<div class="container">
  <div id="print-section" style="width:100%; align-content:center">
    <table style="width:100%;background-color: #fff;">
      <tr>
        <td style="text-align: center;">
          <img src="../../../assets/img/YK Mart .png" style="width:20% !important;" alt="Logo">
        </td>
      </tr>
      <tr>
        <td style=" text-align: center !important">
          <div class="strg">YKMART</div>
          <div style=" text-align: center !important">Herbal House</div>
          <div class="strg_c">
            YK Laboratories, Kuntloor.
          </div>
        </td>
      </tr>
      <div style="border:none !important">
        <td>
          <table style="width:100%;">
            <tr style="border:none !important;">
              <td class="td_class">
                <div>Order Id</div>
                <div>Order Date </div>
                <div>Bill Id</div>
                <div>Invoice Date</div>
                <div>Invoice Time</div>
              </td>

              <td class="td_class_1">
                <div>{{orderNo}}</div>
                <div>{{orderDate | date:'dd-MMM-yyyy'}}</div>
                <div>IN{{billNo}}</div>
                <div>{{orderDate | date:'dd-MMM-yyyy'}}</div>
                <div>{{orderDate | date:'hh:mm'}}</div>
              </td>
            </tr>
          </table>
        </td>
      </div>
      <tr>
        <td style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
    font-size: 15px;
    color: #000;
    font-weight: bold;
    letter-spacing: 0.5px;">
              <td style="width:30%;">Item</td>
              <td style="width:12%;text-align:right">Size</td>
              <td style="width:8%;text-align:right">Qty</td>
              <td style="width:25%;text-align:right">MRP (₹)</td>
              <td style="width:25%;padding:0;text-align:right">T.Amt (₹)</td>

            </tr>

            <tr  *ngFor="let item1 of orderDetails;let i=index" style="font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;">
              <td style="width:30%;">{{item1.ProductName}}</td>
              <td style="width:12%;text-align:right">{{item1.Size}}</td>
              <td style="width:8%;text-align:right">{{item1.Quantity}}</td>
              <td style="width:25%; text-align:right">{{item1.Price |  currency:'INR'}}</td>
              <td style="width:25%;padding:0; text-align:right">{{item1.Quantity*item1.Price|  currency:'INR'}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="Item" colspan="5" style="text-align:right">
          <div class="td_class_1" style="font-size:16px;font-weight:bold;"> Grand Total :{{grandTotal|  currency:'INR'}}</div>

        </td>
      </tr>


      <tfoot>
        <tr>
          <td class="Item" colspan="5" style="text-align:center">
            <span class="strg"> {{orderDetails[0].FarmerName}} - {{orderDetails[0].MobileNo}} </span>
            <br />
            {{orderDetails[0].HNo}},{{orderDetails[0].Landmark}},{{orderDetails[0].Area}},
            <br />
            {{orderDetails[0].City}},{{orderDetails[0].State}}- {{orderDetails[0].Pincode}}
          </td>
        </tr>
        <tr>
          <td class="Item" colspan="5" style="text-align:center">
            Thanks for your purchase!
            <br />
            <span class="strg">Head Office </span>
            <br />
            YK Laboratories,Herbal House, road no 3,servey no 255, Kuntloor, Telangana 501505
            <br />
            Customer Care: +91 9066179179
          </td>
        </tr>
      </tfoot>
    </table>
    <p style="text-align:right">
      <br />
      <button type="submit" class="btn btn-primary hidden-print" (click)="print()">Print</button>
      &nbsp;
      <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Cancel</button>
    </p>
    <script src="script.js"></script>
  </div>
</div>-->
<div class="container">
 
  <div id="print-section" style="width:100%; ">
    <table style="width:100%;background-color: #fff;">
      <tr>

        <td style="text-align:center;">
          <img src="../../../assets/img/IMG-20220210-WA0047.jpg" style="width:20% !important;" alt="Logo">
        </td>
      </tr>
      <tr>
        <td>
          <div class="back_0">
            <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Back</button>
          </div>
        </td>
      </tr>
      <tr>
        <td style="text-align: center;">
          <div class="strg"> Tax Invoice</div>
          <!--<img src="../../../../../assets/img/app/VTlogo.png" style="width:20% !important;" alt="Logo">-->
        </td>
      </tr>
      <tr>
        <td class="Item" colspan="5" style="text-align:left">
          <div class="col-lg-6">
            <b class="strg">Bill To:</b><br />
            <span class="strg"> {{orderDetails[0].FarmerName}} - {{orderDetails[0].MobileNo}} </span>
            <br />
            {{orderDetails[0].HNo}}, {{orderDetails[0].Landmark}}, {{orderDetails[0].Area}},
            <br />
            {{orderDetails[0].City}}, {{orderDetails[0].State}} - {{orderDetails[0].Pincode}}<br />
            <!--{{GSTNUMBER}}-->
            

          </div>

          <div class="col-lg-6">

            <div class="strg" style=" text-align: Right !important">YK mart</div>
            <div style=" text-align: Right !important">
              Herbal House, road no 3,servey no 255, Kuntloor
            </div>
            <div style=" text-align: Right !important">
              Telangana
            </div>
          </div>
          <!--<div class="strg" style=" text-align: Right !important">
            GSTIN : 36AHWPM4614R2ZC
          </div>-->
        </td>
      </tr>
      <tr>
        <td>
          <div style="border:none !important">
            <table style="width:40%;margin-left: 60%;">
              <tr style="border:none !important; ">
                <td class="td_class1"> <span class="strg">Order Id:</span></td>
                <td><span class="ordstrn"> {{orderNo}}</span></td>
              </tr>
              
              <tr>
                <td><span class="strg">Order Date:</span></td>
                <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
              </tr>
            </table>
            <table style="width:40%; margin-top: -90px;">
              <tr>
                <td><span class="strg">Invoice No:</span></td>
                <td><span class="strg"><span class="ordstrn">IN{{billNo}}</span></span></td>
              </tr>
              <tr>
                <td><span class="strg">Invoice Date:</span></td>
                <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
              </tr>
              <!--<tr>
                <td><span class="strg">Invoice Time:</span></td>
                <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'hh:mm a'}}</span></span></td>
              </tr>-->
            </table>


          </div>

        </td>
      </tr>
      <tr>
        <td class="Item" style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
    font-size: 15px;
    color: #000;
    font-weight: bold;
    letter-spacing: 0.5px;">
              <td style="width:5%; ">S No</td>
              <!--<td style="width:50%;">Item</td>
  <td style="width:12%;text-align:right">Size</td>
  <td style="width:8%;text-align:right">Qty</td>
  <td style="width:10%;text-align:right">MRP (₹)</td>
  <td style="width:50%;padding:0;text-align:right">T.Amt (₹)</td>-->
              <td style="width:15%;">Description</td>
              <td style="width:3%; text-align:center">HSN</td>
              <td style="width:10%;text-align:right">DocketNumber</td>
              <td style="width:4%; text-align:right">Qty</td>
              <td style="width:10%;text-align:right">units</td>
              <td style="width:10%;text-align:right">Base Price(₹)</td>
              <td style="width:10%;text-align:right">GST Rate</td>
              <td style="width:10%;text-align:right">GST Amount</td>
              <td style="width:10%;text-align:right">Amount</td>


            </tr>

            <tr *ngFor="let item1 of orderDetails;let i=index" style="font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;">
              <td style="width:5%;">{{i+1}}</td>
              <td style="width:15%;">{{item1.ProductName}}</td>
              <td style="width:6%;">{{item1.HSN}}</td>
              <td style="width:10%;text-align:right">{{item1.DocketNumber }}</td>
              <td style="width:4%; text-align:right">{{item1.Quantity}}</td>
              <td style="width:10%;text-align:right">{{item1.Size}}</td>
              <td style="width:15%;">{{(item1.Price-item1.GstPrice ) |  currency:'INR'}}</td>
              <td style="width:10%;text-align:right">{{item1.GST}}%</td>
              <td style="width:10%;">₹{{item1.GstPrice}}.00</td>
              <td style="width:10%;text-align:right">{{item1.Price |  currency:'INR'}}</td>




            </tr>
          </table>
        </td>
      </tr>
      <tr>



        <td>
          <!--<div class="strg" style=" text-align: Right !important"> Total MRP:  {{grandTotal |  currency:'INR'}}</div>-->
          <!-- <div class="strg" style=" text-align: Right !important"> Total TIGST:  {{TIGST |  currency:'INR'}}</div>-->
          <!--<div class="strg" style=" text-align: Right !important">   Total CGST :      {{TCGST |  currency:'INR'}}</div>
          <div class="strg" style=" text-align: Right !important"> Total SGST :      {{TSGST |  currency:'INR'}}</div>
          <div class="strg" style=" text-align: Right !important"> Total CESS :       {{TCESS |  currency:'INR'}}</div>
          <div class="strg" style=" text-align: Right !important"> Round Off  :       {{Roundoff |  currency:'INR'}}</div>-->
          <!--<div class="td_class_1" style="font-size:16px;font-weight:bold;"> Grand Total :  {{TotalOrderAmount |  currency:'INR'}}

          </div>-->


        </td>
      </tr>
      <tr>
        <td style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins'; font-size: 15px; color: #000; font-weight: bold;letter-spacing: 0.5px;">
              <td>
                <div class="strg" style=" text-align: Right !important">Actual Price:  {{grandTotal+offerAmount-deliveryCharge |  currency:'INR'}}</div>
                <div class="strg" style=" text-align: Right !important;"> Offer Amount:  {{offerAmount |  currency:'INR'}}</div>
                <div class="strg" style=" text-align: Right !important;"> Delivery Charge:  {{deliveryCharge |  currency:'INR'}}</div>
                <div class="strg" style=" text-align: Right !important"> Total Amount:  {{grandTotal |  currency:'INR'}}</div>
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <!--<tr>
        <td>
          <div style="text-align:left;font-size:14px;">
            <b>
              HSN Summary
            </b>
          </div>
        </td>
      </tr>
      <tr>
        <td style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
      font-size: 15px;
      color: #000;
      font-weight: bold;
      letter-spacing: 0.5px;">

              <td style="width:10%;">HSN/SAC</td>
              <td style="width:15%;text-align:right">Taxble Value</td>
              <td style="width:10%;text-align:right">CGST Rate</td>
              <td style="width:10%;text-align:right">CGST Amount</td>
              <td style="width:10%; text-align:right">SGST Rate</td>
              <td style="width:10%; text-align:right">SGST Amount</td>
              <td style="width:10%;text-align:right">CESS Rate</td>
              <td style="width:10%; text-align:right">CESS Amount</td>
              <td style="width:15%;text-align:right">Total</td>

            </tr>

            <tr *ngFor="let item2 of OrderDetails1;let i=index" style="font-family: Arial;
      font-size: 15px;
      color: #000;
      font-weight: normal;
      letter-spacing: 0.5px;">
              <td style="width:10%;">{{item2.HSNTotal}}</td>
              <td style="width:15%; text-align:right">{{item2.MrpPrice |  currency:'INR'}}</td>
              <td style="width:10%; text-align:right">{{item2.HCGSTPERC }}</td>
              <td style="width:10%; text-align:right">{{item2.HCGST  |  currency:'INR'}}</td>
              <td style="width:10%; text-align:right">{{item2.HSGSTPERC }}</td>
              <td style="width:10%; text-align:right">{{item2.HCGST  |  currency:'INR'}}</td>
              <td style="width:10%; text-align:right">{{item2.HCESSPERC}}</td>
              <td style="width:10%; text-align:right">{{item2.HCESS |  currency:'INR'}}</td>
              <td style="width:15%; text-align:right">{{(((item2.MrpPrice*item2.Quantity)+(item2.HCGST+item2.HCGST+item2.HCESS))- (item2.MrpPrice))|  currency:'INR'}}</td>

            </tr>
          </table>
        </td>
      </tr>

      <tr>
        <td style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
      font-size: 15px;
      color: #000;
      font-weight: bold;
      letter-spacing: 0.5px;">
              <td style="width:10%;">Total</td>
              <td style="width:15%;text-align:right">{{TotalMRP |  currency:'INR'}}</td>
              <td style="width:10%;text-align:right"></td>
              <td style="width:10%;text-align:right">{{TCGST |  currency:'INR'}}</td>
              <td style="width:10%;text-align:right"></td>
              <td style="width:10%;text-align:right">{{TSGST |  currency:'INR'}}</td>
              <td style="width:10%;text-align:right"></td>
              <td style="width:10%;text-align:right">{{TCESS |  currency:'INR'}}</td>
              <td style="width:15%;text-align:right">{{TotalTax |  currency:'INR'}}</td>


            </tr>


          </table>
        </td>
      </tr>
      <tr>
        <td>
          <div class="row" style="font-size:14px;">
            <div class="col-lg-8">
              We declare that this invoice shows the actual price of the goods described and that all<br />
              particulars are true and correct.
            </div>
            <div class="col-lg-4" style="text-align:right;">
              Authorized Signatory
            </div>
            <P style="text-align:center;">
              This is computer generated invoice
            </P>


          </div>
        </td>
      </tr>-->
      <tr>
        <td>
          <div style="text-align:left;">
            We declare that this invoice shows the actual price of the goods described and that all<br />
            particulars are true and correct.
          </div>
          <div style="text-align:right;">
            Authorized Signatory
          </div>
        </td>
      </tr>
      <tr><td style="text-align:center;"> This is computer generated invoice</td></tr>
      <tfoot>



      </tfoot>
    </table>
    <p style="text-align:right">
      <br />
      <button type="submit" class="btn btn-primary hidden-print" (click)="print()">Print</button>
      &nbsp;
      <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Cancel</button>
    </p>

    <script src="script.js"></script>
  </div>
</div>
<!--this code use for google analyticals-->
<div class="sticky-container1">
  <ul class="sticky1" style=" margin-top:200px;">
    <li>

      <img height="32" src="assets/media/call-circle.png" width="32">
      <p><a href="Tel:9066-179-179">Call Us<br>Schedule Appointment</a></p>
    </li>


    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32" src="assets/media/youtube-circle.png" width="32" />
      <p _ngcontent-c0="">
        <a _ngcontent-c0="" href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
          Youtube
          <br _ngcontent-c0="">yk mart
        </a>
      </p>
    </li>-->
    <!--<li _ngcontent-c0="">
      <img _ngcontent-c0="" height="32" src="assets/media/facebook-circle.png" width="32">
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br _ngcontent-c0="">Yk mart</a></p>
    </li>-->
    <li>
      <img height="32" src="assets/media/images (1).jpg" width="32" />
      <p><a href="" target="_blank">Email<br>Yk mart</a></p>
    </li>
    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32"src="assets/img/download (1).png"width="32"/>
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://twitter.com/home" target="_blank">Twitter<br _ngcontent-c0="">KISAN</a></p>
    </li>-->

    <li>
      <img height="32" src="assets/media/whatsapp.png" width="32" />
      <p><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br>Yk mart</a></p>
    </li>
  </ul>
</div>








<style>


  .sticky-container1 {
    padding: 0;
    margin: 500px 0 0;
    position: fixed;
    right: -169px;
    top: 230px;
    width: 210px;
    z-index: 1100;
  }

  .sticky1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-top: 20rem;
  }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>

<style>
  .modal-dialog {
    max-width: 60% !important;
    margin: 1.75rem auto !important;
  }

  .card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
  }

  .custom.tabs-primary {
    background-color: rgba(62, 80, 180, 0) !important;
    border: 1px solid #ffffff !important;
    COLOR: #000 !important;
  }

  .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
  }

    .nav-item .nav-link.active {
      background-color: #ffffff !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

    .nav-item .nav-link:hover {
      background-color: #9ed9f7 !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

  .HideDescription {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .strg_c {
    font-family: poppins;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    line-height: 1.8;
    text-align: center;
    padding-top: 0;
  }

  .strg {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 0.5rem
  }

  .ordstrn {
    font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  #plugin {
    height: 30% !important;
    position: absolute;
    width: 100%;
  }
</style>

