<!--<nav class="breadcrumb_padd" style="background-color:#fff;margin-top:2px">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active"> Categories</li>
  </ol>
</nav>-->
<div class="container-fluid farm_0">
  <div class="container"><br /> 
    <app-breadcrumb></app-breadcrumb>
    <div class="row">
      <div class="col-lg-3">
        <div class="hidden-xs">
          <div class="catHead">Farming produces </div>

          <div *ngFor="let item of Specialities;let i=index">
            <div [class]="(SpecialityName === item.SpecialityName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideSpecialities(item.Id,item.SpecialityName)">
              {{item.SpecialityName}}    
            </div>
          </div>
        </div>
        <div class="hidden-lg">
          <div class="catHead">Categories </div>
          <div style="overflow:overlay;">

            <ul>
              <li class="catHeader" *ngFor="let item of Specialities">
                <span [class]="(SpecialityName === item.SpecialityName) ? 'activeCategory' : 'catHeader'" (click)="onclickmethodforLeftsideSpecialities(item.Id,item.SpecialityName)">
                  {{item.SpecialityName}}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <br /> <br />
        <div class="hidden-xs">
          <div class="catHead">Best Seller</div>
          <div class="card">
            <img src="../../../assets/img/Organic Vaayu New.jpg" class="card-img-top" alt="..." (click)="navigateTobestSeller()">
            <div class="card-title over"style="text-align:center;">
              Vaayu
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-9">
                <div class="price"style="text-align:center;">
                  ₹ 500.00
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-9">
        <div class="row roww">
          <!--below div is for showing products regarding selected categories-->

          <div class="col-lg-4  col-xs-6 mbpadd1" *ngFor="let item of FarmerProducts;let i=index">
            <div class="card carda" (click)="navigatingToProduct(item)">
              <img src={{HomeURL+item.Image}}
                   class="card-img-top imgMobile" alt="...">
              <div class="card-title"style="text-align:center;">
                {{item.productname}}
              </div>
              <hr />
              <!--<div class="row">
              <div class="col-lg-9">
                <div class="price">
                  ₹ {{item.Price1}}
                </div>
              </div>
              <div class="col-lg-3" style="text-align:center">
                <div class="itemAdd">
                  +
                </div>
              </div>
            </div>-->
            </div>
          </div>



        </div>
        <!--<div class="row" style="margin-top:3%">
        <div class="col-lg-4">
          <div class="card">
            <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
                 class="card-img-top" alt="...">
            <div class="card-title">
              Max - Size 5lts
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-9">
                <div class="price">
                  ₹ 500.00
                </div>
              </div>
              <div class="col-lg-3" style="text-align:center">
                <div class="itemAdd">
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
                 class="card-img-top" alt="...">
            <div class="card-title">
              Max - Size 5lts
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-9">
                <div class="price">
                  ₹ 500.00
                </div>
              </div>
              <div class="col-lg-3" style="text-align:center">
                <div class="itemAdd">
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <img src="https://images-eu.ssl-images-amazon.com/images/I/71dL9b7NH3L._AC._SR360,460.jpg"
                 class="card-img-top" alt="...">
            <div class="card-title">
              Max - Size 5lts
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-9">
                <div class="price">
                  ₹ 500.00
                </div>
              </div>
              <div class="col-lg-3" style="text-align:center">
                <div class="itemAdd">
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      </div>
      <!--<div>-->

      <br />
      <div class="hidden-lg col-xs-6 mbpadd">
        <p>Best Seller:</p>
        <div class="card">
          <img (click)="navigateTobestSeller()" src="../../../assets/img/Organic Vaayu New.jpg"
               class="card-img-top card-img-top1 imgMobile" alt="...">
          <div class="card-title"style="text-align:center;">
            Vaayu
          </div>
          <hr />
          <div class="row">
            <div class="col-lg-9">
              <div class="price"style="text-align:center;">
                ₹ 500.00
              </div>
            </div>
            <!--<div class="col-lg-3" style="text-align:center">
            <div class="itemAdd">
              +
            </div>
          </div>-->
          </div>
        </div>
      </div>
      <!--</div>-->
    </div>

  </div>
</div>
<!--this code use for google analyticals-->
<div  class="sticky-container1">
  <ul class="sticky1" style=" margin-top:200px;">
    <li>

      <img height="32" src="assets/media/call-circle.png" width="32">
      <p><a href="Tel:9066-179-179">Call Us<br>Schedule Appointment</a></p>
    </li>


    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32" src="assets/media/youtube-circle.png" width="32" />
      <p _ngcontent-c0="">
        <a _ngcontent-c0="" href="https://www.youtube.com/channel/UCVQU8xiEV_Cs_cy4cql629A" target="_blank">
          Youtube
          <br _ngcontent-c0="">yk mart
        </a>
      </p>
    </li>-->
    <!--<li _ngcontent-c0="">
      <img _ngcontent-c0="" height="32" src="assets/media/facebook-circle.png" width="32">
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://www.facebook.com/Agrobinecommercpvtltd-104663955241780" target="_blank">Facebook<br _ngcontent-c0="">Yk mart</a></p>
    </li>-->
    <li>
      <img height="32" src="assets/media/images (1).jpg" width="32" />
      <p><a href="" target="_blank">Email<br>Yk mart</a></p>
    </li>
    <!--<li _ngcontent-c0="">

      <img _ngcontent-c0="" height="32"src="assets/img/download (1).png"width="32"/>
      <p _ngcontent-c0=""><a _ngcontent-c0="" href="https://twitter.com/home" target="_blank">Twitter<br _ngcontent-c0="">KISAN</a></p>
    </li>-->

    <li>
      <img height="32" src="assets/media/whatsapp.png" width="32" />
      <p><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br>Yk mart</a></p>
    </li>
  </ul>
</div>








<style>


  .sticky-container1 {
    padding: 0;
    margin: 500px 0 0;
    position: fixed;
    right: -169px;
    top: 230px;
    width: 210px;
    z-index: 1100;
  }

  .sticky1 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-top: 20rem;
  }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>
