import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CookieService } from 'ngx-cookie-service';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
declare var $;
import { EncryptionService } from '../../service/encryption.service'


@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerLoginComponent {
  customerWishlistItems: any=[];
  CartItems: any=[];
  itemSize: string;
  ItemId: number;
  public router: Router;
  public form: FormGroup;
  public email: AbstractControl;
  public password: AbstractControl;
  logindata: any;
  passwordshow: boolean = true;
  ResetForm: boolean;
  Loginform: boolean;
  MobileNo: string;
  Mobile: any;
  OTP: string;
  Pwd: string;
  Confirmpassword: string;
  randomnumber: any;
  customerCartItems: any = []
  currentCartItems: any = []
  CheckBox: string;
  Remember: boolean;
  cookieEmailValue: string;
  cookiePWDValue: string;
  logindetails: any;
  RoleID: any;

  constructor(router: Router, fb: FormBuilder, public generalService: GeneralService, public encryptionService: EncryptionService,
    public cartService: CartService, private cookieService: CookieService, 
    public wishlistService: WishlistService) {
    localStorage.removeItem("LoginDetails");
    localStorage.removeItem("token");
    var encryptedData = localStorage.getItem('LoginDetails');
    if (encryptedData != null || encryptedData != undefined) {
      this.logindetails = JSON.parse(this.encryptionService.decrypt(encryptedData));
    }
    this.router = router;
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

  //  this.email = this.form.controls['email'];
  //  this.password = this.form.controls['password'];
  //}
   this.email = this.form.controls['email'];
this.password = this.form.controls['password'];
const cookieExists: boolean = cookieService.check('RememberMe');
if (cookieExists) {
  this.cookieEmailValue = this.cookieService.get('email');
  this.cookiePWDValue = this.cookieService.get('password');

  this.form.controls.email.setValue(this.cookieEmailValue)
  this.form.controls.password.setValue(this.cookiePWDValue)
  this.Remember = true;

}
else {
  this.form.reset();
}

    }

  //This code customer login page you have previously registered to input your cellphone number and password.
  //If you do not register, the page will display the(NOT EXIST) alert message.
  public onSubmit(values: any): void {
    debugger
    if (this.form.valid) {
      var url = "api/Products/Login";
      var UploadFile = new FormData();
      UploadFile.append("MobileNo", values.email);
      UploadFile.append("Password", values.password);
     // UploadFile.append("RoleID", '3');
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
        if (data != null) {
          if (data == "NOT EXIST") {
            this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
          }
          else {            
            if (data[0].LoginType == 4) {
              /*Below method is calling to get custsomer cart items*/
              this.getCartItemsListbasedonUserId(data[0].Id)
              this.getWishListItemsListbasedonUserId(data[0].Id)
              this.router.navigate(['/Home']);
              localStorage.setItem("token", data[0].FarmerName);
              localStorage.setItem("LoginDetails", JSON.stringify(data[0]));
              
            }
            else {
              this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials/Customer Cannot Login Into Web', 'warning');
            }
          }
        }
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
      });
    }

  }
  /*Show passwords*/
  ShowPassword(id) {
    debugger;
    var x;
    x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      this.passwordshow = false;
    } else {
      x.type = "password";
      this.passwordshow = true;
    }

  }
  /*end code*/
  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }


 
  /*end code*/
  signOut() {
    sessionStorage.removeItem("LoginDetails");
    this.router.navigate['/Home']
  }

  //openResetpassword
  OpenResetPwd() {
    this.ResetForm = true;
    this.Loginform = false;
  }
 
  /*forgot password*/
  ForgotPassword() {
    this.MobileNo = "";
    this.OTP = "";
    this.Pwd = "";
    this.Confirmpassword = "";
    $('#myModal2').modal('show');
  }
  /*end code*/
  //Rememberb password
  RememberMe(ev) {
    debugger
    if (ev.target.checked == true) {
      this.Remember = true;
      this.cookieService.set('email', this.form.value.email);
      this.cookieService.set('password', this.form.value.password);
      this.cookieService.set('RememberMe', 'Checked');
      this.CheckBox = 'Checked'
    }
    else if (ev.target.checked == false) {
      // this.cookieService.set('RememberMe', 'UnChecked');
      this.CheckBox = ''
      this.Remember = false;
      this.cookieService.deleteAll();

    }
  }
  /*Check mobile there or not*/
  CheckMobile(MobileNo) {
    debugger;
    if (MobileNo == undefined || MobileNo == "" || MobileNo == null) {
      debugger;
      alert("Please Enter Mobile Number")
      // this.openToast();
    }
    else {
      var UploadFile = new FormData();
      UploadFile.append("MobileNo", MobileNo);
      UploadFile.append("Flag", "1");
      var url = "api/Products/CheckMobileandEmail"
      debugger;
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data == 'EXIST') {
          this.OTP = ""
          this.MobileNo = MobileNo;
          this.SendOtpForMobile();
          $('#myModal2').modal('hide');
          $('#myModal3').modal('show');
        }
        else {

          alert("Please Enter Valid Mobile Number")
        }
      }, err => {      
      });
    }

  }
  /*end code*/

  /* send otp for registration */
  SendOtpForMobile() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    var msg = "Your Customer OTP is:" + this.randomnumber + " YKmart";
   var UploadFile = new FormData();
    UploadFile.append("MobileNo", this.MobileNo);
    UploadFile.append("SMS", msg);
    UploadFile.append("OTP", this.randomnumber);
    var url = 'api/Products/SendOtpToMobile';
    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {

      }
      else {
       
      }
    });
  }
  /*end code*/
  /*keep me signed in*/
  keepmesignin(e) {
    debugger;
    var a = e.target.checked;
    if (a == true) {
      localStorage.setItem("keepsignin", a);
    }
    else {
      localStorage.removeItem("keepsignin");
    }
  }

  /*verifyotp for forgotpassword*/
  VerifyOtp(otp) {
    debugger
    if (this.randomnumber = otp) {
      $('#myModal3').modal('hide');
      $('#myModal4').modal('show');
    }
    else {
      alert("Invalid OTP");
    }
  }
  /*end region*/

  /*Change password*/
  ChangePassword(password, confirmpassword) {
    if (password == undefined || password == null || password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
      alert("Please enter password");
    }
    else {
      if (password != confirmpassword) {
        alert("password and confirmpassword must be same");
      }
      else {
        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.MobileNo);
        UploadFile.append("Password", password);
        var url = "api/Products/Forgot_Password"
        debugger;
        this.generalService.PostData(url, UploadFile).then(data => {
          debugger;
          if (data == 'SUCCESS') {
            this.generalService.ShowAlert('SUCCESS', 'Change Password Successfully.', 'success')
            $('#myModal4').modal('hide');
          }
        }, err => {
          $('#myModal4').modal('hide');
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
      }
    }
  }
  /*endregion*/

  /*method is for getting customer cart items based on the user id*/
  getCartItemsListbasedonUserId(userid) {    
    this.customerCartItems = []
    this.CartItems = [];
    this.currentCartItems = []
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    this.cartService.getProducts().subscribe(res => {
      this.currentCartItems = res;
    })
    var url = "api/Products/GetAllCartItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {        
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerCartItems.push(data[k])
          this.cartService.addtoCart(data[k])
        }        
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
      });

    if (this.customerCartItems.length == 0 && this.currentCartItems.length >= 1) {
      this.insertCartItems(userid) /*we need to insert the new cart items when user added in without login*/
    }
  }

  /*method is for getting customer wishList items based on the user id*/
  getWishListItemsListbasedonUserId(userid) {
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    var url = "api/Products/GetAllWishListItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.wishlistService.removeAllWishlistItems();
      if (data != null) {
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerWishlistItems.push(data[k])
          this.wishlistService.setProducts(this.customerWishlistItems[k])
        }
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });
  }

 /*
  Below method is for Inserting customer cart items
  */
  insertCartItems(userid) {        
    for (var m = 0; m < this.currentCartItems.length; m++) {
      this.currentCartItems[m].UserId = String(userid)
    } 
    var url = "api/Products/Insert_Cart_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(this.currentCartItems));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.cartService.removeAllcart();
      for (var k = 0; k < data.length; k++) {
        this.cartService.addtoCart(data[k])
      }  
    })
  }
  
}
