import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../service/general.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Local } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {
  GoalName: any;
  HomeURL: any;
  GoalProducts: any;
  selectedGoal = [];
  Goals: any;
  CategoryData: any;
  goalId: any;
  public router: Router;
  constructor(public generalService: GeneralService, public http: HttpClient, router: Router, public activeroute: ActivatedRoute) {
    /*below categry name is invoked from the above url from the browser in that '-' is replaced with " " and trim for whaite spaces*/
    this.GoalName = this.activeroute.snapshot.paramMap.get('Name').replace(/\_/g, " ").trim();
    this.router = router
    /*By using below service we can get the category id from page header*/
    this.generalService.getCategoryid().subscribe(res => {
      if (res.length == 0) {//if category id is null
        this.GetCategoryforfiltercatidWhilecopyURL()
      }
      else {//it will execute when we select header Goals
        this.goalId = res[0].Id
        this.GoalName = res[0].GoalName;
        this.getGoalBasedProductsBasedongoalId()
      }

    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }
  ngOnInit(): void {
    this.GetGoalforsideBar()
  }

  /*
* Below method is used to get the Category product details from url
    GoalName while category id is null this method i invoked
*/
  GetCategoryforfiltercatidWhilecopyURL() {
    var url = "/api/Products/GetGoals"
    this.generalService.GetData(url).then(data => {
      this.CategoryData = data
      var catData = this.CategoryData.filter(A => A.GoalName === this.GoalName)
      this.goalId = catData[0].Id
      this.getGoalBasedProductsBasedongoalId()
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  /*
* Below method is used to get the Category list for left side menu 
*/
  GetGoalforsideBar() {
    var url = "/api/Products/GetGoals"
    this.generalService.GetData(url).then(data => {
      this.Goals = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*
 * Below method is used to get the Goals based on category Id
 */
  getGoalBasedProductsBasedongoalId() {
    debugger
    let arr = [];
    arr.push({ UserId: 0, TokenID: null, Id: this.goalId })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
    var url = "/api/Products/GetAll_GoalWiseProductsBasedOnGoalID"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.GoalProducts = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /**
   * Below method is call when customer clicks on side bar Goals
   */
  onclickmethodforLeftsideGoals(goalId, name) {
    this.goalId = goalId
    this.GoalName = name
    this.getGoalBasedProductsBasedongoalId()
    // this.GetCategoryforsideBar()
  }

  /**
  * Navigating to selected Products page  regarding category
  */
  navigatingToProduct(data) {
    debugger
    var prodName = ""; var prodarr = []
    prodarr = this.GoalProducts.filter(A => A.productname == data.productname)//Filtering Goals data based on selected chemical name for getting category id    
    var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    var obj = prodarr;
    obj[0].TopicName = this.GoalName;
    localStorage.setItem('Goal', this.GoalName);
    this.generalService.setProducts(obj);
    this.generalService.clearCategoryid()
    this.router.navigate(['/Product/' + prodName]);
    
  }

  /**
 * navigate To best Seller
 */
  navigateTobestSeller() {
    this.router.navigate(['/Product/_VAAYU'])
  }
}
