<!--<p-toast position="top-center"></p-toast>-->




<div class="container-fluid" style="background:#fff">
  <br />
  <div class="container">
    <!--<div class="backbtn" routerLink="/Home">
      <i class="fa fa-angle-double-left" aria-hidden="true"></i>  Back to home
    </div>-->
    <ol class="breadcrumb" style="background-color:white;">
      <li>
        <a routerLink=""><i class="fa fa-home mr-2"></i>Home</a>
      </li>
      <li>
        <a style="cursor:pointer" (click)="navigatingtoCategory(productDetails[0].CategoryName)">
          <span *ngIf="!productDetails[0].GoalName">{{productDetails[0].CategoryName}}</span>
          <span *ngIf="productDetails[0].GoalName">{{productDetails[0].GoalName}}</span>
        </a>
      </li>
      <li>
        <a>{{ProductName}}</a>
      </li>
    </ol>


    <div class="row">
      <div class="col-lg-4 ">
        <div>
          <div class="imgCrd " *ngIf="!video">
            <img src={{HomeURL+productFrameImage}} class="cart_img" alt="product image">
          </div>
          <div class="imgCrd" *ngIf="video">
            <iframe class="fancybox" rel="ligthbox" width="350" height="215" [src]="imageUrl | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="row" style="margin-top:5%">
            <div class="col-lg-3 col-sm-4 col-xs-4" *ngFor="let image of ProductImages;let i=index" (click)="changeImage(image.UImage)">
              <img class="cart_img" alt="products image" style="height:88px;width:75px;" src={{HomeURL+image.UImage}} />
            </div>
            <div class="col-lg-3 col-sm-6 col-xs-6" *ngIf="imageUrl">
              <button class="btn btn-dark" style="background-color:snow" (click)="changeImage('')">
                <img class="cart_img" alt="V@I" style="height: 33px;width: 51px;" src="../../../assets/icons/channels4_profile.jpg" />
              </button>
            </div>


          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-5 col-sm-12 col-xs-12">
        <div class="decCard">
          <h4 class="mainHeader">{{productDetails[0].productname}}-{{productDetails[0].CategoryName}}</h4>

          <div>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
            <!--<i class="fa fa-star rateColor" aria-hidden="true"></i>-->

            <div class="row">

              <span class="rate" style="margin-left: 80px;margin-top: -21px;">{{Starscount}} Rate</span>
            </div>

            <div style="margin-left: 130px; margin-top: -21px; cursor:pointer; color:teal" (click)="rating(ratingsec)">
              Reviews
            </div>

          </div>
          <h5 class="mainHeader">
            Share
            <span style="cursor:pointer">
              <a href="https://www.facebook.com/pg/" target="_blank"> <i class="fa fa-facebook-official" aria-hidden="true" style="color:cornflowerblue;cursor:pointer;"></i></a> &nbsp;
              <i (click)="sendWhatsapp(productDetails[0])" class="fa fa-whatsapp" aria-hidden="true" style="color:green;"></i>
            </span>
          </h5>
          <!--<div class="ratefont">
    M.R.P : <span class="mrp">₹720</span>
  </div>-->
          <div class="ratefont">
            Size : {{currntPacksize}}
          </div>
          <div style="margin-left: 103px;margin-top:-25px;">
            Quantity
            <span>
              <select [(ngModel)]="Quantity">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
              </select>
            </span>
          </div>
          <div class="ratefont">Price : <span class="acmrp">₹{{currentPrice}}</span>&nbsp;&nbsp;&nbsp;<b>GST Included ({{productDetails[0].GST}}%)</b></div>
          <br />
          <div class="row">
            <div class="col-lg-2 col-md-2 col-xs-3 col-sm-3" style="padding-right:0; "
                 (click)="selectPrice(productDetails[0].Pack1,productDetails[0].Price1,productDetails[0].GstAmount1)">

              <div [class]="(currntPacksize === productDetails[0].Pack1) ? 'defaultpricecard' :
                  'pricecard'">
                <div>{{productDetails[0].Pack1}}</div>
                <div><span class="acmrp">₹{{productDetails[0].Price1}}</span></div>
              </div>
            </div>
            <div *ngIf="productDetails[0].Pack2!=''" class="col-lg-2 col-md-2 col-xs-3 col-sm-3" style="padding-right:0;" (click)="selectPrice(productDetails[0].Pack2,productDetails[0].Price2,productDetails[0].GstAmount2)">
              <div [class]="(currntPacksize === productDetails[0].Pack2) ? 'defaultpricecard' : 'pricecard'" style="margin-left:0px;">
                <div class="">{{productDetails[0].Pack2}}</div>
                <div><span class="acmrp">₹{{productDetails[0].Price2}}</span></div>
              </div>
            </div>
            <div *ngIf="productDetails[0].Pack3!=''" class="col-lg-2 col-md-2 col-xs-3 col-sm-3" style="padding-right:0" (click)="selectPrice(productDetails[0].Pack3,productDetails[0].Price3,productDetails[0].GstAmount3)">
              <div [class]="(currntPacksize === productDetails[0].Pack3) ? 'defaultpricecard' : 'pricecard'" style="margin-left:2px;">
                <div class="">{{productDetails[0].Pack3}}</div>
                <div><span class="acmrp">₹{{productDetails[0].Price3}}</span></div>
              </div>
            </div>
            <div *ngIf="productDetails[0].Pack4!=''" class="col-lg-2 col-md-2 col-xs-3 col-sm-3" style="padding-right:0"
                 (click)="selectPrice(productDetails[0].Pack4,productDetails[0].Price4,productDetails[0].GstAmount4)">
              <div [class]="(currntPacksize === productDetails[0].Pack4) ? 'defaultpricecard' : 'pricecard'" style="margin-left:2px;">
                <div class="">{{productDetails[0].Pack4}}</div>
                <div><span class="acmrp">₹{{productDetails[0].Price4}}</span></div>
              </div>
            </div>
          </div>
          <!--<div class="ratefont">
    Brand : YK laboratories
  </div>
  <div class="ratefont">
    Volume : {{productDetails[0].Pack1}}
  </div>-->
          <div class="aboutFont" *ngIf="ProductInfo!=''">


            Production Information


          </div>
          <p class="text-align_j" [innerHtml]="ProductInfo">


          </p>
          <div class="aboutFont" *ngIf="benefits!=''">
            Benefits

          </div>

          <p class="text-align_j" [innerHtml]="benefits">
          </p>
          <div class="aboutFont" *ngIf="dosage!=''">
            Dosage & Application Method

          </div>

          <p class="text-align_j" [innerHtml]="dosage">
          </p>
          <div class="aboutFont" *ngIf="recomendedCrops!=''">
  Recommended For
          </div>
          <p class="text-align_j" [innerHtml]="recomendedCrops">

          </p>
          <div class="aboutFont" *ngIf="ingrediants!=''">
            Ingredients
          </div>
          <p class="text-align_j" [innerHtml]="ingrediants">

          </p>
          <hr />


          <div class="ratings" #ratingsec>
            <div *ngIf="addNew">
              <b style=" color:green"> Product Review</b>
              <p style="text-align:right;color: #92278f;margin-top: -18px;"> Date&Time</p>

              <div *ngFor="let item of ReviewsData; let i=index">
                <div class="card" style="height: 47px;margin-bottom: 8px;border-radius:5px">
                  <p style="font-size: 11px;margin-left:10px"> {{ item.FarmerName}}</p>
                  <div class="row">

                    <div class="col-lg-6">

                      <p style="margin-left: 17px; color:#92278f;margin-top: -16px;">{{ item.Comments}} </p>
                    </div>
                    <div class="col-lg-6">
                      <p style="text-align:right;color:brown ;margin-top: -16px;margin-right: 7px;">{{item.CreatedDate|date:'dd/MM/yyyy '}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="cartbtn" (click)="Addtocart(productDetails[0])">Add to Cart</button>

            </div>
            <div class="col-lg-6">
              <button *ngIf="loginStatus" type="button" class="cartbtn1" (click)="procedtoBuy()">Buy Now</button>
              <button *ngIf="!loginStatus" type="button" class="cartbtn1" routerLink="/CustomerLogin">Buy Now</button>
            </div>

          </div>
        </div>
      </div>
      <!--<div class="col-lg-3 mobileView " style="padding: 1rem;background-color: #f2e8ec;max-width: 263px;margin-top: 10px;">
        <div class="scrollCard">

          <div class="">

            <div style="text-align: center; font-family:arial;font-size: 19px;    font-weight: 700;  color: #831f41;
    letter-spacing: 0.5px; padding: 1rem; border-bottom: 1px dashed #831f41; margin-bottom: 1rem;">Product Details</div>
            <div  *ngIf="!video">
              <img src={{HomeURL+productFrameImage}} class="cart_img" alt="product image">
            </div>
         
            <div class="addcartCard" style="margin-top:-10px;">
              <div class="ratefont">
                Price : <span class="acmrp">₹{{Quantity*currentPrice}}</span>
              </div>
              <div style="margin-left: 103px;margin-top:-25px;">
                Quantity
                <span>
                  <select [(ngModel)]="Quantity">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                </span>
              </div>

              <div>
                <button type="button" class="cartbtn" (click)="Addtocart(productDetails[0])">Add to Cart</button>
              </div>
              <div>
              </div>
              <div>
              </div>


              <button *ngIf="loginStatus" type="button" class="cartbtn1" (click)="procedtoBuy()">Buy Now</button>
              <button *ngIf="!loginStatus" type="button" class="cartbtn1" routerLink="/CustomerLogin">Buy Now</button>
            </div>
          </div>

        </div>


      </div>-->
      <hr />

      <!--------------------------       New productes          ------------------------------>
      <div class="new-producetes ">
        <div class="aboutFont1">
          Popular purchases
        </div>
        <div class="container">
          <div class="row ">
            <div class="col-md-12">
              <!--Carousel Wrapper-->
              <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">
                <!--calling popular purchase as a child component-->
                <app-popular-purchase (someEvent)="navigatingToPopularProd($event)"></app-popular-purchase>

              </div>
              <!--/.Carousel Wrapper-->
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="new-producetes">
        <div class="aboutFont1">
          Related Products
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <!--Carousel Wrapper-->
              <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">
                <!--calling popular purchase as a child component-->
                <!--app-related-products-->
                <!--<app-related-products [(productId)]="ProductId" (someEvent)="navigatingToPopularProd($event)"></app-related-products>-->
              </div>
              <!--/.Carousel Wrapper-->
            </div>
          </div>
        </div>
      </div>
      <div class="aboutFont2" style="font-size: 17px;font-weight: 600;"> </div>
      <app-related-products [(productId)]="ProductId" (someEvent)="navigatingToPopularProd($event)"></app-related-products>
    </div>

    <!--this code use for google analyticals-->
    <div class="sticky-container1 mobiledisplay">
      <ul class="sticky1" style=" margin-top:200px;">
        <li>
          <img  height="32" src="assets/media/call-circle.png" style="height: 30px; width: 35px;">
          <p ><a href="Tel:9066-179-179">Call Us<br>Schedule Appointment</a></p>
        </li>



        <li>
          <img height="32" src="assets/media/images (1).jpg" style="height: 30px; width: 35px;" />
          <p><a href="" target="_blank">Email<br>Yk mart</a></p>
        </li>


        <li>
          <img height="32" src="assets/media/whatsapp.png" style="height: 30px; width: 35px;" />
          <p><a href="https://api.whatsapp.com/send?phone=919491418181&text=&source=&data=" target="_blank">Whatsapp<br>Yk mart</a></p>
        </li>
      </ul>
    </div>

  </div>
</div>


<div class="modal" id="myModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Select Delivery mode</h4>



      </div>
      <div class="modal-body">
        <!--<input type="radio" name="mode" (change)="getPaymentType('CashOnDelivery')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />-->
        <input type="radio" name="mode" (change)="getPaymentType('Online')" /><span>&nbsp;&nbsp;&nbsp;Online payment</span>
        <br />
        <br />
        <div class="modal-footer">

          <button id="closemodal" type="button" class="btn btn-success" (click)="RazorPayPayment()" style="margin-right:46%;">Ok</button>
          <button id="closemodal" type="button" class="btn btn-danger" (click)="cancel()" style="margin-left:-43%;">Cancel</button>
        </div>

      </div>
    </div>
  </div>
</div>
<style>
  @media only screen and (max-width: 600px) {

    .sticky-container1 {
      display:none;
    }
  }

    .sticky-container1 {
      padding: 0;
      margin: 500px 0 0;
      position: fixed;
      right: -169px;
      top: 230px;
      width: 210px;
      z-index: 1100;
    }

    .sticky1 {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
      margin-top: 20rem;
    }

    @media only screen and (max-width: 600px) {
      .col-xs-3 {
        width: 30% !important;
        margin-top: 5px;
      }

      .col-xs-4 {
        width: 23% !important;
        margin-top: 5px;
      }
    }

    .sticky1 li {
      border: 1px solid rgba(18, 54, 84, 0.43);
      list-style-type: none;
      background-color: #fff;
      color: #efefef;
      height: 43px;
      padding: 0;
      margin: 0 0 8px;
      transition: all .25s ease-in-out;
      cursor: pointer;
    }

      .sticky1 li img {
        float: left;
        margin: 5px 5px 5px 4px;
      }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

        .sticky1 li p a {
          text-decoration: none;
          color: #2c3539;
        }

      .sticky1 li p {
        padding-top: 5px;
        margin: 0;
        line-height: 16px;
        font-size: 11px;
      }

      .sticky1 li:hover {
        margin-left: -115px;
      }
</style>

<style>
  .custcards {
    border: 1px solid #c1c1c1;
    padding: 11px;
    border-radius: 4px;
    cursor: pointer;
  }

  .cartpad {
    padding-right: 0
  }

  .product_img {
    margin-top: 3%;
    border: 1px solid #c1c1c1;
    padding: 4px;
    border-radius: 6px;
  }
</style>


