import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/service/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-enquiries',
  templateUrl: './my-enquiries.component.html',
  styleUrls: ['./my-enquiries.component.scss']
})
export class MyEnquiriesComponent implements OnInit {
  EnquiryProductList: any;
  LoginDetails: any;

  constructor(public generalService: GeneralService, public router: Router, ) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
  }

  ngOnInit(): void {
    this.getCustomerEnquiryDetails()
  }
  /*
   *Geting customer order details by sending customer id to the server 
   */
  getCustomerEnquiryDetails() {
    let details = [{
      CustomerID: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(details));
    var url = "/api/Products/Get_All_Customer_Enquiries_Basedon_CustomerID"
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.EnquiryProductList = data;
      }
    });
  }
}
