import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'
import { GeneralService } from 'src/app/service/general.service'
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
  providers: [MessageService]
})
export class WishlistComponent implements OnInit {
  LoginDetails: any;
  Wishlistitmesforloginusers: any=[];
  wishlistIndex: any;
  cartItemList: any=[];
  selectedItemforCart: any=[];
  itemSize: any;
  ItemId: any;
  HomeURL: any;
  wishListItems: any = []

  constructor(public cartService: CartService, public http: HttpClient,
    public generalService: GeneralService, public router: Router,
    public wishlistService: WishlistService, public toast: MessageService) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.wishlistService.getProducts().subscribe(res => {/*this is a service call for getting wishlist items*/
      this.wishListItems = res
      console.log(this.wishListItems)
    })
    this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
      this.cartItemList = res
    })
    this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
      this.HomeURL = data.Webservice;
    });
  }

  ngOnInit(): void {
  }

  /*
  Below method fires when the user clicks on add to cart page
  */
  movetoCart(item: any, index) {
    
    this.ItemId = 0;
    this.itemSize = "";
   // this.selectedItemforCart.push(item)
    this.selectedItemforCart.push({
      Productid: item.Productid, productname: item.productname, CategoryName: item.CategoryName,
      Image: item.Image, Price: item.Price, Pack: item.Pack, Quantity: item.Quantity,
      UserId: ""
    })
    if (this.cartItemList.length != 0 && this.cartItemList != "" && this.cartItemList != undefined && this.cartItemList != null) {
      for (var i = 0; i < this.cartItemList.length; i++) {
        if (this.cartItemList[i].Productid == this.selectedItemforCart[0].Productid) {//checking if the product is existing in the cartlist with same id
          if (this.cartItemList[i].Pack == this.selectedItemforCart[0].Pack) {//checking if the product is existing in the cartlist with same pack
            this.ItemId = this.cartItemList[i].Productid
            this.itemSize = this.cartItemList[i].Pack
          }
        }
      }
      if (this.itemSize == this.selectedItemforCart[0].Pack) { //if product with same size then we show toast msg like below
        this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in cart' });
        this.wishlistService.removeWishlistItems(index)
      }
      else {
        this.cartService.addtoCart(this.selectedItemforCart[0]);
        this.insertCartItems(this.selectedItemforCart)
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Move to Cart Successfully' });
       // this.wishlistService.removeWishlistItems(index
        this.deleteFromwishList(index, item.WishlistId)
      }
    }
    else {
      this.cartService.addtoCart(this.selectedItemforCart[0]);
      this.insertCartItems(this.selectedItemforCart);
    //  this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Move to Cart Successfully' });
     // this.wishlistService.removeWishlistItems(index)
      this.deleteFromwishList(index, item.WishlistId)
    }
    this.selectedItemforCart = []
  }

  /*
  Below method is called when the customer delete the product from the cart
   */
  deleteFromwishList(index, wishlistId) {
    debugger
    this.wishlistIndex = 0;
    this.wishlistIndex = index;
    let formData: FormData = new FormData();
    formData.append('wishListid', wishlistId);
    var url = "/api/Products/DeleteWishlistItems"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.wishlistService.removeWishlistItems(this.wishlistIndex)
       // this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'product deleted successfully' });
      }
    });
  }

  /*
  Below method is called when the customer clicks on product it will navigating to product details page
   */
  navigatingToProduct(data) {
    debugger
    var prodName = ""; var prodarr = []
   var str = data.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.generalService.setProducts(prodarr);
    this.router.navigate(['/Product/' + prodName]);
  }

  /*
 Below method is for Inserting customer cart items
 */
  insertCartItems(cartItems) {
    debugger
    this.Wishlistitmesforloginusers = []
    this.Wishlistitmesforloginusers.push(cartItems)
    cartItems[0].UserId = String(this.LoginDetails.Id)
    var url = "api/Products/Insert_Cart_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(cartItems));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {        
        this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
      }
    })
  }

 }
