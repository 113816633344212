import { GeneralService } from '../../service/general.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'; import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { window } from 'rxjs/operators';
import { EmailValidators } from 'ngx-validators';
import { CartService } from 'src/app/service/cart.service';
import { WishlistService } from 'src/app/service/wishlist.service'

declare var $;

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss']
})

export class CustomerRegistrationComponent {
  Emailid: any;
  checkNo: number;
  Mobile: any;
  checkemail: number;
  TemplateId: any;
  Id: any;
  arr: any = [];
  public form: FormGroup;
  public FarmerName: AbstractControl;
  public Email: AbstractControl;
  public Password: AbstractControl;
  public confirmPassword: AbstractControl;
  public MobileNo: AbstractControl;
  //public address: AbstractControl;
  //public area: AbstractControl;
  //public pincode: AbstractControl;
  randomnumber: any;
  HomeUrl: any;
  otptextbox: boolean;
  spinner: boolean = false;
  logindata: any;
  otp: any;
  mob: any;
  LoginType: any;
  passwordshow: boolean = true;
  confirmpasswordshow: boolean = true;
  MessageText: any;
    arr1: any[];
    customerCartItems: any[];
    CartItems: any[];
    currentCartItems: any[];
    customerWishlistItems: any;
    admin: any;
  constructor(public router: Router, public fb: FormBuilder, public http: HttpClient, public generalService: GeneralService, public cartService: CartService,
    public wishlistService: WishlistService) {
    debugger
    this.LoginType = localStorage.getItem("LoginType");
    this.logindata = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (this.logindata != null) {
      this.router.navigate['/Home']
    }
    this.form = fb.group({
      FarmerName: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      Email: ['',],
      MobileNo: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      Password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      confirmPassword: ['', Validators.required]
    },
           {
             validator: matchingPasswords('Password', 'confirmPassword')
      });
   

    this.FarmerName = this.form.controls['FarmerName'];
    this.Email = this.form.controls['Email'];
    this.MobileNo = this.form.controls['MobileNo'];    
    this.Password = this.form.controls['Password'];
    this.confirmPassword = this.form.controls['confirmPassword'];



    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }
  ngOnInit(): void {
  }

  already() {
    debugger;
    localStorage.setItem("log", '1');
    this.router.navigateByUrl("/login");
  }

  SignUp(value) {
    debugger;
    $('#myModal1').modal('show');
    this.Mobile = value.MobileNo;

    this.Sendsms();
  }
  sendsms1() {
    debugger
    this.TemplateId = 1207164812405527257;
    this.MessageText = "Thank you for registering with Ykmart";
    var url = 'api/Products/Send_Customer_Reg_SMS_WEB';

    let formdata = new FormData();
    formdata.append("Mobile", this.Mobile)
    formdata.append("SMS", this.MessageText)
    formdata.append("TemplateId", this.TemplateId)
    this.generalService.PostData(url, formdata).then((data: any) => {
    })
  }


  sendsms2() {
    debugger
    this.admin = 9866079507;
    this.TemplateId = 1207164812642544439	;
    this.MessageText = "A new customer " + this.form.value.FarmerName + "," + this.form.value.MobileNo + " has been added in the Ykmart "; 
    var url = 'api/Products/Addcustomer_sendsms_admin';

    let formdata = new FormData();
    formdata.append("admin",this.admin)
    formdata.append("Mobile", this.form.value.MobileNo)
    formdata.append("Name", this.form.value.FarmerName)
    formdata.append("SMS", this.MessageText)
    formdata.append("TemplateId", this.TemplateId)
    this.generalService.PostData(url, formdata).then((data: any) => {
    })
  }

  /* send otp for registration */

  SendOtpForMobile() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    //var msg = "Your Customer OTP is:" + this.randomnumber + " YKmart";
    var UploadFile = new FormData();
    UploadFile.append("MobileNo", this.Mobile );
     UploadFile.append("OTP", this.randomnumber);
    var url = 'api/Products/SendOtpToMobile';
    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {

      }
      else {

      }
    });
  }




  public onSubmit() {
    debugger;
    if (this.form.valid) {
      debugger
    
      this.arr = [];
      this.arr.push({
        FarmerName: this.form.value.FarmerName, MobileNo: this.form.value.MobileNo, Email: this.form.value.Email,
        Password: this.form.value.Password, LoginType:'4'
      })
      debugger
      var UploadFile = new FormData();
      UploadFile.append("Param", JSON.stringify(this.arr));     
      var url = "api/Products/Add_UserDetails";
      this.generalService.PostData(url, UploadFile).then(data => {
      
        debugger
        if (data != null) {
          debugger
          if (data == "SUCCESS") {
            debugger
            this.spinner = false;
           // this.router.navigate(['/Home']);
            this.generalService.ShowAlert('SUCCESS', 'Registrated Successfully.', 'success');
            //this.sendsms1();
            this.sendsms2();
            this.onSubmitLogin();
            //this.sendsmscustomerregistration(this.arr);
            debugger
              var UploadFile = new FormData();
              UploadFile.append("RegDetails", JSON.stringify(this.arr));
              var url = "api/Products/RegMailTo_Customer";
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger
                var msg = data;
              });                   
          }
          else if (data == "MOBILE EXISTS") {
            this.generalService.ShowAlert('WARNING', 'Mobile Exist.', 'warning')
          }
          else if (data == "EMAIL EXISTS") {
            this.generalService.ShowAlert('WARNING', 'Email Exist.', 'warning')
          }
        }
      });
    }
  }
  onSubmitLogin() {
    debugger
    if (this.form.valid) {
      var url = "api/Products/Login";
      var UploadFile = new FormData();
      UploadFile.append("MobileNo", this.form.value.MobileNo);
      UploadFile.append("Password", this.form.value.Password);
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger;
        if (data != null) {
          if (data == "NOT EXIST") {
            this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
          }
          else {
            if (data[0].LoginType == 4) {
              /*Below method is calling to get custsomer cart items*/
              this.getCartItemsListbasedonUserId(data[0].Id)
              this.getWishListItemsListbasedonUserId(data[0].Id)
              this.router.navigate(['/Home']);
              localStorage.setItem("token", data[0].FarmerName);
              localStorage.setItem("LoginDetails", JSON.stringify(data[0]));

            }
            else {
              this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials/Customer Cannot Login Into Web', 'warning');
            }
          }
        }
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
      });
    }

  }
  /*method is for getting customer cart items based on the user id*/
  getCartItemsListbasedonUserId(userid) {
    this.customerCartItems = []
    this.CartItems = [];
    this.currentCartItems = []
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    this.cartService.getProducts().subscribe(res => {
      this.currentCartItems = res;
    })
    var url = "api/Products/GetAllCartItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerCartItems.push(data[k])
          this.cartService.addtoCart(data[k])
        }
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });

    if (this.customerCartItems.length == 0 && this.currentCartItems.length >= 1) {
      this.insertCartItems(userid) /*we need to insert the new cart items when user added in without login*/
    }
  }



  /*sendsms*/

 

  /*method is for getting customer wishList items based on the user id*/
  getWishListItemsListbasedonUserId(userid) {
    /*below line of code is for getting current cart items from the current cart items with out login customer cart items*/
    var url = "api/Products/GetAllWishListItemsbasedOnUserId";
    var UploadFile = new FormData();
    UploadFile.append("UserId", userid);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.wishlistService.removeAllWishlistItems();
      if (data != null) {
        for (var k = 0; k < data.length; k++) {/*Pushing customer cart items in to our cart page*/
          this.customerWishlistItems.push(data[k])
          this.wishlistService.setProducts(this.customerWishlistItems[k])
        }
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    });
  }
  /*
  Below method is for Inserting customer cart items
  */
  insertCartItems(userid) {
    for (var m = 0; m < this.currentCartItems.length; m++) {
      this.currentCartItems[m].UserId = String(userid)
    }
    var url = "api/Products/Insert_Cart_Items";
    var UploadFile = new FormData();
    UploadFile.append("Cartxml", JSON.stringify(this.currentCartItems));
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.cartService.removeAllcart();
      for (var k = 0; k < data.length; k++) {
        this.cartService.addtoCart(data[k])
      }
    })
  }
  /*send sms for customer when customer registration*/
  sendsmscustomerregistration(regxml) {
    debugger
    //this.arr1 = [];
    //this.arr1.push({
    //  FarmerName: this.form.value.FarmerName, MobileNo: this.form.value.MobileNo, Email: this.form.value.Email,
    //  Password: this.form.value.Password, LoginType: '4'
    //})
    var url = 'api/Products/YKLABS_Send_SMS?MobileNo=' + regxml[0].MobileNo+'&Message='+'Your registration successfully';
    this.generalService.GetData(url).then((data: any) => {

    });
  }
  /*end*/
  Sendsms() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    var MessageText = "Your Ykmart OTP is" + this.randomnumber + "";
    var UploadFile = new FormData();
    UploadFile.append("Mobile", this.Mobile);
    UploadFile.append("SMS", MessageText)
    UploadFile.append("OTP", this.randomnumber);
    var url = 'api/Products/Send_SMS_Reg_OTP'

    this.generalService.PostData(url, UploadFile).then(data => {
      if (data == "SUCCESS") {

      }
      else {

      }
    });
  }
 

  /*Show passwords*/
  ShowPassword(id) {
    debugger;
    var x;
    if (id == 1) {
      x = document.getElementById("myInput");
      if (x.type === "password") {
        x.type = "text";
        this.passwordshow = false;
      } else {
        x.type = "password";
        this.passwordshow = true;
      }
    }
  

  }
  /*end code*/

  ShowPassword1(id) {
    debugger;
    var x;
    if (id == 2) {
      x = document.getElementById("myInput1");
      if (x.type === "password") {
        x.type = "text";
        this.confirmpasswordshow = false;
      } else {
        x.type = "password";
        this.confirmpasswordshow = true;
      }
    }


  }
  /*check otp for registration*/
  CheckOtp(otp) {
    debugger
    if (this.randomnumber == otp) {
      $('#myModal1').modal('hide');
      debugger
      this.onSubmit();
    }
    else {
      alert("Invalid OTP")
    }
  }
 

  /*check mobile exist or not*/
  CheckMobileNo() {
    debugger;
    this.Mobile = this.form.get('MobileNo').value;
    var UploadFile = new FormData();
    var UploadFile = new FormData();
    UploadFile.append("MobileNo", this.Mobile);
    UploadFile.append("Flag", "1");
    var url = "api/Products/CheckMobileandEmail"
    debugger;
    this.generalService.PostData(url, UploadFile).then(data => {
      this.mob = data;
      debugger;
      if (data == 'EXIST') {
        this.checkNo = 10;
      }
      else {
        this.checkNo = 11;
      }
    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*endregion*/

  /*Check  Email exist or not*/

  //CheckEmail() {
  //  debugger;
  //  this.Emailid = this.form.get('Email').value;
  //  var UploadFile = new FormData();
  //  UploadFile.append("MobileNo", this.Mobile);
  //  UploadFile.append("Flag", "1");
  //  var url = "api/Products/CheckMobileandEmail"
  //  debugger;
  //  this.generalService.PostData(url, UploadFile).then(data => {
  //    debugger;
  //    if (data == 'EXIST') {
  //      this.checkemail = 10;
  //    }
  //    else {
  //      this.checkemail = 11;
  //    }
  //  }, err => {
  //    //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
  //  });
  //}


  ///end code////////
}


  
export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let Password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (Password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}
