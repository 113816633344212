<div class="carousel-inner" role="listbox">
  <div class="row">
    <div class="col-lg-2 col-sm-6 col-xs-6 cartpad" *ngFor="let item of PopularProducts;let i=index">
      <div class="custcards" (click)="navigatingToPopularProd(item.productname)">
        <img class="imgg" src={{HomeURL+item.Image}} />
        <div class="custHead flow-name">{{item.productname}}</div>
        <div style="padding-left: 7px;">
          <i class="fa fa-star rateColor" aria-hidden="true"></i>
          <i class="fa fa-star rateColor" aria-hidden="true"></i>
          <i class="fa fa-star rateColor" aria-hidden="true"></i> <i class="fa fa-star rateColor" aria-hidden="true"></i>
        </div>
        <div class="custPrice flow-name">{{item.Pack1}}-₹{{item.Price}}</div>
      </div>
    </div>
  </div>
</div>











